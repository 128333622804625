import { Filters } from 'context/FilterContext'
import dateToIsoString from 'utils/dateToIsoString'
import getPastTime from 'utils/getPastTime'

export type DateFilters = Pick<
  Filters,
  'startedFrom' | 'startedTo' | 'timeFrame'
>

export const makeISODatesFromFilters = ({
  startedFrom,
  startedTo,
  timeFrame
}: DateFilters) => {
  return {
    startedFrom:
      timeFrame === 'custom'
        ? dateToIsoString(startedFrom)
        : getPastTime(timeFrame),
    startedTo:
      timeFrame === 'custom' ? dateToIsoString(startedTo, true) : undefined
  }
}
