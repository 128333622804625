import { LoadingSpinner } from '@matillion/component-library'

import './FullPageLoader.scss'

export function FullPageLoader() {
  return (
    <div className="FullPageLoader" data-testid={'full-page-loader'}>
      <LoadingSpinner />
    </div>
  )
}
