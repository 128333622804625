import classNames from 'classnames'
import { FC } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import OrchestrationPipelineIcon from 'svg/OrchestrationPipelineIcon'
import TransformationPipelineIcon from 'svg/TransformationPipelineIcon'
import { InaccessiblePipelineData } from 'types/dgs'
import classes from './InaccessiblePipelineNode.module.scss'
import { NodeData } from 'components/ReactFlow/LineageGraph'

export const C_BG = 'white'
export const C_BORDER = '#DDDDDD'
export const C_ICON = '#A9A9A9'

export type CustomInaccessiblePipelineNodeProps = NodeProps<
  NodeData<InaccessiblePipelineData>
>

const CustomInaccessiblePipelineNode: FC<
  CustomInaccessiblePipelineNodeProps
> = ({ data, selected }) => {
  return (
    <div
      className={classes.InaccessiblePipelineNode}
      data-testid="inaccessible-pipeline-node"
    >
      <Handle
        type="target"
        position={Position.Left}
        className={classNames(
          classes.InaccessiblePipelineNode__Handle,
          classes.InaccessiblePipelineNode__Handle__Left
        )}
        isConnectable={false}
      />
      {data.content.kind === 'ORCHESTRATION' ? (
        <OrchestrationPipelineIcon
          backgroundColor={C_BG}
          iconColor={C_ICON}
          borderColor={C_BORDER}
          width="32"
          height="32"
          data-testid="orchestration-icon"
        />
      ) : (
        <TransformationPipelineIcon
          backgroundColor={C_BG}
          iconColor={C_ICON}
          borderColor={C_BORDER}
          width="32"
          height="32"
          data-testid="transformation-icon"
        />
      )}
      <Handle
        type="source"
        position={Position.Right}
        className={classNames(
          classes.InaccessiblePipelineNode__Handle,
          classes.InaccessiblePipelineNode__Handle__Right
        )}
        isConnectable={false}
      />
    </div>
  )
}

export default CustomInaccessiblePipelineNode
