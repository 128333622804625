import { endOfDay, format, parse } from 'date-fns'

const dateToIsoString = (
  date?: string,
  setEndOfDay = false
): string | undefined => {
  if (!date) return
  let parsedDate = parse(date, 'yyyy-MM-dd', new Date())

  if (setEndOfDay) {
    parsedDate = endOfDay(parsedDate)
  }

  return format(parsedDate, "yyyy-MM-dd'T'HH:mm:ssXXX")
}

export default dateToIsoString
