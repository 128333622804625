import { useEffect, useState } from 'react'
import { UseJobActivityProps } from './types'
import { useGetJobs } from 'api/hooks'
import useEventCallback from 'use-event-callback'

const useJobActivity = ({
  installationId,
  instanceId
}: UseJobActivityProps) => {
  const [jobsMessage, setJobsMessage] = useState<{
    title: string
    description: string
    searchMode: boolean
  } | null>(null)
  const {
    searchByName,
    jobs,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    name,
    refresh
  } = useGetJobs({
    installationId,
    instanceId
  })

  const paginate = useEventCallback(() => {
    if (hasNextPage) {
      void fetchNextPage()
    }
  })

  useEffect(() => {
    if (!jobs?.length) {
      setJobsMessage({
        title: name
          ? 'No Result Found'
          : 'No jobs have been found on this instance',
        searchMode: !!name,
        description: name
          ? `No jobs were found for the search key ${name}`
          : 'Please run some jobs in Matillion ETL to see them appear here.'
      })
      return
    }
    setJobsMessage(null)
  }, [jobs, name])

  return {
    isRefreshing: isFetching && !isFetchingNextPage,
    isPaginating: isFetchingNextPage,
    jobs,
    jobsMessage,
    name,
    searchByName,
    paginate,
    refresh
  }
}

export default useJobActivity
