import { useEffect, useState, useRef } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { Organisation, Roles } from '../../types'
import { getCurrentOrganisation } from '../../api/hub'
import { useAccountRequired } from '../selectedAccountUtils'
import useAuth from '../../effects/useAuth'
import Cookies from 'js-cookie'
import useEnvironment from '../../effects/useEnvironment'
import { useAccountId } from '../AccountIdProvider'

interface OrganisationPayload {
  organisation: Organisation
  roles: Roles
}

/**
 * Returns the current user's organisation, by looking it up from the API based
 * on their current Auth0 token.
 */
const useOrganisation = () => {
  const { isLoading: isUserLoading, isAuthenticated } = useAuth0()
  const accountId = useAccountId()
  // The initial accountId is passed to Auth0Provider and used to fetch access token
  const requestedTokenIdsRef = useRef(new Set([accountId]))
  const { getToken } = useAuth()

  const [isPending, setPending] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()
  const [data, setData] = useState<OrganisationPayload>()
  const accountRequired = useAccountRequired()
  const { environment } = useEnvironment()

  useEffect(() => {
    // Don't try to load organisation information if auth0 hasn't finished determining
    // whether or not the user is authenticated
    if (isUserLoading) {
      return
    }

    // Don't try to load organisation information if the user isn't logged in
    if (!isAuthenticated) {
      setPending(false)
      return
    }

    const fetchOrg = async () => {
      setPending(false)
      setLoading(true)

      try {
        // Auth0 can cache multiple valid tokens - they're looked up by the scope, i.e. org:<accountId>
        const token = await getToken({
          ignoreCache: !requestedTokenIdsRef.current.has(accountId)
        })
        requestedTokenIdsRef.current.add(accountId)
        const {
          data: { userRoles, ...organisation }
        } = await getCurrentOrganisation(token)

        setData({
          roles: new Set(userRoles),
          organisation
        })
        setError(undefined)
        Cookies.set(`hub.account_id.${environment}`, organisation.id, {
          expires: 365,
          secure: true,
          domain: 'matillion.com'
        })
      } catch (e) {
        console.error('Failed to load Organisation for user', e)
        setData(undefined)
        setError(e as Error)
      }

      setLoading(false)
    }

    if (
      (accountId && data?.organisation.id !== accountId) ||
      (accountRequired && !data?.organisation.id)
    ) {
      fetchOrg()
    } else if (!data && !accountRequired) {
      setPending(false)
      setData({
        roles: new Set(),
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        organisation: {} as Organisation
      })
    }
  }, [
    getToken,
    isAuthenticated,
    isUserLoading,
    setLoading,
    setPending,
    setData,
    accountId,
    accountRequired,
    environment
  ])

  return [data, isPending || isLoading, error] as const
}

export default useOrganisation
