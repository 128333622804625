import { useAuth, useUser } from '@matillion/hub-client'
import axios from 'axios'
import { useMemo } from 'react'
import config from 'config'

const usePlatformClient = (baseUrl: string) => {
  const { getToken } = useAuth()
  const { organisation } = useUser()

  return useMemo(() => {
    const client = axios.create({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      baseURL: baseUrl.replace('{{region}}', organisation.region!),
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: 50000
    })

    client.interceptors.request.use(async (interceptedConfig) => {
      const tokenOptions = {
        audience: config.platformAudience
      }
      interceptedConfig.headers.Authorization = `Bearer ${await getToken(
        tokenOptions
      )}`
      interceptedConfig.headers['account-id'] = organisation.id
      return interceptedConfig
    })
    return client
  }, [baseUrl, organisation.id, organisation.region, getToken])
}

export default usePlatformClient
