import { useUser } from '@matillion/hub-client'
import { useMemo } from 'react'

const DO11Y_ELIGIBLE_ROLES = [
  'platform-sje:user',
  'platform-sje:admin',
  'saas-etl:user',
  'saas-etl:admin',
  'mdl:user',
  'mdl:admin'
]

export type Persona = 'DO11Y' | 'METL'

export const usePersona = (): Persona => {
  const { roles } = useUser()
  return useMemo(() => {
    return DO11Y_ELIGIBLE_ROLES.some((role) => roles.has(role))
      ? 'DO11Y'
      : 'METL'
  }, [roles])
}
