import { PipelineLinks } from 'types/eos'
import { FC } from 'react'
import { MatillionApplicationLink } from '@matillion/hub-client'
import { Icon } from '@matillion/component-library'

export interface ApplicationLinkProps {
  links: PipelineLinks
  label: string
}

export const ApplicationLink: FC<ApplicationLinkProps> = ({ links, label }) => {
  const href = links.designer?.href

  if (href) {
    return (
      <MatillionApplicationLink aria-label={label} href={href} target="_blank">
        <Icon.Export aria-hidden="true" width="18px" height="18px" />
      </MatillionApplicationLink>
    )
  }
  return null
}
