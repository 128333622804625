import { QueryClient, QueryObserverOptions } from '@tanstack/react-query'

const queryObserverOptions: QueryObserverOptions = {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: true,
  queryKey: []
}

/* istanbul ignore next */
if (process.env.REACT_APP_ENVIRONMENT === 'test') {
  queryObserverOptions.retry = false
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: queryObserverOptions
  }
})

export default queryClient
