import RunHistoryDataGrid from './RunHistoryDataGrid/RunHistoryDataGrid'
import Search from '../Search/Search'
import { usePersona } from 'hooks'
import { ResultsMessage } from 'components/ResultsMessage'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'components/ErrorPage'
import { FilterProvider } from 'context/FilterContext'
import PageLayout from 'components/PageLayout'
import HeaderSection from 'components/HeaderSection'
import TimeFrameDropdown from 'components/TimeFrameDropdown'
import RefreshButton from 'components/RefreshButton'
import classes from './Activity.module.scss'
import NavigationTabs from 'components/NavigationTabs'
import { Section } from '@matillion/component-library'
import Filters from './Filters/Filters'

const Activity = () => {
  const persona = usePersona()

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <FilterProvider>
        <PageLayout>
          <HeaderSection title="Your activity">
            <TimeFrameDropdown className={classes.TimeFrameDropdown} />
            <RefreshButton />
          </HeaderSection>
          <Filters />
          {persona === 'DO11Y' ? (
            <>
              <NavigationTabs />
              <Section size={12}>
                <Search />
                <RunHistoryDataGrid />
              </Section>
            </>
          ) : (
            <>
              <NavigationTabs />
              <Section size={12}>
                <ResultsMessage>
                  {"It looks like you haven't run any pipelines yet"}
                </ResultsMessage>
              </Section>
            </>
          )}
        </PageLayout>
      </FilterProvider>
    </ErrorBoundary>
  )
}

export default Activity
