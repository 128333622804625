import { Grid, Page } from '@matillion/component-library'
import { FC, PropsWithChildren } from 'react'
import classes from './PageLayout.module.scss'

const PageLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Page>
      <Grid className={classes.Grid}>{children}</Grid>
    </Page>
  )
}

export default PageLayout
