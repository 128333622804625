import { BadgeProps } from '@matillion/component-library/dist/components/Badge'
import { InstanceState } from 'types/installations'

const statusToColor: Record<InstanceState, BadgeProps['colour']> = {
  ERROR: 'red',
  RESTARTING: 'blue',
  RUNNING: 'green',
  STOPPED: 'grey'
}

export { statusToColor }
