import { FC, useCallback } from 'react'
import { useViewport, useReactFlow } from 'reactflow'

import { Icon, Typography } from '@matillion/component-library'

import classes from './ZoomToolbar.module.scss'
import { HorizontalStack } from '../../HorizontalStack/HorizontalStack'
import { IconButton } from './ToolbarButton/ToolbarButton'
import Minus from 'svg/Minus'

export interface ZoomToolbarProps {
  collapseAll: () => void
  reorganiseNodes: () => void
}

export const ZoomToolbar: FC<ZoomToolbarProps> = ({
  collapseAll,
  reorganiseNodes
}) => {
  const { zoom } = useViewport()
  const { fitView, zoomTo } = useReactFlow()

  const onZoomOut = useCallback(() => {
    zoomTo(zoom - 0.25, { duration: 400 })
  }, [zoomTo, zoom])

  const onZoomIn = useCallback(() => {
    zoomTo(zoom + 0.25, { duration: 400 })
  }, [zoomTo, zoom])

  return (
    <div className={classes.ZoomToolbar}>
      <HorizontalStack withSpacer>
        <IconButton
          label={'Reorganise'}
          autoSize={true}
          className={classes.ZoomToolbar__CollapseAll}
          onClick={reorganiseNodes}
        >
          <Icon.Refresh />
        </IconButton>
        <IconButton
          label="Collapse all"
          autoSize={true}
          className={classes.ZoomToolbar__CollapseAll}
          onClick={collapseAll}
        >
          <Typography>Collapse all</Typography>
        </IconButton>

        <HorizontalStack>
          <IconButton
            label={'Zoom out'}
            onClick={onZoomOut}
            data-testid="canvas-zoom-controls-zoom-out"
          >
            <Minus />
          </IconButton>

          <div
            aria-live="polite"
            data-testid="canvas-zoom-controls-percentage"
            className={classes.ZoomToolbar__Percentage}
          >
            <Typography>{(zoom * 100).toFixed(0)}%</Typography>
          </div>

          <IconButton
            label={'Zoom in'}
            onClick={onZoomIn}
            data-testid="canvas-zoom-controls-zoom-in"
          >
            <Icon.Add />
          </IconButton>
        </HorizontalStack>
        <IconButton
          label={'Fit all'}
          onClick={() =>
            fitView({
              maxZoom: 1,
              duration: 400
            })
          }
          data-testid="canvas-zoom-controls-fit-all"
        >
          <Icon.FitAll />
        </IconButton>
      </HorizontalStack>
    </div>
  )
}
