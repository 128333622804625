import { AxiosInstance } from 'axios'

export interface Project {
  id: string
  name: string
  status: string
}

export type ProjectsResponse = readonly Project[]

export const getProjects = async (
  client: AxiosInstance
): Promise<ProjectsResponse> => {
  const { data } = await client.get<ProjectsResponse>(
    '/v1/projects?status=ACTIVE&status=DELETING&status=DELETED'
  )
  return data
}
