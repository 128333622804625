import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateMetadata } from 'api/odb'
import { InstallationId, InstanceId } from 'types/installations'
import useClient from './useClient'

const useUpdateMetadata = () => {
  interface MetadataParams {
    description: string
    installationId: InstallationId
    organisationId: InstanceId
  }
  const client = useClient()
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateMetadata'],
    mutationFn: async (request: MetadataParams) => {
      await updateMetadata(client, request)
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['installations']
      })
    }
  })
}

export default useUpdateMetadata
