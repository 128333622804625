import { PipelineData } from 'types/dgs'
import { NodeData } from '../../LineageGraph'
import { useGetSinglePipelineActivity } from 'api/hooks/useGetSinglePipelineActivity'
import { CenteredSpinner } from 'components/CenteredSpinner'
import ErrorMessage from 'components/ErrorMessage'
import { FC } from 'react'
import InformationPanelHeader from '../InformationPanelHeader'
import PipelineDetailsTable from '../InformationPanelTable/PipelineDetailsTable'
import classes from './PipelineDetails.module.scss'
import OrchestrationPipelineIcon from 'svg/OrchestrationPipelineIcon'
import TransformationPipelineIcon from 'svg/TransformationPipelineIcon'
import {
  C_ORCH_SELECTED_BG,
  C_ORCH_SELECTED_ICON,
  C_TRAN_SELECTED_BG,
  C_TRAN_SELECTED_ICON
} from '../../CustomNodes/PipelineNode/PipelineNode'
import { pipelineKindLabels } from 'utils/pipelineKindLabels'

export interface InformationPanelProps {
  nodeData: NodeData<PipelineData>
}

const PipelineDetails: FC<InformationPanelProps> = ({ nodeData }) => {
  const { pipelineExecutionId } = nodeData.content

  if (!pipelineExecutionId) {
    throw new Error('Missing pipelineExecutionId in node data')
  }

  const { data, isError, isLoading } = useGetSinglePipelineActivity(
    nodeData.content.pipelineExecutionId as string
  )

  if (isLoading) {
    return <CenteredSpinner />
  }

  if (isError) {
    return (
      <ErrorMessage>
        Sorry, there was an error when getting pipeline info
      </ErrorMessage>
    )
  }

  if (!data) {
    return undefined
  }

  const Icon =
    data.definition.pipelineKind === 'ORCHESTRATION' ? (
      <OrchestrationPipelineIcon
        className={classes.PipelineDetails__Header__Icon}
        backgroundColor={C_ORCH_SELECTED_BG}
        iconColor={C_ORCH_SELECTED_ICON}
        borderColor={C_ORCH_SELECTED_ICON}
        width="40"
        height="40"
      />
    ) : (
      <TransformationPipelineIcon
        className={classes.PipelineDetails__Header__Icon}
        backgroundColor={C_TRAN_SELECTED_BG}
        iconColor={C_TRAN_SELECTED_ICON}
        borderColor={C_TRAN_SELECTED_ICON}
        width="40"
        height="40"
      />
    )

  return (
    <div className={classes.PipelineDetails__Container}>
      <InformationPanelHeader
        Icon={Icon}
        title={data.definition.pipelineName}
        subtitle={pipelineKindLabels[data.definition.pipelineKind]}
        url={`/pipeline/${data.id}`}
      />
      <div className={classes.PipelineDetails__Content}>
        <PipelineDetailsTable data={data} />
      </div>
    </div>
  )
}

export default PipelineDetails
