import { useFilters } from 'context/FilterContext'
import classes from './CustomDateRange.module.scss'
import CustomDateInput from 'components/CustomDateInput/CustomDateInput'
import { useEffect, useState } from 'react'
import { Typography } from '@matillion/component-library'

const CustomDateRange = () => {
  const { updateFilters, filters } = useFilters()

  const [startedFrom, setStartedFrom] = useState(filters.startedFrom)
  const [startedTo, setStartedTo] = useState(filters.startedTo)
  const [startedFromError, setStartedFromError] = useState(false)
  const [startedToError, setStartedToError] = useState(false)
  const [startedFromOpen, setStartedFromOpen] = useState(false)
  const [startedToOpen, setStartedToOpen] = useState(false)

  useEffect(() => {
    if (startedFrom && startedTo) {
      updateFilters({ startedFrom, startedTo })
      setStartedFromError(false)
      setStartedToError(false)
      setStartedFromOpen(false)
      setStartedToOpen(false)
    }
    if (startedFrom && !startedTo) {
      setStartedToError(true)
      setStartedToOpen(true)
    }
    if (!startedFrom && startedTo) {
      setStartedFromError(true)
      setStartedFromOpen(true)
    }
  }, [startedFrom, startedTo, updateFilters])

  return (
    <div className={classes.CustomDateRange}>
      <CustomDateInput
        heading="From date"
        subheading="*Required"
        className={classes.CustomDateRange__FromDate}
        value={startedFrom}
        error={startedFromError}
        maxDate={startedTo ? new Date(startedTo) : new Date()}
        open={startedFromOpen}
        onFocus={() => setStartedFromOpen(true)}
        onCalendarClose={() => setStartedFromOpen(false)}
        onChange={(date) => setStartedFrom(date)}
      />
      <CustomDateInput
        heading="To date"
        subheading="*Required"
        value={startedTo}
        error={startedToError}
        minDate={startedFrom ? new Date(startedFrom) : undefined}
        maxDate={new Date()}
        open={startedToOpen}
        onFocus={() => setStartedToOpen(true)}
        onCalendarClose={() => setStartedToOpen(false)}
        onChange={(date) => setStartedTo(date)}
      />
      <Typography
        format="bcm"
        role="button"
        className={classes.CustomDateRange__ClearButton}
        onClick={() => {
          setStartedFrom('')
          setStartedTo('')
          updateFilters({
            startedFrom: undefined,
            startedTo: undefined,
            timeFrame: '24h'
          })
        }}
      >
        Clear
      </Typography>
    </div>
  )
}

export default CustomDateRange
