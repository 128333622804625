import { Tooltip, Typography } from '@matillion/component-library'
import classNames from 'classnames'
import DetailLink from 'components/DetailLink'
import { FC, ReactNode } from 'react'
import classes from './CellTypography.module.scss'

interface Props {
  text: ReactNode
  subtext?: ReactNode
  SubtextIcon?: FC
  testId?: string
  subtextTestId?: string
  pipelineId?: string
  Icon?: FC
  isLink?: boolean
  format?: 'bcs' | 'bcm' | 'mc'
}

export const CellTypography: FC<Props> = ({
  text,
  subtext,
  SubtextIcon,
  testId,
  subtextTestId,
  pipelineId,
  Icon,
  isLink,
  format = 'bcm'
}) => {
  if (isLink) {
    return (
      <div className={classes.LinkContainer}>
        {Icon && (
          <div className={classes.IconContainerLink}>
            <Icon />
          </div>
        )}
        <DetailLink
          text={text}
          pipelineId={pipelineId}
          className="u-truncate-text-2-lines"
        />
      </div>
    )
  }

  return (
    <>
      <div className={classes.MainTextContainer} data-testid={testId}>
        {Icon && (
          <div className={classes.IconContainer}>
            <Icon />
          </div>
        )}
        <Tooltip content={text} onlyShowOnOverflow>
          <Typography
            className={classNames(classes.MainTextContainer__Text, {
              'u-truncate-text-2-lines': !subtext,
              [classes['MainTextContainer__Text--noWrap']]: !!subtext
            })}
            format={format}
            weight="regular"
          >
            {text}
          </Typography>
        </Tooltip>
      </div>
      {subtext && (
        <div className={classes.SubtextContainer} data-testid={subtextTestId}>
          {SubtextIcon && (
            <div className={classes.SubtextContainer__IconContainer}>
              <SubtextIcon />
            </div>
          )}
          <Typography
            format="bcs"
            className={classNames(classes.Subtext, {
              'u-truncate-text-2-lines': !text,
              [classes['Subtext--noWrap']]: !!text
            })}
          >
            {subtext}
          </Typography>
        </div>
      )}
    </>
  )
}
