import axios from 'axios'
import { useEffect, useState } from 'react'
import useEnvironment from './useEnvironment'

interface UseTextResult {
  data?: string
  isError: boolean
  isLoading: boolean
  isSuccess: boolean
}

export const useText = (file: string): UseTextResult => {
  const { hubAssetsUrl } = useEnvironment()
  const [data, setData] = useState<string | undefined>()
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  useEffect(() => {
    axios
      .get(`${hubAssetsUrl}/text/${file}`)
      .then((response) => {
        setData(response.data)
        setIsLoading(false)
        setIsSuccess(true)
      })
      .catch(() => {
        setIsError(true)
        setIsLoading(false)
      })
  }, [file, hubAssetsUrl])
  return {
    data,
    isError,
    isLoading,
    isSuccess
  }
}
