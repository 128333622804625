import { AxiosInstance } from 'axios'
import { ActivityItem, HalLink, PipelineStatus, Step } from '../types/eos'

export interface ActivityResponse {
  results: ActivityItem[]
  earlierToken: string
  hasEarlier: boolean
}

export interface StepsResponse {
  results: Step[]
  limit: number
  offset: number
  total: number
  _links: {
    next?: HalLink
  }
}

export interface GetActivityParams {
  paginationToken?: string
  startedFrom?: string
  startedTo?: string
  search?: string
  status?: PipelineStatus[]
}

export const getActivity = async (
  client: AxiosInstance,
  params?: GetActivityParams
): Promise<ActivityResponse> => {
  const { data } = await client.get<ActivityResponse>('/v1/activity', {
    params: {
      ...params,
      status: params?.status?.join(',')
    }
  })
  return data
}

export const getSteps = async (
  client: AxiosInstance,
  pipelineId: string,
  offset?: number,
  limit?: number,
  status?: PipelineStatus
): Promise<StepsResponse> => {
  const { data } = await client.get<StepsResponse>(
    `/v1/activity/pipeline/${pipelineId}/steps`,
    {
      params: {
        offset,
        limit,
        status
      }
    }
  )

  return data
}

export const getSinglePipelineActivity = async (
  client: AxiosInstance,
  pipelineId: string
): Promise<ActivityItem> => {
  const { data } = await client.get<ActivityItem>(
    `/v1/activity/pipeline/${pipelineId}`
  )
  return data
}

export type ActivityFilterFields = 'STATUS'

export interface ActivityFilterQueryParams {
  /** full ISODateString */
  startedFrom?: string
  /** full ISODateString */
  startedTo?: string
  fields?: ActivityFilterFields[]
}

export interface ActivityFiltersResponse {
  status: PipelineStatus[]
}

export const getActivityFilters = async (
  client: AxiosInstance,
  { fields, startedFrom, startedTo }: ActivityFilterQueryParams
) => {
  const { data } = await client.get<ActivityFiltersResponse>(
    '/v1/activity/filters',
    { params: { fields: fields?.join(','), startedFrom, startedTo } }
  )

  return data
}

export const cancelPipeline = async (
  client: AxiosInstance,
  pipelineId: string,
  force: boolean
) => {
  const forced = force ? 'true' : 'false'
  return client.patch(
    `/v1/activity/${pipelineId}/cancellation?force=${forced}&source=observability-dashboard`
  )
}
