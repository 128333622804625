import {
  FunctionComponent,
  PropsWithChildren,
  ButtonHTMLAttributes,
  HTMLAttributes,
  Dispatch,
  SetStateAction,
  useContext,
  isValidElement,
  Children,
  createContext,
  useMemo,
  useState
} from 'react'
import classes from './Accordion.module.scss'
import classnames from 'classnames'

interface AccordionCtxInterface extends HTMLAttributes<HTMLDivElement> {
  accordionExpand: boolean
  id: string
  setAccordionExpand?: Dispatch<SetStateAction<boolean>>
}

interface AccordionProps {
  id: string
  className?: string
}

const AccordionContext = createContext<AccordionCtxInterface>({
  accordionExpand: false,
  id: 'default-0'
})

const Accordion: FunctionComponent<PropsWithChildren<AccordionProps>> = ({
  children,
  id,
  className,
  ...other
}) => {
  const [accordionExpand, setAccordionExpand] = useState(false)
  const contextValue = useMemo(
    () => ({ id, accordionExpand, setAccordionExpand }),
    [id, accordionExpand]
  )
  const accordionHeader = Children.toArray(children)[0]
  const accordionContainer = Children.toArray(children)[1]

  if (!isValidElement(accordionHeader) || !isValidElement(accordionContainer)) {
    return null
  }

  return (
    <div
      className={classnames([classes.Accordion__Card, className])}
      {...other}
    >
      <div className={classes.Accordion__Container}>
        <AccordionContext.Provider value={contextValue}>
          {accordionHeader}
        </AccordionContext.Provider>
        <div
          id={`${id}-accordion-section`}
          role="region"
          aria-labelledby={`${id}-accordion`}
          aria-hidden={!accordionExpand}
          className={classnames([
            classes.Accordion__Info,
            classes[`Accordion__Info--${accordionExpand ? 'Open' : 'Close'}`]
          ])}
        >
          {accordionExpand && <div>{accordionContainer}</div>}
        </div>
      </div>
    </div>
  )
}

const AccordionHeader: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const { accordionExpand } = useContext(AccordionContext)
  return (
    <div
      className={classnames([
        classes.Accordion__Header,
        accordionExpand && classes['Accordion__Header--Open']
      ])}
    >
      {children}
    </div>
  )
}

const AccordionContent: FunctionComponent<
  PropsWithChildren<HTMLAttributes<HTMLDivElement>>
> = ({ children, ...other }) => <div {...other}>{children}</div>

const AccordionButton: FunctionComponent<
  PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>
> = ({ children, ...props }) => {
  const { accordionExpand, setAccordionExpand } = useContext(AccordionContext)

  const handleChange = () => {
    if (!setAccordionExpand) return
    if (accordionExpand) {
      setAccordionExpand(false)
      return
    }
    setAccordionExpand(true)
  }

  return (
    <button
      type="button"
      aria-expanded={accordionExpand}
      onClick={() => {
        handleChange()
      }}
      tabIndex={0}
      {...props}
    >
      {children}
    </button>
  )
}

const AccordionTitle: FunctionComponent<
  PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>
> = ({ children, onClick, className, ...props }) => {
  const { id } = useContext(AccordionContext)
  return (
    <AccordionButton
      id={`${id}-accordion`}
      aria-controls={`${id}-accordion-section`}
      className={className}
      {...props}
    >
      {children}
    </AccordionButton>
  )
}

export {
  Accordion,
  AccordionHeader,
  AccordionButton,
  AccordionTitle,
  AccordionContent,
  AccordionContext
}
