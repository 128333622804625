import { Button, Typography } from '@matillion/component-library'
import ErrorConnection from 'svg/ErrorConnection'
import classes from './ErrorPage.module.scss'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <div className={classes.ErrorPage}>
      <div className={classes.ErrorPage__Container}>
        <ErrorConnection />
        <Typography as="h2" format="tl" className={classes.ErrorPage__Title}>
          Something went wrong.
        </Typography>
        <Typography className={classes.ErrorPage__Description}>
          Something unexpected happened, please go back and try again.
        </Typography>

        <Link to={'/'} className={classes.ErrorPage__Link}>
          <Button className={classes.ErrorPage__LinkButton}>
            <Typography weight="bold">Back to pipelines</Typography>
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default ErrorPage
