import { FC } from 'react'

export interface Props {
  backgroundColor?: string
  iconColor?: string
  borderColor?: string
  width?: string
  height?: string
  className?: string
}
const OrchestrationPipelineIcon: FC<Props> = ({
  backgroundColor = '#D6E3F2',
  iconColor = '#264EA0',
  borderColor = '#264EA0',
  ...props
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="17"
        height="17"
        rx="3.5"
        fill={backgroundColor}
      />
      <path
        d="M9.005 12.495C11.555 12.495 12.505 10.835 12.505 9.00501C12.505 7.20501 11.555 5.505 9.005 5.505C6.455 5.505 5.495 7.19501 5.495 9.00501C5.495 10.845 6.455 12.495 9.005 12.495ZM7.085 9.00501C7.085 7.535 7.545 6.745 9.005 6.745C10.465 6.745 10.925 7.535 10.925 9.00501C10.925 10.455 10.465 11.245 9.005 11.245C7.545 11.245 7.085 10.455 7.085 9.00501Z"
        fill={iconColor}
      />
      <rect
        x="0.5"
        y="0.5"
        width="17"
        height="17"
        rx="3.5"
        stroke={borderColor}
      />
    </svg>
  )
}

export default OrchestrationPipelineIcon
