import { Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import classes from './ErrorMessage.module.scss'

interface Props {
  className?: string
  children?: ReactNode
}

const ErrorMessage: FC<Props> = ({ className, children }) => {
  return (
    <div
      className={classNames(classes.ErrorMessage, className)}
      data-testid="error-message"
    >
      <Icon.Error className={classes.ErrorMessage__Icon} />
      <Typography>{children}</Typography>
    </div>
  )
}

export default ErrorMessage
