import { Icon, Popover, Toaster } from '@matillion/component-library'
import { FC, useCallback, useState } from 'react'
import { IconButton } from '../../../../components/ReactFlow/ZoomToolbar/ToolbarButton/ToolbarButton'
import classes from './DropdownMenu.module.scss'
import { PipelineLinks } from '../../../../types/eos'
import { useCancelPipeline } from '../../../../api/hooks/useCancelPipeline'
import { useTranslation } from 'react-i18next'
import ForceCancelDialog from './ForceCancelDialog'
import MenuItems from './MenuItems'

export interface DropdownMenuProps {
  pipelineId: string
  links: PipelineLinks
}

const DropdownMenu: FC<DropdownMenuProps> = ({ pipelineId, links }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)
  const [showForceCancelDialog, setShowForceCancelDialog] = useState(false)

  const href = links.designer?.href ?? ''

  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()
  const { mutate: mutateCancel } = useCancelPipeline(pipelineId)

  const cancelPipeline = useCallback(
    (id: string, force: boolean) => {
      mutateCancel(
        { pipelineId: id, force },
        {
          onError() {
            makeToast({
              type: 'warning',
              title: t('activity.cancellation.cancelFailedTitle'),
              message: t('activity.cancellation.cancelFailedMessage')
            })
          },
          onSuccess() {
            makeToast({
              type: 'success',
              title: t('activity.cancellation.cancelSuccessTitle'),
              message: t('activity.cancellation.cancelSuccessMessage')
            })
          }
        }
      )
    },
    [mutateCancel, makeToast, t]
  )

  return (
    <>
      {showForceCancelDialog && (
        <ForceCancelDialog
          close={() => setShowForceCancelDialog(false)}
          onConfirm={() => cancelPipeline(pipelineId, true)}
        />
      )}
      <Popover
        className={classes.DropdownMenu}
        isOpen={isOpen}
        position={'bottom'}
        align="end"
        onClickOutside={() => setIsOpen(false)}
        anchor={
          <div
            className={classes.DropdownMenu__Container}
            data-testid="more-button"
          >
            <IconButton
              name="more"
              label="more"
              data-testid="more-button"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Icon.Ellipsis
                className={isOpen ? classes.DropdownMenu__Icon__Green : ''}
              />
            </IconButton>
          </div>
        }
      >
        <MenuItems
          href={href}
          isCancelling={isCancelling}
          pipelineId={pipelineId}
          cancelPipeline={cancelPipeline}
          setIsCancelling={setIsCancelling}
          setShowForceCancelDialog={setShowForceCancelDialog}
        />
      </Popover>
    </>
  )
}

export default DropdownMenu
