import classNames from 'classnames'
import { FC, useState } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import OrchestrationPipelineIcon from 'svg/OrchestrationPipelineIcon'
import TransformationPipelineIcon from 'svg/TransformationPipelineIcon'
import { PipelineData } from 'types/dgs'
import classes from './PipelineNode.module.scss'
import { NodeData } from 'components/ReactFlow/LineageGraph'

export const C_BG = 'white'
export const C_BORDER = '#DDDDDD'
export const C_ORCH_SELECTED_BG = '#D6E3F2'
export const C_TRAN_SELECTED_BG = '#EAEFE5'
export const C_ORCH_SELECTED_ICON = '#081D4C'
export const C_TRAN_SELECTED_ICON = '#2D8825'

export type CustomPipelineNodeProps = NodeProps<NodeData<PipelineData>>

const CustomPipelineNode: FC<CustomPipelineNodeProps> = ({
  data,
  selected
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className={classes.PipelineNode}
      data-testid="pipeline-node"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Handle
        type="target"
        position={Position.Left}
        className={classNames(
          classes.PipelineNode__Handle,
          classes.PipelineNode__Handle__Left
        )}
        isConnectable={false}
      />
      {data.content.kind === 'ORCHESTRATION' ? (
        <OrchestrationPipelineIcon
          backgroundColor={selected ? C_ORCH_SELECTED_BG : C_BG}
          iconColor={C_ORCH_SELECTED_ICON}
          borderColor={selected || isHovered ? C_ORCH_SELECTED_ICON : C_BORDER}
          width="32"
          height="32"
          data-testid="orchestration-icon"
        />
      ) : (
        <TransformationPipelineIcon
          backgroundColor={selected ? C_TRAN_SELECTED_BG : C_BG}
          iconColor={C_TRAN_SELECTED_ICON}
          borderColor={selected || isHovered ? C_TRAN_SELECTED_ICON : C_BORDER}
          width="32"
          height="32"
          data-testid="transformation-icon"
        />
      )}
      <Handle
        type="source"
        position={Position.Right}
        className={classNames(
          classes.PipelineNode__Handle,
          classes.PipelineNode__Handle__Right
        )}
        isConnectable={false}
      />
    </div>
  )
}

export default CustomPipelineNode
