import { FC } from 'react'
import { Typography } from '@matillion/component-library'
import classes from './EditorDisplay.module.scss'

interface EditorDisplayProps {
  text: string
}

const EditorDisplay: FC<EditorDisplayProps> = ({ text }) => {
  const lines = text.split('\n')

  return (
    <div className={classes.EditorDisplay}>
      <div className={classes.EditorDisplay__LineContainer}>
        <div className={classes.EditorDisplay__Gutter}>
          {lines.map((line, i) => (
            <Typography format="bcs" key={line}>
              <span className={classes.EditorDisplay__LineNumber}>{i + 1}</span>
            </Typography>
          ))}
        </div>
        <div className={classes.EditorDisplay__MessageContainer}>
          <Typography format="bcs">
            <pre className={classes.EditorDisplay__Message}>{text}</pre>
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default EditorDisplay
