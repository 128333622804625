import { useFlags } from '@matillion/hub-client'
import { FC, memo, useMemo } from 'react'
import {
  BaseEdge,
  EdgeProps,
  getBezierPath,
  getSmoothStepPath
} from 'reactflow'

export const C_SELECTED = '#0C2656'
export const C_NORMAL = '#6C6C6C'
export const C_BACKGROUND = '#DDDDDD'

let id = 0

export interface EdgeData {
  meta: {
    isInActiveChain?: boolean
    isInBackground?: boolean
  }
}

export const CustomEdge: FC<EdgeProps<EdgeData>> = memo(
  ({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    markerEnd,
    data,
    ...props
  }) => {
    const { rolloutEnableGraphUpdates } = useFlags()
    const pathFunc = rolloutEnableGraphUpdates
      ? getBezierPath
      : getSmoothStepPath

    const [path] = pathFunc({
      sourceX: sourceX - 3,
      sourceY,
      sourcePosition,
      targetPosition,
      targetX: targetX + 3,
      targetY
    })
    const isInActiveChain = data?.meta?.isInActiveChain
    const isInBackground = data?.meta?.isInBackground
    const markerId = useMemo(() => `custom-edge-marker-id-${id++}`, [])
    const markerColor = useMemo(() => {
      if (isInActiveChain) {
        return C_SELECTED
      }

      return isInBackground ? C_BACKGROUND : C_NORMAL
    }, [isInActiveChain, isInBackground])

    const source = props.sourceHandleId
      ? `${props.source}.${props.sourceHandleId}`
      : props.source
    const target = props.targetHandleId
      ? `${props.target}.${props.targetHandleId}`
      : props.target
    const testId = ['edge-between', source, 'to', target].join('-')

    return (
      <>
        <svg
          data-testid={testId}
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <marker
              data-testid={`marker-${props.id}`}
              id={markerId}
              viewBox="0 0 9 14"
              refX="7"
              refY="7"
              markerUnits="userSpaceOnUse"
              markerWidth="9"
              markerHeight="14"
              orient="auto-start-reverse"
            >
              <path
                data-testid="marker-path"
                d="M1 1L7 7L1 13"
                stroke={markerColor}
                strokeWidth="2"
              />
            </marker>
          </defs>
        </svg>
        <BaseEdge
          {...props}
          data-testid="custom-edge"
          path={path}
          markerEnd={`url(#${markerId})`}
          style={{ stroke: markerColor, strokeWidth: 2 }}
        />
      </>
    )
  }
)

CustomEdge.displayName = 'CustomEdge'
