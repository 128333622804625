const DATA_WAREHOUSE = {
  REDSHIFT: {
    label: 'Amazon Redshift',
    svgFile: 'redshift'
  },
  SNOWFLAKE: {
    label: 'Snowflake',
    svgFile: 'snowflake'
  },
  BIGQUERY: {
    label: 'BigQuery',
    svgFile: 'bigquery'
  },
  SYNAPSE: {
    label: 'Azure Synapse',
    svgFile: 'synapse'
  },
  DELTALAKE: {
    label: 'Delta Lake on Databricks',
    svgFile: 'databricks'
  }
}

const PLATFORMS = {
  AWS: {
    label: 'AWS',
    svgFile: 'aws'
  },
  GCP: {
    label: 'Google Cloud',
    svgFile: 'gcp'
  },
  AZURE: {
    label: 'Microsoft Azure',
    svgFile: 'azure'
  }
}

export type keyPlatformTypes = keyof typeof PLATFORMS
export type keyDataWarehouseTypes = keyof typeof DATA_WAREHOUSE

export { PLATFORMS, DATA_WAREHOUSE }
