import { FunctionComponent } from 'react'
import { JobStatus } from 'types/jobs'
import classes from './JobStatusIcon.module.scss'
import classnames from 'classnames'
import { LoadingSpinner } from '@matillion/component-library'

const JobStatusIcon: FunctionComponent<{ status: JobStatus }> = ({
  status
}) => {
  if (status === 'RUNNING') {
    return <LoadingSpinner className={classes.RunningDots} />
  }
  return (
    <svg
      aria-hidden="true"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      className={classnames([classes.JobStatusIcon, classes[status]])}
    >
      <circle cx="4" cy="4" r="4" fill="currentColor" />
    </svg>
  )
}

export default JobStatusIcon
