import SearchInput from 'components/SearchInput'
import { FunctionComponent } from 'react'

interface SearchJobsProps {
  value: string
  onSearch: (args: string) => void
}

const SearchJobs: FunctionComponent<SearchJobsProps> = ({
  value,
  onSearch
}) => {
  const handleKeyUp = (e: { target: { value: string } }) => {
    onSearch(e.target.value)
  }

  return (
    <SearchInput
      onChange={handleKeyUp}
      aria-label="Search by job name"
      data-tracker="jobs-item-search"
      value={value}
      placeholder="Search by job name"
    />
  )
}

export default SearchJobs
