import { FC } from 'react'
import { PipelineStatus } from 'types/eos'
import { getStateIcon } from '../utils/getStateIcon'
import classes from './TaskStatusIcon.module.scss'
import { Tooltip } from '@matillion/component-library'

interface TaskStatusIconProps {
  status: PipelineStatus
  depth: string
}

const TaskStatusIcon: FC<TaskStatusIconProps> = ({ status, depth }) => {
  const taskLabel = status.toLowerCase()
  const Icon = getStateIcon(status)
  return (
    <Tooltip content={taskLabel}>
      <div
        className={classes.PipelineStatus}
        data-testid={`treeGrid-item-${depth}`}
      >
        <Icon className={classes.PipelineStatus__Icon} aria-label={taskLabel} />
      </div>
    </Tooltip>
  )
}

export default TaskStatusIcon
