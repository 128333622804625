import { FunctionComponent } from 'react'
import { Instance, InstanceState } from 'types/installations'
import { Badge, Tooltip } from '@matillion/component-library'
import { statusToColor } from './instanceStateToColor'
import classes from './Status.module.scss'

const tooltips: Record<InstanceState, string> = {
  STOPPED:
    'This instance has been stopped; please contact your provider for more information',
  RUNNING: 'This instance is running and sending us data',
  ERROR:
    'There was an error when starting your instance; please contact your provider for more information',
  RESTARTING:
    'This instance is currently restarting; please contact your provider for more information'
}

const Status: FunctionComponent<Pick<Instance, 'state'>> = ({ state }) => (
  <Tooltip content={tooltips[state]}>
    <span className={classes.Status}>
      <Badge
        className={classes.Status__Badge}
        colour={statusToColor[state]}
        data-testid="instance-card-metadata-state"
        data-tracker="instance-status-text"
        theme="filled"
      >
        {state.toLowerCase()}
      </Badge>
    </span>
  </Tooltip>
)

export default Status
