import { FC } from 'react'

export interface VerticalLineProps {
  y1?: string
  y2?: string
}

const VerticalLine: FC<VerticalLineProps> = ({ y1 = '0', y2 = '100%' }) => {
  return (
    <line x1="0" y1={y1} x2="0" y2={y2} stroke="currentColor" strokeWidth="1" />
  )
}

export default VerticalLine
