const ErrorConnection = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.68642 41.3988C3.90269 44.0605 5.93945 46.0973 8.60122 46.3135C11.964 46.5868 17.3292 46.875 25 46.875C32.6708 46.875 38.036 46.5868 41.3988 46.3135C44.0605 46.0973 46.0973 44.0605 46.3135 41.3988C46.5868 38.036 46.875 32.6708 46.875 25C46.875 17.3292 46.5868 11.964 46.3135 8.60122C46.0973 5.93946 44.0605 3.90269 41.3988 3.68643C38.036 3.41321 32.6708 3.125 25 3.125C17.3292 3.125 11.964 3.4132 8.60123 3.68642C5.93946 3.90268 3.90269 5.93945 3.68643 8.60122C3.41321 11.964 3.125 17.3292 3.125 25C3.125 32.6708 3.4132 38.036 3.68642 41.3988Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.64648 14.5835H46.3548"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.416 9.375H12.4993"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 9.375H20.8333"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 29.1662C14.6111 26.6177 17.1235 24.8487 19.7917 23.8589M37.5 29.1662C35.3889 26.6177 32.8765 24.8487 30.2083 23.8589"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.666 34.3753C17.6049 33.2421 18.6627 32.3399 19.791 31.6689M33.3327 34.3753C32.3938 33.2421 31.336 32.3399 30.2077 31.6689"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25 22.9165V33.3332"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25 38.5415V39.5811"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export default ErrorConnection
