import { Node } from 'reactflow'

export interface Position {
  x: number
  y: number
}

export const getNodesInArea = (
  nodes: Node[],
  topLeft: Position,
  bottomRight: Position
) => {
  return nodes.filter((node) => {
    const { x, y } = node.position

    return (
      x >= topLeft.x &&
      x <= bottomRight.x &&
      y >= topLeft.y &&
      y <= bottomRight.y
    )
  })
}

export const doNodesIntersect = (node1: Node, node2: Node) => {
  const { x, y } = node1.position
  const width1 = node1.width
  const height1 = node1.height

  const { x: x2, y: y2 } = node2.position
  const width2 = node2.width
  const height2 = node2.height

  if (!width1 || !height1 || !width2 || !height2) {
    return false
  }

  if (
    x + width1 < x2 ||
    x2 + width2 < x ||
    y + height1 < y2 ||
    y2 + height2 < y
  ) {
    return false // No intersection
  }

  return true // Nodes intersect
}

export const repositionSecondNode = (node1: Node, node2: Node) => {
  if (!node1.width || !node1.height || !node2.width || !node2.height) {
    return node2
  }

  const center2 = {
    x: node2.position.x + node2.width / 2,
    y: node2.position.y + node2.height / 2
  }

  const distanceToTop = Math.abs(center2.y - node1.position.y)
  const distanceToBottom = Math.abs(
    center2.y - (node1.position.y + node1.height)
  )

  const minDistance = Math.min(distanceToTop, distanceToBottom)

  // Reposition node2 based on the closest edge
  if (minDistance === distanceToTop) {
    // Move node2 above node1
    node2.position.y = node1.position.y - node2.height - 10
  } else {
    // Move node2 below node1
    node2.position.y = node1.position.y + node1.height + 10
  }

  return node2
}

export const repositionIntersectingNodes = (
  nodeToCheck: Node,
  allNodes: Node[],
  depth = 0
) => {
  const MAX_DEPTH = 20
  if (depth > MAX_DEPTH) {
    console.warn('Max depth exceeded, potential infinite loop detected')
    return allNodes
  }

  let updatedNodes = [...allNodes]
  const height = nodeToCheck.height as number
  const width = nodeToCheck.width as number

  const areaBounds = {
    topLeft: {
      x: nodeToCheck.position.x - width - 1000,
      y: nodeToCheck.position.y - height - 1000
    },
    bottomRight: {
      x: nodeToCheck.position.x + width + 1000,
      y: nodeToCheck.position.y + height + 1000
    }
  }

  const nearbyNodes = getNodesInArea(
    allNodes,
    areaBounds.topLeft,
    areaBounds.bottomRight
  )

  const intersectingNodes = nearbyNodes.filter(
    (node) => node.id !== nodeToCheck.id && doNodesIntersect(nodeToCheck, node)
  )

  if (intersectingNodes.length === 0) {
    return updatedNodes // No intersections, base case for recursion
  }

  intersectingNodes.forEach((intersectingNode) => {
    const repositionedNode = repositionSecondNode(nodeToCheck, intersectingNode)
    updatedNodes = updatedNodes.map((node) =>
      node.id === intersectingNode.id ? repositionedNode : node
    )

    // Recursive call for the newly repositioned node
    updatedNodes = repositionIntersectingNodes(
      repositionedNode,
      updatedNodes,
      depth + 1
    )
  })

  return updatedNodes
}
