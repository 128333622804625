import React, { FunctionComponent } from 'react'

import useEnvironment from '../../../effects/useEnvironment'
import encodeLocation from '../../../utils/encodeLocation'

const SwitchOrganisationLink: FunctionComponent<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { hubUrl, appUrl } = useEnvironment()

  const returnTo = {
    origin: appUrl,
    pathname: '/',
    search: ''
  }

  return (
    <a {...props} href={`${hubUrl}?returnTo=${encodeLocation(returnTo)}`}>
      {children}
    </a>
  )
}

export default SwitchOrganisationLink
