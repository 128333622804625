import { Typography } from '@matillion/component-library'
import classnames from 'classnames'
import { useStatusTranslation } from 'hooks/useStatusTranslation'
import { FunctionComponent } from 'react'
import { JobStatus as JobStatusType } from 'types/jobs'
import classes from './JobStatus.module.scss'
import JobStatusIcon from './JobStatusIcon'

const JobStatus: FunctionComponent<{ status: JobStatusType }> = ({
  status
}) => {
  const t = useStatusTranslation()

  return (
    <>
      <JobStatusIcon status={status} />
      <Typography
        format="bcs"
        className={classnames([classes.JobStatus, classes[status]])}
        data-testid="status"
        data-tracker="jobs-item-status-text"
      >
        {t(status)}
      </Typography>
    </>
  )
}

export default JobStatus
