import { Icon, Typography } from '@matillion/component-library'
import { FunctionComponent } from 'react'
import { JobType, Jobs } from 'types/jobs'
import { OrchestrationIcon, TransformationIcon } from '../svg'
import classes from './JobLink.module.scss'

const typeLabels: Record<JobType, string> = {
  ORCHESTRATION: 'Orchestration',
  TRANSFORMATION: 'Transformation'
}

const typeIcons: Record<JobType, FunctionComponent> = {
  ORCHESTRATION: OrchestrationIcon,
  TRANSFORMATION: TransformationIcon
}

type JobsProps = Pick<Jobs, 'type' | 'url' | 'name'>

const JobLink: FunctionComponent<JobsProps> = ({ type, url, name }) => {
  const typeLabel = typeLabels[type]
  const TypeIcon = typeIcons[type]
  return (
    <div className={classes.Wrapper} data-testid="job-link">
      <span title={typeLabel}>
        <TypeIcon />
      </span>
      {url ? (
        <Typography
          as="a"
          format="bcs"
          className={classes.JobLink}
          href={url}
          target="_blank"
          rel="noreferrer"
          data-testid="name"
          data-tracker="jobs-item-title-link"
        >
          {name}
          <Icon.Export />
        </Typography>
      ) : (
        <Typography format="bcs" className={classes.JobName} data-testid="name">
          {name}
        </Typography>
      )}
    </div>
  )
}

export default JobLink
