import { FunctionComponent } from 'react'
import { Icon } from '@matillion/component-library'
import classes from './ContactSupportLink.module.scss'
const { Support } = Icon

const ContactSupportLink: FunctionComponent = () => {
  return (
    <a
      href="https://www.matillion.com/support/"
      target="_blank"
      rel="noreferrer"
      className={classes.ContactSupportLink__Links}
    >
      <Support
        height={18}
        width={18}
        className={classes.ContactSupportLink__Icons}
        aria-hidden="true"
      />
      contact support
    </a>
  )
}

export default ContactSupportLink
