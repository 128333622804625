import { useGetDatasetsInfinite } from 'api/hooks/useGetDatasets'
import { CenteredSpinner } from 'components/CenteredSpinner'
import ErrorMessage from 'components/ErrorMessage'
import { Cell, Row, TBody, THead, Table } from 'components/DataTable'
import classes from './DatasetList.module.scss'
import TypeIcon from './TypeIcon'
import { Tooltip, Typography } from '@matillion/component-library'
import { ResultsMessage } from 'components/ResultsMessage'
import { CellTypography } from 'components/CellTypography'
import { Link, useSearchParams } from 'react-router-dom'
import DATASET_TYPE_LOOKUP from './DatasetTypeLookup'
import { InfiniteScrollAnchor } from 'components/InfiniteScrollAnchor'
import { useTranslation } from 'react-i18next'
import SearchInput from 'components/SearchInput'
import classNames from 'classnames'

const DatasetList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { datasets, isFetching, isError, hasMore, fetchNextPage } =
    useGetDatasetsInfinite(searchParams.get('search') ?? undefined)

  const { t } = useTranslation()

  if (isError) {
    return <ErrorMessage>{t('lineage.datasetList.error')}</ErrorMessage>
  }

  return (
    <>
      <div className={classes.DatasetList__Search}>
        <SearchInput
          value={searchParams.get('search') ?? ''}
          onChange={(e) =>
            setSearchParams({ search: e.target.value }, { replace: true })
          }
          placeholder={t('misc.search')}
          className={classes.DatasetList__Search__Input}
        />
      </div>
      <Typography className={classes.DatasetList__Results_Number}>
        {t('misc.displayingResults', { count: datasets?.length })}
      </Typography>
      <Table>
        <THead className={classes.DatasetList__Header} sticky>
          <Row className={classes.DatasetList__Header_Row}>
            <Cell className={classes.DatasetList__Cell} semantics="head">
              {t('misc.tableName')}
            </Cell>
            <Cell className={classes.DatasetList__Cell} semantics="head">
              {t('misc.infrastructure')}
            </Cell>
            <Cell className={classes.DatasetList__Cell} semantics="head">
              {t('misc.location')}
            </Cell>
          </Row>
        </THead>
        <TBody>
          {datasets?.map((dataset) => {
            const { path, name, namespace, type, id } = dataset
            const breadcrumbs = path.join(' / ')
            return (
              <Row key={id} className={classes.DatasetList__Row}>
                <Cell className={classes.DatasetList__Cell}>
                  <Tooltip content={name}>
                    <Link
                      to={`/lineage/dataset/${id}`}
                      className="u-truncate-text"
                    >
                      <Typography
                        format={'bcs'}
                        weight={'regular'}
                        className={classNames(
                          classes.DatasetList__Link,
                          'u-truncate-text'
                        )}
                      >
                        {name}
                      </Typography>
                    </Link>
                  </Tooltip>
                </Cell>
                <Cell className={classes.DatasetList__Cell}>
                  <TypeIcon iconName={DATASET_TYPE_LOOKUP[type]} />
                  <CellTypography
                    format="bcs"
                    text={
                      DATASET_TYPE_LOOKUP[type]
                        ? DATASET_TYPE_LOOKUP[type]
                        : t('misc.unknown')
                    }
                  />
                </Cell>
                <Cell className={classes.DatasetList__Cell}>
                  <CellTypography
                    format="bcs"
                    text={`${namespace} ${
                      breadcrumbs ? '/ ' + breadcrumbs : ''
                    }`}
                  />
                </Cell>
              </Row>
            )
          })}
        </TBody>
      </Table>
      {!isFetching && <InfiniteScrollAnchor onIntersect={fetchNextPage} />}
      <div className={classes.Spinner}>{isFetching && <CenteredSpinner />}</div>
      {datasets?.length === 0 && !isError && (
        <ResultsMessage>{t('lineage.datasetList.noResults')}</ResultsMessage>
      )}
      {!isFetching && !hasMore && !isError && (
        <div className={classes.DatasetList__EndOfResults}>
          <Typography type="bcl">{t('misc.endOfResults')}</Typography>
        </div>
      )}
    </>
  )
}

export default DatasetList
