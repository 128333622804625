import { RedirectToLocation } from '../types'

/**
 * Encodes the provided RedirectToLocation in a format suitable for
 * transmission in a URL's query parameters. This allows us to pass
 * return URLs/state to Auth0 when redirecting bcack through the Hub.
 *
 * @param location The RedirectToLocation to encode
 * @returns An encoded string representing the provided location
 */
const encodeLocation = (location: RedirectToLocation) =>
  btoa(JSON.stringify(location))

export default encodeLocation
