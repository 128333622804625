import { FunctionComponent, PropsWithChildren } from 'react'
import { Layout } from '@matillion/component-library'
import OdbAppMenu from './OdbAppMenu'
import { HubHeader } from '@matillion/hub-client'

const AppLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <Layout header={<HubHeader />} appMenu={<OdbAppMenu />}>
      {children}
    </Layout>
  )
}

export default AppLayout
