import { DatasetData } from 'types/dgs'
import { NodeData } from '../../LineageGraph'
import { FC, useState } from 'react'
import InformationPanelHeader from '../InformationPanelHeader'
import TypeIcon from 'modules/Lineage/DatasetList/TypeIcon'
import DATASET_TYPE_LOOKUP from 'modules/Lineage/DatasetList/DatasetTypeLookup'
import { Tab, Tabs } from '@matillion/component-library'
import DatasetDetailsTable from '../InformationPanelTable/DatasetDetailsTable'
import Columns from '../Columns/Columns'
import classes from './DatasetDetails.module.scss'
import { useGetDataset } from 'api/hooks/useGetDataset'
import { CenteredSpinner } from 'components/CenteredSpinner'

export interface DatasetDetailsProps {
  nodeData: NodeData<DatasetData>
}

const DatasetDetails: FC<DatasetDetailsProps> = ({ nodeData }) => {
  const [activeTab, setActiveTab] = useState('Details')

  const Icon = (
    <TypeIcon
      className={classes.DatasetDetails__Header__Icon}
      iconName={DATASET_TYPE_LOOKUP[nodeData.content.type]}
      width="32"
      height="32"
    />
  )

  const { data, isLoading } = useGetDataset(nodeData.content.id)

  if (isLoading) return <CenteredSpinner />
  if (!data) return

  return (
    <>
      <div className={classes.DatasetDetails__Header__Container}>
        <InformationPanelHeader
          Icon={Icon}
          title={nodeData.content.name}
          subtitle={nodeData.content.namespace}
          className={classes.DatasetDetails__Header}
        />
        <Tabs
          onChange={(change) => setActiveTab(change.tab.title)}
          listClassName={classes.DatasetDetails__Tabs}
        >
          <Tab title="Details" />
          <Tab title="Columns" />
        </Tabs>
      </div>
      <div className={classes.DatasetDetails__Content}>
        {activeTab === 'Details' ? (
          <DatasetDetailsTable data={data} />
        ) : (
          <Columns fields={data.fields} />
        )}
      </div>
    </>
  )
}

export default DatasetDetails
