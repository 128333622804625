import { FC } from 'react'
import classes from './DatasetList.module.scss'
import { Icon } from '@matillion/component-library'
import classNames from 'classnames'

interface TypeIconProps {
  iconName?: string
  width?: string
  height?: string
  className?: string
}

const TypeIcon: FC<TypeIconProps> = ({ iconName, className, ...props }) => {
  if (!iconName) {
    return (
      <Icon.CloudDataWarehouse
        height="16"
        width="16"
        className={classes.DatasetList__Type_Icon}
      />
    )
  }

  const cdnURL = `https://connector-logos.matillion.com/${iconName}.svg`

  return (
    <img
      src={cdnURL}
      alt={`${iconName} logo`}
      width="16"
      height="16"
      className={classNames(classes.DatasetList__Type_Icon, className)}
      {...props}
    />
  )
}

export default TypeIcon
