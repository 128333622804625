import { FC, useState } from 'react'

import { Icon, MicroCta, Modal, Typography } from '@matillion/component-library'

import classes from './CellMessage.module.scss'
import EditorDisplay from 'components/EditorDisplay'

export interface CellMessageProps {
  text: string
  component: string
}

const CellMessage: FC<CellMessageProps> = ({ text, component }) => {
  const [messageModalOpen, setMessageModalOpen] = useState(false)

  return (
    <div className={classes.CellMessage}>
      <div className="u-truncate-text-2-lines">
        <Typography format="bcs" as="span">
          {text}
        </Typography>
      </div>
      {text && (
        <MicroCta
          aria-label="Open message fullscreen"
          alt="transparent"
          onClick={() => setMessageModalOpen(true)}
          className={classes.CellMessage__CTA}
        >
          <Icon.ArrowRightSmall />
        </MicroCta>
      )}
      {messageModalOpen && text && (
        <Modal
          onCancel={() => setMessageModalOpen(false)}
          ariaLabelledBy="modal-title"
          size="large"
          className={classes.CellMessageModal}
        >
          <Typography
            as="h2"
            format="tl"
            id="modal-title"
            className={classes.CellMessageModal__Title}
          >
            Message
          </Typography>
          <Typography
            as="h3"
            format="ts"
            className={classes.CellMessageModal__Subtitle}
          >
            {component}
          </Typography>
          <EditorDisplay text={text} />
        </Modal>
      )}
    </div>
  )
}

export default CellMessage
