import { AxiosInstance } from 'axios'
import {
  Installation,
  InstallationId,
  InstallationsQueryResponse,
  InstanceId
} from 'types/installations'
import { JobsQueryResponse } from 'types/jobs'
import { formatISO } from 'date-fns'

interface JobsFilterRequest {
  installationId: InstallationId
  instanceId: InstanceId
  name?: string
  page?: number
  itemsPerPage?: number
  start?: Date
  end?: Date
}

interface UpdateMetadataRequest {
  description?: string
  installationId: string
}

/* istanbul ignore next */
export const getInstallations = async (
  request: AxiosInstance
): Promise<Installation[]> => {
  const {
    data: { content }
  } = await request.get<InstallationsQueryResponse>('/installations', {
    params: {
      page: 0,
      size: 999
    }
  })
  return content
}

export const getJobs = async (
  jobsFilterRequest: JobsFilterRequest,
  request: AxiosInstance
): Promise<JobsQueryResponse> => {
  const { installationId, instanceId, name, page, itemsPerPage, start, end } =
    jobsFilterRequest
  const startDateTime = start && formatISO(start)
  const endDateTime = end && formatISO(end)
  const { data } = await request.get<JobsQueryResponse>(
    `/installations/${installationId}/instances/${instanceId}/jobs`,
    {
      params: {
        name,
        page,
        size: itemsPerPage,
        start: startDateTime,
        end: endDateTime
      }
    }
  )

  return data
}

export const updateMetadata = async (
  http: AxiosInstance,
  metaDataRequest: UpdateMetadataRequest
) => {
  const { installationId, description } = metaDataRequest
  const metadata = await http.put(`/installations/${installationId}/metadata`, {
    description: description
  })

  return metadata
}
