import { Icon, MicroCta } from '@matillion/component-library'
import classNames from 'classnames'
import { CellTypography } from 'components/CellTypography'
import { FC } from 'react'
import { PipelineStatus } from 'types/eos'
import classes from './PipelineNameCell.module.scss'
import TaskStatusIcon from './TaskStatusIcon'
import { DEPTH_MARGIN } from './Guidelines/Guidelines'

interface PipelineNameCellProps {
  depth: number
  pipelineName: string
  isExpanded: boolean
  hasNestedStuff: boolean
  onClick: () => void
  status: PipelineStatus
}

const PipelineNameCell: FC<PipelineNameCellProps> = ({
  depth,
  pipelineName,
  isExpanded,
  hasNestedStuff,
  onClick,
  status
}) => {
  return (
    <div
      className={classNames(
        classes.PipelineNameCell,
        classes.PipelineNameCell__Expander
      )}
    >
      <div style={{ marginLeft: `${depth * DEPTH_MARGIN}px` }}>
        {hasNestedStuff && (
          <MicroCta
            aria-label={isExpanded ? 'collapse' : 'expand'}
            alt="transparent"
            size="sm"
            className={classNames(classes.RowExpandTrigger, {
              [classes.expanded]: isExpanded
            })}
            onClick={onClick}
          >
            <Icon.ChevronDown />
          </MicroCta>
        )}
      </div>
      <TaskStatusIcon status={status} depth={String(depth)} />
      <CellTypography text={pipelineName} />
    </div>
  )
}

export default PipelineNameCell
