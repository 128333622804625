import { FunctionComponent } from 'react'
import Instance from './Instance'
import useInstallationsAndInstances from './useInstallationsAndInstances'
import NoInstancesMessage from './NoInstancesMessage'
import { safeString } from 'utils/safeString'

const InstancesList: FunctionComponent = () => {
  const { installations, instances } = useInstallationsAndInstances()

  if (!instances.length) return <NoInstancesMessage />

  return (
    <>
      {installations.map((installation) => (
        <Instance
          key={installation.installationId}
          instances={installation?.instances}
          installationId={installation.installationId}
          installationName={installation.installationName}
          description={safeString(installation.installationDescription)}
        />
      ))}
    </>
  )
}

export default InstancesList
