import { Icon, Tooltip, Typography } from '@matillion/component-library'
import classNames from 'classnames'
import DATASET_TYPE_LOOKUP from 'modules/Lineage/DatasetList/DatasetTypeLookup'
import TypeIcon from 'modules/Lineage/DatasetList/TypeIcon'
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow'
import { DatasetData } from 'types/dgs'
import classes from './DatasetNode.module.scss'
import Field from './Field'
import { NodeData } from 'components/ReactFlow/LineageGraph'
import { useAreDatasetsLoading } from 'modules/DatasetDetailView/Lineage/useColumnLineage'

export type CustomDatasetNodeProps = NodeProps<NodeData<DatasetData>>

const CustomDatasetNode: FC<CustomDatasetNodeProps> = ({
  id,
  data,
  selected
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const updateNodeInternals = useUpdateNodeInternals()
  const {
    content,
    meta: {
      isExpanded,
      selectedField,
      filteredColumnEdges,
      connectedEdges,
      toggleExpanded,
      setSelectedField,
      onExpand
    }
  } = data
  const datasetsLoading = useAreDatasetsLoading(connectedEdges ?? [])

  const nodeRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    updateNodeInternals(id)
  }, [id, isExpanded, datasetsLoading, updateNodeInternals])

  useLayoutEffect(() => {
    if (onExpand && nodeRef.current && isExpanded) {
      const height = nodeRef.current.getBoundingClientRect().height
      onExpand(id, height)
    }
  }, [onExpand, isExpanded, id])

  return (
    <div
      className={classNames(classes.DatasetNode, {
        [classes['DatasetNode--Selected']]: selected
      })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-testid="dataset-node"
      data-dataset-id={id}
      data-dataset-name={content.name}
      ref={nodeRef}
    >
      <Handle
        type="target"
        position={Position.Left}
        className={classes.DatasetNode__Handle}
      />
      <div
        className={classNames(classes.DatasetNode__NodeHeader, {
          [classes['DatasetNode__NodeHeader--Selected']]: selected,
          [classes['DatasetNode__NodeHeader--IsExpanded']]: isExpanded,
          [classes['DatasetNode__NodeHeader--IsExpanded--IsHovered']]:
            isHovered && isExpanded
        })}
      >
        <TypeIcon
          iconName={DATASET_TYPE_LOOKUP[content.type]}
          width="32"
          height="32"
          className={classes.DatasetNode__TypeIcon}
        />
        <Tooltip
          content={[...content.path, content.name].join(' / ')}
          className={classes.DatasetNode__Tooltip}
          position="top"
        >
          <div
            className={classNames(
              classes.DatasetNode__Headers,
              'u-truncate-text'
            )}
          >
            <Typography className="u-truncate-text" format="bcm">
              {content.name}
            </Typography>
            <Typography
              className={classNames(
                classes.DatasetNode__NodeHeader__Subtitle,
                'u-truncate-text'
              )}
              format="bcs"
            >
              {content.namespace}
            </Typography>
          </div>
        </Tooltip>

        {content.fields.length > 0 && (
          <div
            className={classNames(
              classes.DatasetNode__NodeHeader__ButtonContainer,
              'nodrag'
            )}
            tabIndex={0}
            role="button"
            aria-label={isExpanded ? 'Collapse columns' : 'Expand columns'}
            onClick={(e) => {
              e.stopPropagation()
              if (toggleExpanded) toggleExpanded(id)
            }}
          >
            <Icon.ChevronDown
              height={24}
              width={24}
              className={classNames(
                classes.DatasetNode__NodeHeader__IconButton,
                {
                  [classes['DatasetNode__NodeHeader__IconButton--IsExpanded']]:
                    isExpanded
                }
              )}
            />
          </div>
        )}
      </div>
      <Handle
        type="source"
        position={Position.Right}
        className={classes.DatasetNode__Handle}
      />
      {content.fields.length > 0 && isExpanded && (
        <div className={classes.DatasetNode__Columns}>
          <div className={classes.DatasetNode__Columns__Names}>
            {content.fields.map((field) => (
              <Field
                data-testid={`dataset-node-${id}-field-${field.name}`}
                name={field.name}
                nodeId={id}
                key={`${id}-${field.name}`}
                setSelectedField={setSelectedField}
                selectedField={selectedField}
                filteredColumnEdges={filteredColumnEdges}
                isLoading={datasetsLoading}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomDatasetNode
