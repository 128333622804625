import { InfiniteData } from '@tanstack/react-query'
import { StepsResponse } from 'api/eos'
import _ from 'lodash'
import { NestingAwareStep, StepProgressUpdate } from 'types/eos'

const STEPS_PER_PAGE = 100

const updateOrAddStep = (
  currentSteps: NestingAwareStep[],
  message: StepProgressUpdate
) => {
  const index = currentSteps.findIndex((m) => m.id === message.step.id)

  if (index !== -1) {
    const updatedSteps = [...currentSteps]
    const existingStep = { ...updatedSteps[index] }

    existingStep.result = {
      ...existingStep.result,
      finishedAt: message.step.result.finishedAt,
      message: message.step.result.message,
      rowCount: message.step.result.rowCount,
      status: message.step.result.status
    }
    existingStep.nestedPipeline = message.step.nestedPipeline

    updatedSteps[index] = existingStep
    return updatedSteps
  }

  const newStep: NestingAwareStep = {
    ...message.step,
    nestedSteps: []
  }

  return [...currentSteps, newStep]
}

export function pipelineMessageHandler(
  message: StepProgressUpdate,
  currentStepsData?: InfiniteData<StepsResponse, unknown>
) {
  const clonedData = _.cloneDeep(currentStepsData)
  const allSteps =
    clonedData?.pages.flatMap((page) => page.results as NestingAwareStep[]) ??
    []
  const pagesLength = clonedData?.pages.length ?? 0

  // for each page set the new results filling each page to STEPS_PER_PAGE
  const newResults = updateOrAddStep(allSteps, message)
  clonedData?.pages.forEach((page, index) => {
    const start = index * STEPS_PER_PAGE
    const end = start + STEPS_PER_PAGE
    page.results = newResults.slice(start, end)
  })

  // if there are more results than the current pages allow for, add a new page
  if (newResults.length > pagesLength * STEPS_PER_PAGE) {
    const offset = pagesLength * STEPS_PER_PAGE
    const newPage = {
      results: newResults.slice(offset, newResults.length),
      limit: STEPS_PER_PAGE,
      offset,
      total: newResults.length - offset,
      _links: {}
    }
    clonedData?.pages.push(newPage)
  }

  return clonedData
}
