import { FunctionComponent } from 'react'
import { useMatillionServiceUrl, billingURLPaths } from 'hooks'
import { Button } from '@matillion/component-library'

const AddInstanceBtn: FunctionComponent = () => {
  const { billingUrl } = useMatillionServiceUrl()

  return (
    <a
      data-testid="add-instance-btn"
      data-tracker="add-instance-btn"
      href={billingUrl + billingURLPaths.setup}
      target="_blank"
      rel="noreferrer"
    >
      <Button as="span" size="md" text="Add instance" />{' '}
    </a>
  )
}

export default AddInstanceBtn
