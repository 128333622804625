import { Section, Typography } from '@matillion/component-library'
import { FC, PropsWithChildren } from 'react'
import classes from './HeaderSection.module.scss'

export interface HeaderSectionProps extends PropsWithChildren {
  title: string
}

const HeaderSection: FC<HeaderSectionProps> = ({ children, title }) => {
  return (
    <Section size={12} className={classes.HeaderSection}>
      <Typography as="h1" format="dts">
        {title}
      </Typography>
      {children}
    </Section>
  )
}

export default HeaderSection
