import ErrorPage from 'components/ErrorPage'
import { ErrorBoundary } from 'react-error-boundary'
import PipelineRunDetails from '../PipelineRunDetails'

const PipelineRunDetailsWrapper = () => {
  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <PipelineRunDetails />
    </ErrorBoundary>
  )
}

export default PipelineRunDetailsWrapper
