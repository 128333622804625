import { Tooltip, Typography } from '@matillion/component-library'
import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import classes from './PipelineDetails.module.scss'

interface Props {
  name: string
  value: string
  contents?: ReactNode
  className?: string
}

const Detail: FC<Props> = ({ name, value, className, contents }) => {
  return (
    <div className={classNames(classes.Detail, className)}>
      <Typography format="bcs" weight="bold" className={classes.DetailName}>
        {name}
      </Typography>
      <Tooltip content={value} className={classes.Detail__Tooltip}>
        <div>
          <Typography format="bcs" className={'u-truncate-text-2-lines'}>
            {contents ?? value}
          </Typography>
        </div>
      </Tooltip>
    </div>
  )
}

export default Detail
