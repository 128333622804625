import { useEffect, useState, useCallback } from 'react'
import placeholder from './placeholder.svg'

const useSVGImport = (name: string, svgPath = './logos') => {
  const [svgFile, setSvgFile] = useState('')

  const importSvgFile = useCallback(async () => {
    try {
      const getIconFile = await import(`${svgPath}/${name}.svg`)
      setSvgFile(getIconFile.default)
    } catch (e) {
      setSvgFile(placeholder)
    }
  }, [name, svgPath])

  useEffect(() => {
    void importSvgFile()
  }, [importSvgFile])

  return svgFile
}

export default useSVGImport
