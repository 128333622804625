import { HTMLAttributes, FunctionComponent, PropsWithChildren } from 'react'
import classes from './MetadataBlock.module.scss'
import { Typography } from '@matillion/component-library'

interface MetadataBlockProps extends HTMLAttributes<HTMLDivElement> {
  label: string
}

const MetadataBlock: FunctionComponent<
  PropsWithChildren<MetadataBlockProps>
> = ({ label, children, ...props }) => (
  <div className={classes.MetadataBlock} {...props}>
    <Typography
      format="bcs"
      weight="bold"
      className={classes.MetadataBlock__Label}
    >
      {label}
    </Typography>
    <div className={classes.MetadataBlock__Body}>{children}</div>
  </div>
)

export default MetadataBlock
