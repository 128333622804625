import { FunctionComponent } from 'react'
import useSVGImport from 'svg/useSVGImport'
import classes from './Logo.module.scss'

const Logo: FunctionComponent<{ SVGFileName: string }> = ({ SVGFileName }) => {
  const filePath = useSVGImport(SVGFileName)
  return (
    <img
      loading="lazy"
      src={filePath}
      alt={`${SVGFileName} logo`}
      className={classes.Logo}
    />
  )
}

export default Logo
