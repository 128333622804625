import getMarketoInstance from './getMarketoInstance'
import type { MarketoFormCallback } from '../../globals'

let marketoClientId: string | undefined

/**
 * Initialises the Marketo library. Other Marketo calls will not work
 * unless the function has been called.
 *
 * @param clientId The Munchkin ID of your Marketo instance
 */
export const init = (clientId: string) => {
  marketoClientId = clientId
}

/**
 * Loads the specified Marketo form, and passes it to `callback` when ready.
 * The Marketo library does this using callbacks, rather than async/await,
 * so `callback` will be executed at an indefinite point in the future.
 *
 * @param formId The form version id to load
 * @param callback A function which will be called when the specified form is loaded
 *                 and initialized. The loaded form will be passed to `callback` as
 *                 the only parameter.
 */
export const loadForm = (formId: string, callback: MarketoFormCallback) => {
  if (!marketoClientId) {
    console.debug(
      'Please call init with your Marketo Client ID before trying to load a form!'
    )
    return
  }

  if (!getMarketoInstance()) {
    console.debug('The Marketo forms library has not been loaded.')
    return
  }

  const existingForm = getMarketoInstance()?.getForm(formId)

  if (existingForm) {
    callback(existingForm)
    return
  }

  const form = document.createElement('form')
  form.setAttribute('id', `mktoForm_${formId}`)
  form.setAttribute('data-testid', `mktoForm_${formId}`)
  form.setAttribute('style', 'display: none')
  document.body.appendChild(form)

  getMarketoInstance()?.loadForm(
    '//pages.matillion.com',
    marketoClientId,
    formId,
    (loadedForm) => {
      /* We need to intercept the onSuccess event for forms, as they cause
       * page reloads when trying to redirect to the form's follow-up URL
       * post-submission. Our forms are set to stay on the current page, but
       * that effectively causes the page to reload, meaning that the React
       * app has to load from scratch. */
      loadedForm.onSuccess(() => false)
      callback(loadedForm)
    }
  )
}

/**
 * Loads the specified form and submits the provided information.
 * The Marketo library does this using callbacks, rather than async/await,
 * so this function will complete at an indefinite point in the future.
 *
 * @param formId The form version id to submit this lead for
 * @param email The user's email address, used to identify the lead
 * @param fields The fields to add to this lead
 * @return {Promise<boolean>} a promise that resolves true when the call to marketo is completed successfully within the specified timeout period.
 */
export const postLead = async (
  formId: string,
  email: string,
  fields: Record<string, unknown>,
  timeoutMs = 2000
) => {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(
        new Error(
          `Failed to send form ${formId} to Marketo in ${timeoutMs} ms. It may still be sent later.`
        )
      )
    }, timeoutMs)
    try {
      return loadForm(formId, (form) => {
        form.addHiddenFields({
          Email: email,
          ...fields
        })
        form.onSuccess(() => {
          console.log(`Marketo form ${formId} submitted successfully`)
          clearTimeout(timer)
          resolve(true)
        })
        form.submit()
      })
    } catch (e) {
      clearTimeout(timer)
      console.warn('Could not post lead to Marketo!', e)
      reject(e)
    }
  })
}
