const LineageIcon = ({ ...props }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6668 9.33333H2.00016C1.60016 9.33333 1.3335 9.06667 1.3335 8.66667V4.66667C1.3335 4.26667 1.60016 4 2.00016 4H16.6668C17.0668 4 17.3335 4.26667 17.3335 4.66667V8.66667C17.3335 8.93333 17.0668 9.33333 16.6668 9.33333Z"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.0002 27.9998H10.0002C9.60016 27.9998 9.3335 27.7332 9.3335 27.3332V23.3332C9.3335 22.9332 9.60016 22.6665 10.0002 22.6665H30.0002C30.4002 22.6665 30.6668 22.9332 30.6668 23.3332V27.3332C30.6668 27.5998 30.4002 27.9998 30.0002 27.9998Z"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3335 6.6665H21.3335C21.7335 6.6665 22.0002 6.93317 22.0002 7.33317V18.6665"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3335 16L22.0002 18.6667L24.6668 16"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LineageIcon
