import {
  asyncWithLDProvider,
  ProviderConfig
} from 'launchdarkly-react-client-sdk'
import {
  FunctionComponent,
  useRef,
  useState,
  useEffect,
  ReactElement,
  ReactNode
} from 'react'
import { Loader } from '@matillion/component-library'

import { FullPageLoader } from '../components/FullPageLoader/FullPageLoader'

interface AsyncFlagProviderProps {
  children: ReactElement
  providerProps: ProviderConfig
}

const FlagLoader = function () {
  if (window.__MICROVERSE__) {
    return <FullPageLoader />
  }

  return <Loader />
}

const AsyncFlagProvider: FunctionComponent<
  React.PropsWithChildren<AsyncFlagProviderProps>
> = ({ children, providerProps }) => {
  const ref = useRef<FunctionComponent<{ children: ReactNode }>>()
  const [providerIsReady, setProviderIsReady] = useState(false)

  useEffect(() => {
    const createProvider = async () => {
      ref.current = await asyncWithLDProvider(providerProps)
      setProviderIsReady(true)
    }

    if (!ref.current) {
      createProvider()
    }
  }, [providerIsReady, providerProps])

  return ref.current && providerIsReady ? (
    <ref.current {...providerProps}>{children}</ref.current>
  ) : (
    <FlagLoader />
  )
}

export default AsyncFlagProvider
