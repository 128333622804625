import { ServiceRegistry } from '../index'
import useUser from '../effects/useUser'
import useAuth from '../effects/useAuth'
import { ServiceRegistryContext } from '../context'
import React, { FunctionComponent, useEffect, useState } from 'react'
import useEnvironment from '../effects/useEnvironment'
import { Loader } from '@matillion/component-library'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import offlineRegistry from './offlineRegistry'

const LOCALSTORAGE_KEY = 'serviceRegistry'

export const ServiceRegistryProvider: FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const retryableAxiosClient = axios.create()
  axiosRetry(retryableAxiosClient, {
    retries: 2, // total number of attempts will be retries +1
    retryDelay: axiosRetry.exponentialDelay // default delayFactor is 100ms
  })
  const { isLoggedIn } = useAuth()
  const { organisation } = useUser()
  const [serviceRegistry, setServiceRegistry] = useState<ServiceRegistry>()
  const { offline, registryUrl } = useEnvironment()
  useEffect(() => {
    if (!isLoggedIn) {
      return
    }
    if (offline) {
      const fromLocalStorage = window.localStorage.getItem(LOCALSTORAGE_KEY)
      if (fromLocalStorage) {
        setServiceRegistry(JSON.parse(fromLocalStorage))
      } else {
        setServiceRegistry(offlineRegistry)
      }
      return
    }
    retryableAxiosClient
      .get<ServiceRegistry>(
        `${registryUrl}/${organisation.region ?? 'global'}.json`
      )
      .then((res) => res.data)
      .then((res) => {
        setServiceRegistry(res)
        window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(res))
      })
      .catch(() => {
        console.error(
          'Failed to fetch service registry, attempting to fall back to localStorage'
        )
        const fromLocalStorage = window.localStorage.getItem(LOCALSTORAGE_KEY)
        if (fromLocalStorage) {
          setServiceRegistry(JSON.parse(fromLocalStorage))
        } else {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          setServiceRegistry({
            metadata: {},
            endpoints: {}
          } as ServiceRegistry)
        }
      })
  }, [isLoggedIn, offline, registryUrl, organisation.region])

  if (!isLoggedIn) {
    return <>{children}</>
  }
  return serviceRegistry ? (
    <ServiceRegistryContext.Provider value={serviceRegistry}>
      {children}
    </ServiceRegistryContext.Provider>
  ) : (
    <Loader data-testid="ServiceRegistryContext/Loading" />
  )
}
