import { useMemo } from 'react'

import useEnvironment from './useEnvironment'
import { parseHost } from '../utils/parseHost'
import { prependSubdomain } from '../utils/prependSubdomain'

const useMatillionApplicationURL = (href: string) => {
  const { subdomain } = useEnvironment()

  return useMemo(() => {
    const parsedHost = parseHost(new URL(href).hostname)
    const url = prependSubdomain(href, subdomain)

    return {
      url,
      application: parsedHost.product,
      account: subdomain,
      isActiveApplication: url.hostname === window.location.hostname,
      isActivePathname: url.pathname === window.location.pathname
    }
  }, [href, subdomain])
}

export default useMatillionApplicationURL
