import axios, { AxiosRequestConfig } from 'axios'
import { BillingData } from '../types'

const billingApi = axios.create({
  headers: { 'Content-Type': 'application/json' }
})

export const getBillingData = async (
  token: string,
  requestConfig?: AxiosRequestConfig
) =>
  billingApi.get<BillingData>('/billing/v1/organisation-summary', {
    ...requestConfig,
    headers: {
      authorization: `Bearer ${token}`
    }
  })

export default billingApi
