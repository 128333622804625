import { SkeletonText, Typography } from '@matillion/component-library'
import classNames from 'classnames'
import { FC } from 'react'
import { Edge, Handle, Position } from 'reactflow'
import classes from './DatasetNode.module.scss'
import { SelectedField } from 'components/ReactFlow/LineageGraph'

export interface FieldProps {
  name: string
  nodeId: string
  'data-testid'?: string
  selectedField?: SelectedField
  setSelectedField?: (field: SelectedField | undefined) => void
  filteredColumnEdges?: Edge[]
  isLoading: boolean
}

const Field: FC<FieldProps> = ({
  name,
  nodeId,
  'data-testid': testId,
  selectedField,
  setSelectedField,
  filteredColumnEdges,
  isLoading
}) => {
  const isSelected =
    selectedField?.fieldId === name && selectedField?.nodeId === nodeId

  const isTarget = filteredColumnEdges?.some((edge) => {
    return (
      edge.targetHandle === name &&
      edge.target === nodeId &&
      selectedField?.nodeId !== nodeId
    )
  })

  const isSource = filteredColumnEdges?.some((edge) => {
    return (
      edge.sourceHandle === name &&
      edge.source === nodeId &&
      selectedField?.nodeId !== nodeId
    )
  })

  if (isLoading) {
    return (
      <div>
        <SkeletonText type="p" className={classes.DatasetNode__FieldSkeleton} />
      </div>
    )
  }

  return (
    <button
      data-testid={testId}
      className={classNames(classes.DatasetNode__Columns__Name, 'nodrag', {
        [classes['DatasetNode__Columns__Name--Selected']]: isSelected,
        [classes['DatasetNode__Columns__Name--IsTarget']]: isTarget,
        [classes['DatasetNode__Columns__Name--IsSource']]: isSource
      })}
      type="button"
      key={name}
      onClick={(e) => {
        e.stopPropagation()
        if (isSelected && setSelectedField) {
          setSelectedField(undefined)
        } else if (setSelectedField) {
          setSelectedField({ fieldId: name, nodeId: nodeId })
        }
      }}
    >
      <Handle
        position={Position.Left}
        className={classNames(
          classes.DatasetNode__Columns__Name__Handle,
          classes.DatasetNode__Columns__Name__Handle__Left
        )}
        type={'target'}
        id={name}
      />

      <Typography format="bcs" className="u-truncate-text">
        <span className="u-visually-hidden">
          {isSelected ? 'Deselect column ' : 'Select column '}
        </span>
        {name}
      </Typography>

      <Handle
        position={Position.Right}
        className={classNames(
          classes.DatasetNode__Columns__Name__Handle,
          classes.DatasetNode__Columns__Name__Handle__Right
        )}
        type={'source'}
        id={name}
      />
    </button>
  )
}

export default Field
