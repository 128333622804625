import { useContext, useMemo } from 'react'

import { AuthHelpersContext, AuthStateContext } from '../context'
import { AuthHelpers, AuthState } from '../types'

/**
 * Returns the current user state, and a handy bag of helpers for maintaining it.
 *
 * useAuth can only be used beneath the [[AuthProvider]].
 *
 * @returns The AuthProvider bag, containing the [[AuthState | current auth state]]
 *          and [[AuthHelpers | Auth0 helper methods]].
 * @category Hooks
 */
const useAuth = (): AuthState & AuthHelpers => {
  const helpers = useContext(AuthHelpersContext)
  const state = useContext(AuthStateContext)

  return useMemo(() => ({ ...helpers, ...state }), [helpers, state])
}

export default useAuth
