import { FunctionComponent, PropsWithChildren } from 'react'
import {
  DataGrid,
  DataGridColumnProps,
  Typography
} from '@matillion/component-library'
import { Jobs } from 'types/jobs'
import JobStatus from 'components/JobStatus'
import JobLink from 'components/JobLink'
import formatTimeDuration from 'utils/formatTimeDuration'
import classnames from 'classnames'
import JobDataGridTheme from './JobDataGridTheme.module.scss'
import { Timestamp } from '../Timestamp'

const DataGridTypography: FunctionComponent<
  PropsWithChildren<{
    testId: string
  }>
> = ({ children, testId }) => {
  return (
    <Typography format="bcs" data-testid={`${testId}`}>
      {children}
    </Typography>
  )
}

const dataGridColumns: Array<DataGridColumnProps<Jobs>> = [
  {
    key: 'startedAt',
    title: 'Started at',
    mapValues: (v) => {
      const testId = 'startedAt'

      if (!v.startedAt) {
        return {
          children: '-',
          testId
        }
      }

      return {
        children: <Timestamp timestamp={v.startedAt} format="friendly" />,
        testId
      }
    },
    as: DataGridTypography
  },
  {
    key: 'name',
    title: 'Job name',
    mapValues: ({ type, name, url }) => ({ type, name, url }),
    as: JobLink
  },
  {
    key: 'duration',
    title: 'Duration',
    mapValues: ({ duration }) => {
      const testId = 'duration'
      if (!duration) {
        return {
          children: '-',
          testId
        }
      }

      const formatDuration =
        duration < 1000 ? ` ${duration}ms` : `${formatTimeDuration(duration)}`
      return {
        children: formatDuration,
        testId
      }
    },
    as: DataGridTypography
  },
  {
    key: 'status',
    title: 'Status',
    mapValues: ({ status }) => ({ status }),
    as: JobStatus
  }
]

interface JobProps {
  jobs?: Jobs[]
}

const JobDataGrid: FunctionComponent<JobProps> = ({ jobs }) => {
  if (!jobs?.length) return null

  return (
    <DataGrid
      columns={dataGridColumns}
      rows={jobs}
      className={classnames([
        JobDataGridTheme.ODStyle,
        JobDataGridTheme.StickyHeader
      ])}
      rowClassName={JobDataGridTheme.ODStyle__Row + ' job-row'}
      data-testid="jobs-table"
      data-tracker="jobs-table"
    />
  )
}

export default JobDataGrid
