import React, { FunctionComponent } from 'react'

import useEnvironment from '../../../effects/useEnvironment'
import MatillionApplicationLink from '../../MatillionApplicationLink'

const HubHomeLink: FunctionComponent<React.PropsWithChildren> = ({
  children,
  ...otherProps
}) => {
  const { services } = useEnvironment()

  return (
    <MatillionApplicationLink
      href={services.homeUrl}
      aria-label="Hub"
      {...otherProps}
    >
      {children}
    </MatillionApplicationLink>
  )
}

export default HubHomeLink
