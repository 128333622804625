import { Auth0ContextInterface, RedirectLoginOptions } from '@auth0/auth0-react'
import { RegionCode } from './api/hub'

declare global {
  interface Window {
    AuthProvider: {
      version?: string
      mockProfile?: Partial<UserInfo>
      mockAuthState?: Partial<AuthState>
    }
  }
}

export interface Environment {
  /**
   * The current environment.
   */
  environment: AuthProviderEnvironment

  /**
   * The URL of the Hub for the current environment.
   */
  hubUrl: string

  /**
   * The URL of the Hub API for the current environment.
   */
  hubApiUrl: string

  /**
   * The URL of the Billing Entitlements API for the current environment.
   */
  entitlementsApiUrl: string

  /**
   * The URL of the Billing API for the current environment.
   */
  billingApiUrl: string

  /**
   * The URL of the Hub Assets CDN for the current environment.
   */
  hubAssetsUrl: string

  /**
   * The URL of the Service Registry API for the current environment.
   */
  registryUrl: string

  /**
   * The URL of the Permissions API for the current environment.
   */
  permissionsApiUrl: string

  /**
   * The current root origin your application is being
   * accessed through.
   */
  appUrl: string

  /**
   * The current organisation subdomain your application
   * is being accessed through.
   */
  subdomain?: string

  /**
   * A list of URLs to other Matillion applications, used
   * for global navigation.
   */
  services: {
    homeUrl: string
  }

  /**
   * Whether or not the AuthProvider is currently running
   * in offline mode. Under offline mode, all services
   * will be mocked out by default.
   */
  offline: boolean
}

export interface AuthHelpers {
  /**
   * Redirects the user to Auth0's login page.
   *
   * @param options The auth0 login options for this request.
   *                `options.state` should be a Location-shaped object describing the
   *                the state to return to after authenticating with Auth0.
   *                The default state is the current `window.location.pathname` and `window.location.search`.
   *                If not overridden in your state, the current `window.location.origin` will be used.
   *                See https://auth0.github.io/auth0-react/interfaces/auth0_context.redirectloginoptions.html
   *                (`options.appState` is not currently supported.)
   */
  login: (
    options?: RedirectLoginOptions & {
      state?: {
        pathname?: string
        search?: string
        hash?: string
      }
    }
  ) => Promise<void>

  /**
   * Clears login session, and redirects the user to be logged out from Auth0.
   *
   * @param options The auth0 logout options for this request.
   *                `options.returnTo` will be set to `window.location.origin` by default.
   *                See https://auth0.github.io/auth0-react/interfaces/index.logoutoptions.html
   */
  logout: Auth0ContextInterface['logout']

  /**
   * Gets the user's access token from Auth0. If a valid token exists
   * in memory, this will return it. Otherwise, a request to Auth0 for
   * a new token will be made in a hidden iframe.
   *
   * @param options The auth0 options for this token request.
   *                See https://auth0.github.io/auth0-react/interfaces/index.gettokensilentlyoptions.html
   */
  getToken: Auth0ContextInterface['getAccessTokenSilently']
}

export interface AuthState {
  /**
   * This will be `true` until the user's profile has finished loading.
   * This covers loading the user's login information from Auth0,
   * and also their Matillion organisation from the Hub.
   */
  isLoading: boolean

  /**
   * Whether or not the user is currently logged in. A logged in user must both
   * be logged in to Auth0, and also be in a Matillion organisation.
   */
  isLoggedIn: boolean
}

/**
 * The details of the currently logged in user, as provided by Auth0.
 */
export interface Auth0User {
  email: string
  name: string
  nickname: string
  picture: string
  updated_at: string
  [key: string]: string | undefined
}

/**
 * A summary of the currently logged in user, with their details
 * formatted in a standardised Matillion way.
 * When displaying a user's information on screen--such as in the
 * User Menu--the Profile should be used first.
 */
export interface Profile {
  /** The email address associated with the user's Auth0 account. */
  email: string
  /**
   * The user's full name (given name + family name) where possible,
   * otherwise their default Auth0 name.
   */
  name: string
  /** The URL of the user's icon. */
  icon: string
  /** The name of the user's current organisation. */
  organisation: string
}

export type { RegionCode } from './api/hub'

/**
 * The details of the logged in user's current organisation.
 */
export interface Organisation {
  id: string
  name: string
  publicId?: string
  subdomain: string
  region?: RegionCode
}

/**
 * A Set containing the roles that this user has been granted.
 */
export type Roles = Set<string>

export interface UserInfo {
  user: Auth0User
  organisation: Organisation
  profile: Profile
  roles: Roles
}

/**
 * An object describing the location of a Matillion application.
 */
export interface ApplicationLocation {
  /**
   * The `window.location.origin` of the current page.
   * For example, if the current URL is `https://myorg.billing.matillion.com/associate/1234`,
   * `origin` will be `https://myorg.billing.matillion.com`.
   */
  origin: string

  /**
   * The organisation subdomain this page is being accessed under.
   * For example, if the current URL is `https://myorg.billing.matillion.com/associate/1234`,
   * `organisation` will be `myorg`.
   */
  organisation?: string

  /**
   * The product slug of the application this page is being accessed under.
   */
  product: string

  /**
   * The region code of the account (organisation) this page is being accessed under.
   */
  region?: string

  /**
   * The root origin of the current page. In a Matillion application,
   * this is considered to be the origin of the app without any subdomain.
   * For example, if the current URL is `https://myorg.billing.matillion.com/associate/1234`,
   * `rootOrigin` will be `https://billing.matillion.com`.
   */
  rootOrigin: string
}

/**
 * An object used to describe a Location that the user should be
 * redirected to after logging in with the Hub.
 */
export interface RedirectToLocation {
  /**
   * The origin of where the user should be redirected to.
   * The origin of a Location is its protocol and host.
   * For example, `https://myorg.billing.matillion.com`
   */
  origin: string

  /**
   * An optional pathname where the user should be redirected to.
   * For example, `/associate/1234`
   */
  pathname?: string

  /**
   * An optional query string to be appended to the redirect URL.
   * For example, `?provider=aws`
   */
  search?: string

  /**
   * An optional hash to be appended to the redirect URL.
   * For example, `#title`
   */
  hash?: string
}

/**
 * The different Auth0 environments available for use.
 * Each of these have different tenants, and will therefore
 * not share user accounts between them.
 */
export enum AuthProviderEnvironment {
  local = 'local',
  dev = 'dev',
  test = 'test',
  preprod = 'preprod',
  prod = 'prod'
}

/**
 * A bag of feature flags, to be returned by `useFlags`.
 * By default, this could contain anything, and will
 * return unhelpful `unknown` values--we really recomend
 * creating an interface describing your known feature
 * flags and passing it to `useFlags` to work with
 * a much nicer type!
 *
 * @typeParam TFlags A type describing your application's
 *                   currently-enabled feature flags.
 */
export type FlagSet<TFlags = undefined> = undefined extends TFlags
  ? Record<string, unknown>
  : TFlags

export interface ServiceRegistry {
  endpoints: {
    [index: string]:
      | {
          endpoint: string
          global: boolean
          region?: string
          type: string
        }
      | undefined
  }
  metadata: {
    env: string
    region: string
    tier: string
  }
}

/**
 * A string identifying a permission for a user.
 * For example, 'view_pipelines' and 'run_pipelines' can be given to DPC users.
 * Please check the permissions API for the names specific to your application.
 */
export type PermissionName = string

/**
 * A resource represents the subject on which the user is trying to perform an action.
 * It is made up of a type and a unique ID which, when paired together, can be used to
 * get the relationship between a resource and a permission.
 *
 * At the time of writng, the type is most commonly "project", with the the ID being
 * a UUID representing a user's project within the DPC.
 */
export interface PermissionResource {
  type: string
  id: string
}

/**
 * The possible values that a permission may have.
 * Successful checks will return a `HAS_PERMISSION` value.
 * These are taken from https://permissions.core-dev.matillion.com/swagger-ui/index.html#/permissions-controller/checkPermission
 */
export enum PermissionValue {
  UNSPECIFIED = 'UNSPECIFIED',
  NO_PERMISSION = 'NO_PERMISSION',
  HAS_PERMISSION = 'HAS_PERMISSION',
  CONDITIONAL_PERMISSION = 'CONDITIONAL_PERMISSION',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

/**
 * A list of the entitlements for a given account, returned from the Billing endpoint.
 */
export interface Entitlements {
  [key: string]: boolean
}

export interface Edition {
  displayName: string
  id: string
}

export interface Trial {
  edition: Edition
  periodStartAt: string
  periodEndAt: string
  creditAllowance: number
  creditsConsumed: number
}

export interface Subscription {
  edition: Edition
  createdAt: string
  id: string
  periodEndAt: string
  periodStartAt: string
}

export interface BillingData {
  trial?: Trial
  subscription: Subscription
  effectiveEdition: {
    displayName: string
    id: string
  }
  id: string
}
