import { MatillionApplicationLink } from '@matillion/hub-client'
import classes from './DropdownMenu.module.scss'
import { FC, useEffect } from 'react'
import { Button, Typography } from '@matillion/component-library'
import { useTranslation } from 'react-i18next'

export interface MenuItemsProps {
  href: string
  isCancelling: boolean
  pipelineId: string
  cancelPipeline: (id: string, force: boolean) => void
  setIsCancelling: (isCancelling: boolean) => void
  setShowForceCancelDialog: (showForceCancelDialog: boolean) => void
}

const MenuItems: FC<MenuItemsProps> = ({
  href,
  isCancelling,
  pipelineId,
  cancelPipeline,
  setIsCancelling,
  setShowForceCancelDialog
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      setIsCancelling(false)
    }
  }, [setIsCancelling])

  return (
    <>
      {href && (
        <MatillionApplicationLink
          aria-label={'View pipeline'}
          href={href}
          target="_blank"
        >
          <Button alt="text" className={classes.DropdownMenu__Button}>
            <Typography format="bcs">
              {t('activity.cancellation.view')}
            </Typography>
          </Button>
        </MatillionApplicationLink>
      )}
      <Button
        alt="text"
        disabled={isCancelling}
        className={classes.DropdownMenu__Button}
        onClick={() => {
          cancelPipeline(pipelineId, false)
          setIsCancelling(true)
        }}
      >
        <Typography format="bcs">
          {t('activity.cancellation.cancel')}
        </Typography>
      </Button>
      <Button
        alt="text"
        className={classes.DropdownMenu__Button}
        onClick={() => setShowForceCancelDialog(true)}
      >
        <Typography format="bcs">
          {t('activity.cancellation.forceCancel')}
        </Typography>
      </Button>
    </>
  )
}

export default MenuItems
