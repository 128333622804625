import { Button, Modal, Typography } from '@matillion/component-library'
import classes from './DropdownMenu.module.scss'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface ForceCancelDialogProps {
  close: () => void
  onConfirm: () => void
}

const ForceCancelDialog: FC<ForceCancelDialogProps> = ({
  close,
  onConfirm
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      size="mid"
      onCancel={() => {
        close()
      }}
      ariaLabelledBy="force-cancel-title"
    >
      <div className={classes.CancelDialog}>
        <Typography
          className={classes.CancelDialog__Title}
          as="h2"
          format="tl"
          id="force-cancel-title"
        >
          {t('activity.cancellation.forceCancel')}
        </Typography>
        <p>{t('activity.cancellation.forceCancelExplainer')}</p>
        <div className={classes.CancelDialog__Actions}>
          <Button
            alt="secondary"
            text={t('activity.cancellation.forceCancelAbort')}
            onClick={() => {
              close()
            }}
          />
          <Button
            alt="danger"
            text={t('activity.cancellation.forceCancelConfirm')}
            onClick={() => {
              close()
              onConfirm()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ForceCancelDialog
