import { FunctionComponent } from 'react'

export const TransformationIcon: FunctionComponent = () => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.79289 0H12V9H11V1H6V6H1V15H4V16H0V4.79289L4.79289 0ZM1.20711 5H5V1.20711L1.20711 5ZM15 13C14.4477 13 14 12.5523 14 12C14 11.4477 14.4477 11 15 11C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13ZM13 12C13 10.8954 13.8954 10 15 10C16.1046 10 17 10.8954 17 12C17 13.1046 16.1046 14 15 14C13.8954 14 13 13.1046 13 12ZM6 16H8V18H6V16ZM5 19V15H6.5V13.5C6.5 12.3954 7.39543 11.5 8.5 11.5H10.7929L9.64645 10.3536L10.3536 9.64645L12.7071 12L10.3536 14.3536L9.64645 13.6465L10.7929 12.5H8.5C7.94772 12.5 7.5 12.9477 7.5 13.5V15H9V19H5Z"
        fill="#2D8825"
      />
    </svg>
  )
}
