import { FunctionComponent } from 'react'
import { Typography } from '@matillion/component-library'
import classnames from 'classnames'
import classes from './MessageBanner.module.scss'

export interface MessageBannerProps {
  title: string
  description: string
  className?: string
}

const MessageBanner: FunctionComponent<MessageBannerProps> = ({
  title,
  description,
  className
}) => {
  return (
    <div
      role="alert"
      className={classnames([classes.MessageBanner__Container, className])}
    >
      <Typography
        as="h4"
        format="tm"
        className={classes.MessageBanner__Underline}
        data-testid="message-banner-title"
      >
        {title}
      </Typography>
      <Typography
        className={classes.MessageBanner__Description}
        format="bcs"
        data-testid="message-banner-description"
      >
        {description}
      </Typography>
    </div>
  )
}

export default MessageBanner
