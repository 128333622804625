import { addMinutes, addHours, addDays, format } from 'date-fns'
import { TimePeriod } from '../context/FilterContext'

const formatDate = (date: Date) => format(date, "yyyy-MM-dd'T'HH:mm:ssXXX")

const getPastTime = (item?: TimePeriod) => {
  if (!item) return

  const now = new Date()

  switch (item) {
    case '15m':
      return formatDate(addMinutes(now, -15))
    case '1h':
      return formatDate(addHours(now, -1))
    case '4h':
      return formatDate(addHours(now, -4))
    case '24h':
      return formatDate(addHours(now, -24))
    case '3d':
      return formatDate(addDays(now, -3))
    case '5d':
      return formatDate(addDays(now, -5))
    case '7d':
      return formatDate(addDays(now, -7))
    case '30d':
      return formatDate(addDays(now, -30))
    default:
  }
}

export default getPastTime
