const Minus = ({ ...props }) => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M 7.5 14 L 22.5 14 L 22.5 16 L 7.5 16 L 7.5 14 Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Minus
