import { Panel } from 'reactflow'
import { FC } from 'react'
import classes from './InformationPanel.module.scss'
import { MicroCta } from '@matillion/component-library'
import Close from 'svg/Close'
import { FlowNode, NodeData } from '../LineageGraph'
import PipelineDetails from './PipelineDetails/PipelineDetails'
import { DatasetData, PipelineData } from 'types/dgs'
import DatasetDetails from './DatasetDetails/DatasetDetails'

export interface InformationPanelProps {
  node: FlowNode
  onClose: () => void
}

const InformationPanel: FC<InformationPanelProps> = ({ node, onClose }) => {
  return (
    <Panel
      position="top-right"
      className={classes.InformationPanel}
      data-testid="information-panel"
    >
      <MicroCta
        aria-label={'Close'}
        onClick={() => onClose()}
        className={classes.InformationPanel__Close}
        size="sm"
        alt="secondary"
      >
        <Close />
      </MicroCta>
      {node.type === 'PIPELINE' && (
        <PipelineDetails nodeData={node.data as NodeData<PipelineData>} />
      )}
      {node.type === 'DATASET' && (
        <DatasetDetails nodeData={node.data as NodeData<DatasetData>} />
      )}
    </Panel>
  )
}

export default InformationPanel
