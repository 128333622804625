const HorizontalLine = () => {
  return (
    <line
      x1="0"
      y1="50%"
      x2="100%"
      y2="50%"
      stroke="currentColor"
      strokeWidth="1"
    />
  )
}

export default HorizontalLine
