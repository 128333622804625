import { FC, PropsWithChildren } from 'react'
import { Typography } from '@matillion/component-library'
import classes from './ResultsMessage.module.scss'

export const ResultsMessage: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={classes.ResultsMessage}>
      <Typography type="bcl">{children}</Typography>
    </div>
  )
}
