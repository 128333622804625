import { Dropdown } from '@matillion/component-library'
import classes from './TimeFrameDropdown.module.scss'
import { TimePeriod, useFilters } from 'context/FilterContext'
import classNames from 'classnames'

interface TimePeriodOption {
  id: TimePeriod
  name: string
}

const dropdownItems: TimePeriodOption[] = [
  { name: 'Last 15 minutes', id: '15m' },
  { name: 'Last 1 hour', id: '1h' },
  { name: 'Last 4 hours', id: '4h' },
  { name: 'Last 24 hours', id: '24h' },
  { name: 'Last 3 days', id: '3d' },
  { name: 'Last 5 days', id: '5d' },
  { name: 'Last 7 days', id: '7d' },
  { name: 'Last 30 days', id: '30d' },
  { name: 'Full history', id: '*' },
  { name: 'Custom range', id: 'custom' }
]

const TimeFrameDropdown = ({ className }: { className?: string }) => {
  const { updateFilters, filters } = useFilters()
  return (
    <Dropdown
      className={classNames(classes.Dropdown, className)}
      value={filters.timeFrame}
      items={dropdownItems}
      handleSelectedItem={(item) => {
        updateFilters({
          timeFrame: (item?.id ?? '*') as TimePeriod,
          startedFrom: undefined,
          startedTo: undefined
        })
      }}
      colorTheme="white"
    />
  )
}

export default TimeFrameDropdown
