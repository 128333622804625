import { Tooltip, Typography } from '@matillion/component-library'
import classNames from 'classnames'
import DATASET_TYPE_LOOKUP from 'modules/Lineage/DatasetList/DatasetTypeLookup'
import TypeIcon from 'modules/Lineage/DatasetList/TypeIcon'
import { FC } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import { InaccessibleDatasetData } from 'types/dgs'
import classes from './InaccessibleDatasetNode.module.scss'
import { NodeData } from 'components/ReactFlow/LineageGraph'

export type CustomInaccessibleDatasetNodeProps = NodeProps<
  NodeData<InaccessibleDatasetData>
>

const CustomInaccessibleDatasetNode: FC<CustomInaccessibleDatasetNodeProps> = ({
  id,
  data
}) => {
  const { content } = data

  return (
    <div
      className={classes.InaccessibleDatasetNode}
      data-testid={`inaccessible-dataset-node-${id}`}
    >
      <Handle
        type="target"
        position={Position.Left}
        className={classes.InaccessibleDatasetNode__Handle}
      />
      <div className={classes.InaccessibleDatasetNode__NodeHeader}>
        <TypeIcon
          iconName={DATASET_TYPE_LOOKUP[content.type]}
          width="32"
          height="32"
          className={classes.InaccessibleDatasetNode__TypeIcon}
        />
        <Tooltip
          content={[...content.path, content.name].join(' / ')}
          position="top"
        >
          <div
            className={classNames(
              classes.InaccessibleDatasetNode__Headers,
              'u-truncate-text'
            )}
          >
            <Typography className="u-truncate-text" format="bcm">
              {content.name}
            </Typography>
            <Typography
              className={classNames(
                classes.InaccessibleDatasetNode__NodeHeader__Subtitle,
                'u-truncate-text'
              )}
              format="bcs"
            >
              {content.namespace}
            </Typography>
          </div>
        </Tooltip>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        className={classes.InaccessibleDatasetNode__Handle}
      />
    </div>
  )
}

export default CustomInaccessibleDatasetNode
