import { useFilters } from 'context/FilterContext'
import { Section } from '@matillion/component-library'
import CustomDateRange from '../CustomDateRange'

const Filters = () => {
  const { filters } = useFilters()
  return (
    <>
      {filters.timeFrame === 'custom' && (
        <Section size={12}>
          <CustomDateRange />
        </Section>
      )}
    </>
  )
}

export default Filters
