import { useLDClient as useClient } from 'launchdarkly-react-client-sdk'

/**
 * Returns an object containing the track method
 *
 *  useLDClient can only be used beneath the [[FlagProvider]].
 *
 * @returns An object containing the track method to track page events for A/B tests.
 * @category Hooks
 */
type LDClient = ReturnType<typeof useClient>

const useLDClient = (client: LDClient = useClient()) => {
  const track = (key: string) => {
    if (!client) {
      console.error('LaunchDarkly client not available')
      return
    }

    try {
      client.track(key)
    } catch (error) {
      console.error('Error tracking event in LaunchDarkly:', error)
    }
  }

  const flush = async () => {
    if (!client) {
      console.error('LaunchDarkly client not available')
      return
    }
    try {
      await client.flush()
    } catch (error) {
      console.error('Error flushing events in LaunchDarkly:', error)
    }
  }

  return { track, flush }
}

export default useLDClient
