import { Row, Cell, THead, Table, TBody } from 'components/DataTable'
import { SearchBar, Typography } from '@matillion/component-library'
import { FC, useState } from 'react'
import classes from './Columns.module.scss'
import highlightText from 'utils/highlightText'
import { SourcedField } from 'types/dgs'

export interface ColumnsProps {
  fields: SourcedField[]
}

const Columns: FC<ColumnsProps> = ({ fields }) => {
  const [search, setSearch] = useState('')

  const filteredFields = fields?.filter((field) =>
    field.name.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <div>
      <SearchBar
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        className={classes.Columns__Search}
        placeholder="Search column name"
        crossOrigin={''}
      />
      <Table className={classes.Columns__Table}>
        <THead>
          <Row>
            <Cell className={classes.Columns__Table__Header} semantics="head">
              Column name
            </Cell>
            <Cell className={classes.Columns__Table__Header} semantics="head">
              Type
            </Cell>
          </Row>
        </THead>
        <TBody>
          {filteredFields.map((field) => (
            <Row key={field.name}>
              <Cell className={classes.Columns__Table__Cell}>
                <Typography format="bcs">
                  {highlightText(field.name, search)}
                </Typography>
              </Cell>
              <Cell className={classes.Columns__Table__Cell}>
                <Typography format="bcs">{field.type}</Typography>
              </Cell>
            </Row>
          ))}
        </TBody>
      </Table>
    </div>
  )
}

export default Columns
