import classNames from 'classnames'
import { HTMLProps, Ref, forwardRef } from 'react'
import classes from './DataTable.module.scss'

export interface Props extends HTMLProps<HTMLTableSectionElement> {
  ref?: Ref<HTMLTableSectionElement>
}

export const DataTableBody = forwardRef<HTMLTableSectionElement, Props>(
  ({ className, ...props }, ref) => {
    return (
      <tbody
        {...props}
        ref={ref}
        className={classNames(classes.DataTableBody, className)}
      />
    )
  }
)

DataTableBody.displayName = 'DataTableBody'
