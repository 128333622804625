import { useEffect } from 'react'
import { Loader } from '@matillion/component-library'
import { useAuth } from '@matillion/hub-client'

const Login = () => {
  const { login } = useAuth()

  useEffect(() => {
    login()
  }, [login])

  return <Loader />
}

export default Login
