import { intervalToDuration, formatDuration } from 'date-fns'

const formatTimeDuration = (duration: number): string => {
  const formatShortTemplate: { [key: string]: string } = {
    xSeconds: '{{count}}s',
    xMinutes: '{{count}}m',
    xHours: '{{count}}h'
  }

  const durationWithInterval = intervalToDuration({
    start: 0,
    end: duration
  })

  return formatDuration(durationWithInterval, {
    format: ['hours', 'minutes', 'seconds'],
    locale: {
      formatDistance: (token: string, count: number) =>
        formatShortTemplate[token].replace('{{count}}', `${count}`)
    }
  })
}

export default formatTimeDuration
