import { createContext, useContext } from 'react'

const AccountIdContext = createContext<string | undefined>(undefined)

interface Props {
  children: React.ReactNode
  accountId?: string
}

export function AccountIdProvider({ accountId, children }: Props) {
  return (
    <AccountIdContext.Provider value={accountId}>
      {children}
    </AccountIdContext.Provider>
  )
}

export function useAccountId() {
  return useContext(AccountIdContext)
}
