import { Entitlements, useEntitlements } from '@matillion/hub-client'
import { useMemo } from 'react'

export interface METLAccess {
  canAccessMETL: boolean
  isLoading: boolean
}

interface METLAccessRequirements {
  entitlements: Entitlements | undefined
}

const canAccessMETL = ({ entitlements }: METLAccessRequirements): boolean => {
  return entitlements?.canAccessMETL ?? false
}

export const useCanAccessMETL = () => {
  const { data: entitlements } = useEntitlements()

  return useMemo(() => {
    return {
      canAccessMETL: canAccessMETL({
        entitlements
      })
    }
  }, [entitlements])
}
