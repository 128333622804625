import { Cell } from 'components/DataTable'

import { Popover, Typography } from '@matillion/component-library'
import { ActivityFiltersResponse } from 'api/eos'
import classNames from 'classnames'
import { FilterPopoverContent } from 'components/FilterPopoverContent/FilterPopoverContent'
import { FilterPopoverItem } from 'components/FilterPopoverContent/FilterPopoverItem'
import { Filters, useFilters } from 'context/FilterContext'
import { useOpener } from 'hooks/useOpener'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useFilterFields } from '../hooks/useFilterFields'
import classes from './FilterCellHead.module.scss'
import { IconPicker } from './IconPicker'

export type AvailableFilterKeys = keyof ActivityFiltersResponse & keyof Filters

export interface Props<T extends AvailableFilterKeys> {
  children?: React.ReactNode
  /** Filter key that we can use to both adjust the filters used for activity lists and the url */
  filterKey: T
  className?: string
  renderCheckboxContent?: (
    filter: ActivityFiltersResponse[T][number]
  ) => React.ReactNode
}

const withAppliedFilters = <T extends string>(
  availableFilters: T[],
  appliedFilters: T[]
) => {
  return [
    ...availableFilters,
    ...appliedFilters.filter((filter) => !availableFilters.includes(filter))
  ]
}

export const FilterCellHead = <T extends AvailableFilterKeys>({
  children,
  filterKey,
  className,
  renderCheckboxContent = (filter) => filter
}: Props<T>) => {
  type FilterType = ActivityFiltersResponse[T]
  const { isOpen, close, toggleOpen } = useOpener()
  const [showFilterIcon, setShowFilterIcon] = useState(false)
  const [search, setSearch] = useState('')
  const { filters, updateFilters } = useFilters()
  const appliedFilters = useMemo<FilterType>(
    () => filters[filterKey] ?? [],
    [filters, filterKey]
  )
  const {
    filters: availableFilters,
    isError,
    isLoading
  } = useFilterFields({ enabled: isOpen || appliedFilters.length > 0 })

  const availableFiltersWithApplied = useMemo(() => {
    if (!availableFilters || !filterKey) return []
    return withAppliedFilters(availableFilters[filterKey], appliedFilters)
  }, [availableFilters, filterKey, appliedFilters])

  const filteredFilters = useMemo<FilterType>(() => {
    if (!search) return availableFiltersWithApplied

    return availableFiltersWithApplied.filter((filter) =>
      filter.toLowerCase().includes(search.toLowerCase())
    )
  }, [availableFiltersWithApplied, search])

  const onCheckboxChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const val = evt.target.value as FilterType[number]
      const newFilters = { [filterKey]: appliedFilters }

      if (evt.target.checked) {
        newFilters[filterKey] = [...appliedFilters, val]
      } else {
        newFilters[filterKey] = appliedFilters.filter(
          (filter) => filter !== val
        )
      }

      updateFilters(newFilters)
    },
    [updateFilters, appliedFilters, filterKey]
  )

  const onClear = useCallback(() => {
    updateFilters({ [filterKey]: [] })
  }, [updateFilters, filterKey])

  useEffect(() => {
    if (!isOpen) setSearch('')
  }, [isOpen])

  const isActive = useMemo(() => appliedFilters.length > 0, [appliedFilters])

  return (
    <Cell
      onMouseEnter={() => setShowFilterIcon(true)}
      onMouseLeave={() => setShowFilterIcon(false)}
      semantics="head"
      className={classNames(classes.FilterTableHead, className)}
    >
      <Popover
        className={classes.FilterTableHead__Popover}
        isOpen={isOpen}
        position="bottom"
        align="start"
        onClickOutside={close}
        anchor={
          <button
            type="button"
            className={classNames(
              classes.FilterTableHead__Button,
              (showFilterIcon || isOpen) &&
                classes['FilterTableHead__Button--active']
            )}
            onClick={toggleOpen}
          >
            <Typography weight="bold" format="bcs">
              {children}
            </Typography>

            <IconPicker
              isActive={isActive}
              isVisible={isOpen || showFilterIcon}
            />
          </button>
        }
      >
        <div className={classes.FilterTableHead__PopoverContent}>
          <FilterPopoverContent
            data-testid={`filter-popover-content-${filterKey}`}
            onSearchChange={setSearch}
            onClear={onClear}
            isLoading={isLoading}
            isError={isError}
          >
            {filteredFilters.map((filter) => (
              <FilterPopoverItem
                key={filter}
                value={filter}
                label={renderCheckboxContent(filter)}
                checked={appliedFilters?.includes(filter)}
                onChange={onCheckboxChange}
              />
            ))}
          </FilterPopoverContent>
        </div>
      </Popover>
    </Cell>
  )
}
