import { Button, SearchBar } from '@matillion/component-library'
import { CenteredSpinner } from 'components/CenteredSpinner'
import ErrorMessage from 'components/ErrorMessage'
import { FormEvent, useCallback } from 'react'
import classes from './FilterPopoverContent.module.scss'

export interface Props {
  onSearchChange?: (value: string) => unknown
  onClear?: () => unknown
  children?: React.ReactNode
  isLoading?: boolean
  isError?: boolean
  ['data-testid']?: string
}

const preventDefault = (evt: Event | FormEvent) => evt.preventDefault()

export const FilterPopoverContent = ({
  onClear,
  children,
  onSearchChange,
  isLoading,
  isError,
  'data-testid': dataTestId
}: Props) => (
  <form
    className={classes.FilterPopoverContent}
    onSubmit={preventDefault}
    data-testid={dataTestId}
  >
    <div className={classes.FilterPopoverContent__Stack}>
      <div>
        <SearchBar
          placeholder="Search"
          onChange={useCallback(
            ({ target }) => onSearchChange?.(target.value),
            [onSearchChange]
          )}
          crossOrigin={''}
        />
      </div>
      {isError && (
        <ErrorMessage>Something went wrong fetching filter values</ErrorMessage>
      )}
      {isLoading && <CenteredSpinner />}
      {!isLoading && !isError && (
        <ul className={classes.FilterPopoverContent__List}>{children}</ul>
      )}
      <hr className={classes.FilterPopoverContent__HR} />
      <div>
        <Button alt="text" fontWeight="normal" text="Clear" onClick={onClear} />
      </div>
    </div>
  </form>
)
