import { ServiceRegistry } from '../types'

const offlineRegistry: ServiceRegistry = {
  metadata: {
    env: 'prod',
    region: 'us1',
    tier: 'default'
  },
  endpoints: {
    'agent-manager-frontend': {
      endpoint: 'https://agentmanager.matillion.com',
      global: false,
      region: 'us1',
      type: 'frontend'
    },
    'billing-frontend': {
      endpoint: 'https://billing.matillion.com',
      global: true,
      region: undefined,
      type: 'frontend'
    },
    'cyoc-frontend': {
      endpoint: 'https://create-connector.matillion.com',
      global: true,
      region: undefined,
      type: 'frontend'
    },
    'designer-frontend': {
      endpoint: 'https://designer.matillion.com',
      global: false,
      region: 'us1',
      type: 'frontend'
    },
    'hub-frontend': {
      endpoint: 'https://hub.matillion.com',
      global: true,
      region: undefined,
      type: 'frontend'
    },
    'mdl-frontend': {
      endpoint: 'https://dataloader.matillion.com',
      global: true,
      region: undefined,
      type: 'frontend'
    },
    'observability-dashboard-frontend': {
      endpoint: 'https://observability-dashboard.us1.matillion.com',
      global: false,
      region: 'us1',
      type: 'frontend'
    },
    'project-explorer-frontend': {
      endpoint: 'https://project-explorer.matillion.com',
      global: false,
      region: 'us1',
      type: 'frontend'
    },
    'scheduler-frontend': {
      endpoint: 'https://scheduler.matillion.com',
      global: false,
      region: 'us1',
      type: 'frontend'
    },
    'start-frontend': {
      endpoint: 'https://start.matillion.com',
      global: true,
      region: undefined,
      type: 'frontend'
    }
  }
}
export default offlineRegistry
