import axios, { AxiosRequestConfig } from 'axios'

const entitlementsApi = axios.create({
  headers: { 'Content-Type': 'application/json' }
})

interface EntitlementsServiceResponse {
  [key: string]: boolean
}

export const getEntitlements = async (
  token: string,
  accountId: string,
  requestConfig?: AxiosRequestConfig
) =>
  entitlementsApi.get<EntitlementsServiceResponse>('/v1/entitlements', {
    ...requestConfig,
    headers: {
      authorization: `Bearer ${token}`,
      'Account-Id': accountId
    }
  })

export default entitlementsApi
