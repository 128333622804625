import { DateInput, Typography } from '@matillion/component-library'
import { FC } from 'react'
import classes from './CustomDateInput.module.scss'
import classNames from 'classnames'

interface CustomDateInputProps {
  heading?: string
  subheading?: string
  className?: string
  value?: string
  error?: boolean
  maxDate?: Date
  minDate?: Date
  open?: boolean
  onFocus?: () => void
  onCalendarClose?: () => void
  onChange?: (date?: string) => void
}

const CustomDateInput: FC<CustomDateInputProps> = ({
  heading,
  subheading,
  className,
  value,
  error,
  maxDate,
  minDate,
  open,
  onFocus,
  onCalendarClose,
  onChange
}) => {
  return (
    <div className={classNames(classes.CustomDateInput, className)}>
      <div className={classes.CustomDateInput__Headings}>
        <Typography
          format="bcm"
          className={classes.CustomDateInput__Headings__MainHeading}
        >
          {heading}
        </Typography>
        <Typography
          format="mc"
          className={classes.CustomDateInput__Headings__SubHeading}
        >
          {subheading}
        </Typography>
      </div>
      <div className={classes.CustomDateInput__Input}>
        <DateInput
          value={value}
          onChange={onChange}
          error={error}
          placeholder="yyyy-mm-dd"
          maxDate={maxDate}
          minDate={minDate}
          open={open}
          onFocus={onFocus}
          onCalendarClose={onCalendarClose}
          onClickOutside={onCalendarClose}
        />
      </div>
    </div>
  )
}

export default CustomDateInput
