import React, { FunctionComponent } from 'react'

import useMatillionApplicationURL from '../../effects/useMatillionApplicationURL'

export interface MatillionApplicationLinkProps {
  /**
   * The href of this link. This is assumed to be a Matillion
   * application link, with the following taxonomy:
   *
   * ```
   * https://<account>.<application>.matillion.com
   * ```
   *
   * Providing a non-Matillion application link here may result
   * in unexpected behaviour.
   */
  href: string

  [key: string]: unknown
}

/**
 * Renders a link to the provided Matillion application. If a current
 * organisation subdomain is available, it will be prepended to the
 * href, to make a link to the organisation's instance of the
 * application.
 *
 * This component assumes the provided href is a Matillion application
 * link, which follows the following taxonomy:
 *
 * ```
 * https://<account>.<application>.matillion.com
 * ```
 *
 * @param props See [[MatillionApplicationLinkProps]].
 * @category Components
 */
const MatillionApplicationLink: FunctionComponent<
  React.PropsWithChildren<MatillionApplicationLinkProps>
> = ({ children, href, ...otherProps }) => {
  const { url } = useMatillionApplicationURL(href)

  return (
    <a href={url.toString()} {...otherProps}>
      {children}
    </a>
  )
}

export default MatillionApplicationLink
