import { FC } from 'react'

export interface CloseProps {
  className?: string
}

const Close: FC<CloseProps> = ({ className }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_700_10683)">
        <path
          d="M0.25 0.249496L11.75 11.7495"
          stroke="#222222"
          strokeWidth="0.666667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.75 0.249496L0.25 11.7495"
          stroke="#222222"
          strokeWidth="0.666667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_700_10683">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Close
