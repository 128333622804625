import { HTMLProps, Ref, forwardRef } from 'react'

import classNames from 'classnames'
import classes from './DataTable.module.scss'

export interface Props extends HTMLProps<HTMLTableRowElement> {
  ref?: Ref<HTMLTableRowElement>
}

export const DataTableRow = forwardRef<HTMLTableRowElement, Props>(
  ({ className, ...props }, ref) => {
    return (
      <tr
        {...props}
        ref={ref}
        className={classNames(classes.DataTableRow, className)}
      />
    )
  }
)

DataTableRow.displayName = 'DataTableRow'
