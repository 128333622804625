import { Button } from '@matillion/component-library'
import { FunctionComponent } from 'react'
import './HubLogout.scss'

import useAuth from '../../effects/useAuth'

const HubLogoutButton: FunctionComponent = () => {
  const { logout } = useAuth()

  const onLogout = () => {
    logout({ returnTo: window.location.origin })
  }

  return (
    <Button
      data-testid="hub-logout-button"
      as="span"
      alt="primary"
      fontWeight="bold"
      onClick={onLogout}
      className="HubLogout"
      text={'Logout'}
    />
  )
}

export default HubLogoutButton
