export function parseHost(host: string): {
  tld: string
  matillion: string
  region?: string
  product: string
  subdomain?: string
} {
  const parts = host.split('.').reverse()
  if (parts[2]?.match(/^[a-z]{2}\d$/)) {
    const [tld, matillion, region, product, subdomain] = parts
    return {
      tld,
      matillion,
      region,
      product,
      subdomain
    }
  }
  const [tld, matillion, product, subdomain] = parts
  return {
    tld,
    matillion,
    product,
    subdomain
  }
}
