import { Typography } from '@matillion/component-library'
import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { safeString } from 'utils/safeString'

import classNames from 'classnames'
import { useFilters } from '../../context/FilterContext'
import classes from './DetailLink.module.scss'

interface Props {
  text: ReactNode
  pipelineId?: string
  className?: string
}

const DetailLink: FC<Props> = ({ text, pipelineId, className }) => {
  const { encoded } = useFilters()
  return (
    <Link to={`/pipeline/${safeString(pipelineId)}?filters=${encoded}`}>
      <Typography
        format={'bcm'}
        weight={'regular'}
        className={classNames(classes.DetailLink, className)}
      >
        {text}
      </Typography>
    </Link>
  )
}

export default DetailLink
