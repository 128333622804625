import { CellTypography } from 'components/CellTypography'
import { DateTime } from 'components/DateTime'
import { Cell, Row } from 'components/DataTable'
import { calcDuration } from 'modules/Activity/RunHistoryDataGrid/hooks/calcDuration'
import { FC, useState } from 'react'
import { NestingAwareStep, PipelineStatus } from 'types/eos'
import { safeString } from 'utils/safeString'
import CellMessage from './CellMessage'
import NestedPipelineSteps from './NestedPipelineSteps'
import PipelineNameCell from './PipelineNameCell'
import classes from './StepRow.module.scss'
import classNames from 'classnames'
import Guidelines from './Guidelines/Guidelines'
import formatNumber from 'utils/formatNumber'

interface StepRowProps {
  step: NestingAwareStep
  depth: number
  pipelineName: string
  rowClassName?: string
  cellClassName?: string
  nestedFilterStatus?: PipelineStatus
  isRoot: boolean
  isLast: boolean
  treeLines: boolean[]
}

const StepRow: FC<StepRowProps> = ({
  step,
  depth,
  pipelineName,
  rowClassName,
  cellClassName,
  nestedFilterStatus,
  isRoot,
  isLast,
  treeLines
}) => {
  const {
    result: { startedAt, finishedAt, rowCount, message, status },
    nestedPipeline,
    nestedSteps,
    name
  } = step
  const hasNestedSteps = nestedSteps.length > 0
  const [isExpanded, setIsExpanded] = useState(hasNestedSteps)

  return (
    <>
      <Row className={classNames(rowClassName, classes.StepRow)}>
        <Cell className={classNames(cellClassName, classes.StepRow__FirstCell)}>
          <Guidelines
            depth={depth}
            treeLines={treeLines}
            isRoot={isRoot}
            isLast={isLast}
            isExpanded={isExpanded}
          />
          <PipelineNameCell
            depth={depth}
            isExpanded={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            hasNestedStuff={!!nestedPipeline || hasNestedSteps}
            pipelineName={pipelineName}
            status={status}
          />
        </Cell>
        <Cell className={cellClassName}>
          <CellTypography text={name} />
        </Cell>
        <Cell className={cellClassName}>
          <CellTypography text={<DateTime timestamp={startedAt} />} />
        </Cell>
        <Cell className={cellClassName}>
          <CellTypography
            text={calcDuration(startedAt, safeString(finishedAt))}
            subtext={<DateTime timestamp={finishedAt} />}
          />
        </Cell>
        <Cell className={cellClassName}>
          <CellTypography
            text={typeof rowCount === 'number' ? formatNumber(rowCount) : '-'}
          />
        </Cell>
        <Cell className={cellClassName}>
          <CellMessage text={safeString(message)} component={name} />
        </Cell>
      </Row>

      {isExpanded && nestedPipeline && (
        <NestedPipelineSteps
          rowClassName={rowClassName}
          nestedPipeline={nestedPipeline}
          parentDepth={depth}
          filterStatus={nestedFilterStatus}
          treeLines={treeLines}
          parentIsLast={isLast}
        />
      )}

      {isExpanded &&
        hasNestedSteps &&
        nestedSteps.map((nestedStep, index) => {
          return (
            <StepRow
              key={nestedStep.id}
              step={nestedStep}
              depth={depth + 1}
              pipelineName={pipelineName}
              isRoot={false}
              isLast={index === nestedSteps.length - 1}
              treeLines={[...treeLines, !isLast]}
              rowClassName={rowClassName}
            />
          )
        })}
    </>
  )
}

export default StepRow
