import { useEffect } from 'react'
import { Loader } from '@matillion/component-library'
import { useAuth, usePendo } from '@matillion/hub-client'

const Logout = () => {
  const { logout } = useAuth()
  const pendo = usePendo()

  useEffect(() => {
    pendo.teardown()
    logout({ returnTo: window.location.origin })
  }, [logout, pendo])

  return <Loader />
}

export default Logout
