import { LDEvaluationReason } from 'launchdarkly-js-sdk-common'
import { heap } from '../utils/heap'
import { useLDClient as useClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'

interface Experiment {
  experimentName: string
  flagKey: string
  variationName: string
}

export interface EvaluationReason extends LDEvaluationReason {
  inExperiment?: boolean
}

export const useSendLDExperimentAnalytics = (experiments: Experiment[]) => {
  const LDClient = useClient()

  const [heapHasRun, setHeapHasRun] = useState(false)

  let experimentPayload = {}

  experiments.forEach((experiment) => {
    const { experimentName, flagKey, variationName } = experiment

    const flagVariationDetail = LDClient?.variationDetail(flagKey)
    const isExperimentEnabled =
      (flagVariationDetail?.reason as EvaluationReason)?.inExperiment ?? false
    const flagVariation = flagVariationDetail?.variationIndex
    const flagValue = flagVariationDetail?.value

    const experimentProperties = {
      [`Experiment: ${experimentName} - Experiment Name`]: experimentName,
      [`Experiment: ${experimentName} - Flag Key`]: flagKey,
      [`Experiment: ${experimentName} - Variation`]: `${variationName} (Var: ${
        flagVariation ?? ''
      } | Val: ${(flagValue as string) ?? ''})`,
      ...(isExperimentEnabled !== undefined && {
        [`Experiment: ${experimentName} - In Experiment`]: isExperimentEnabled
      })
    }

    experimentPayload = { ...experimentPayload, ...experimentProperties }
  })

  useEffect(() => {
    const sendToHeap = () => {
      if (!heapHasRun && heap.getHeapInstance() && LDClient) {
        heap.addEventProperties(experimentPayload)
        setHeapHasRun(true)
      }
    }

    sendToHeap()

    document.addEventListener('heapTagHasRun', sendToHeap)

    return () => {
      document.removeEventListener('heapTagHasRun', sendToHeap)
    }
  }, [LDClient])
}
