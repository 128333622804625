import { useQuery } from '@tanstack/react-query'
import useProjectsClient from './useProjectsClient'
import { getProjects } from '../projects'

export const useGetProjects = () => {
  const client = useProjectsClient()
  return useQuery({
    queryKey: ['projects'],
    queryFn: async () => getProjects(client),
    retry: 1,
    staleTime: 60_000
  })
}
