import { differenceInSeconds, parseISO } from 'date-fns'

export const calcDuration = (startedAt: string, finishedAt: string) => {
  if (!startedAt || !finishedAt) return ''

  const date1 = parseISO(startedAt)
  const date2 = parseISO(finishedAt)

  const totalSecondsDiff = differenceInSeconds(date2, date1)
  if (!totalSecondsDiff) {
    return '<1s'
  }

  const hours = Math.floor(totalSecondsDiff / 3600)
  const minutes = Math.floor((totalSecondsDiff % 3600) / 60)
  const seconds = totalSecondsDiff % 60

  const hoursDisplay = hours > 0 ? `${hours}h ` : ''
  const minutesDisplay = minutes > 0 ? `${minutes}m ` : ''
  const secondsDisplay = seconds > 0 ? `${seconds}s` : ''

  return `~${hoursDisplay}${minutesDisplay}${secondsDisplay}`.trim()
}
