import { DataTable, DataTableBody } from 'components/DataTable'
import { FC } from 'react'
import { Dataset } from 'types/dgs'
import PanelTableRow from './PanelTableRow'
import { capitalize } from 'lodash'

export interface DatasetDetailsTableProps {
  data: Dataset
}

const DatasetDetailsTable: FC<DatasetDetailsTableProps> = ({ data }) => {
  const { path, fields, datasetType } = data
  return (
    <DataTable>
      <DataTableBody>
        <PanelTableRow fieldName="Type">
          {capitalize(datasetType)}
        </PanelTableRow>
        <PanelTableRow fieldName="Database">
          {path[path.length - 2]}
        </PanelTableRow>
        <PanelTableRow fieldName="Schema">
          {path[path.length - 1]}
        </PanelTableRow>
        <PanelTableRow fieldName="Columns">{fields.length}</PanelTableRow>
      </DataTableBody>
    </DataTable>
  )
}

export default DatasetDetailsTable
