import { FunctionComponent, useEffect, useRef, useState } from 'react'

interface InfiniteScrollAnchorProps {
  onIntersect: (isIntersecting: boolean) => void
}

export const InfiniteScrollAnchor: FunctionComponent<
  InfiniteScrollAnchorProps
> = ({ onIntersect }) => {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const node = ref.current
    if (!node) {
      return
    }
    const observer = new IntersectionObserver(
      ([entry]: IntersectionObserverEntry[]) =>
        setIsIntersecting(entry.isIntersecting),
      { threshold: 0 }
    )
    observer.observe(node)
    return () => observer.disconnect()
  }, [ref])

  useEffect(() => {
    if (isIntersecting) {
      onIntersect(isIntersecting)
    }
  }, [isIntersecting, onIntersect])

  return <div ref={ref} />
}
