import { Typography, Section } from '@matillion/component-library'
import AddInstanceBtn from '../AddInstanceBtn'
import classes from './NoInstancesMessage.module.scss'

const NoInstancesMessage = () => {
  return (
    <Section
      size={12}
      subgrid
      className={classes.NoInstancesMessage_Center}
      data-testid="no-instances-view"
    >
      <Section>
        <Typography as="h1" format="tl">
          Matillion ETL Activity
        </Typography>
      </Section>
      <Section>
        <Typography>
          This feature is available for Matillion ETL version 1.65 or above.
        </Typography>
        <Typography>Upgrade to see your job activity here.</Typography>
      </Section>
      <Section>
        <Typography>If you need to add a new instance, do so below.</Typography>
      </Section>
      <Section className={classes.NoInstancesMessage_Center}>
        <AddInstanceBtn />
      </Section>
    </Section>
  )
}

export default NoInstancesMessage
