import { useQuery } from '@tanstack/react-query'
import { DatasetLineageResponse, getDatasetLineage } from 'api/dgs'
import { AxiosError } from 'axios'
import useDgsClient from './useDgsClient'

export const useGetDatasetLineage = (datasetId: string) => {
  const client = useDgsClient()

  return useQuery<DatasetLineageResponse, AxiosError>({
    queryKey: ['dataset-lineage', datasetId],
    queryFn: async () => getDatasetLineage(client, datasetId),
    refetchOnMount: false,
    staleTime: 5 * 60 * 1000
  })
}
