import { Alert, Section, Typography } from '@matillion/component-library'
import ErrorPage from 'components/ErrorPage'
import HeaderSection from 'components/HeaderSection'
import PageLayout from 'components/PageLayout'
import { ErrorBoundary } from 'react-error-boundary'
import DatasetList from './DatasetList/DatasetList'
import classes from './Lineage.module.scss'
import { useTranslation } from 'react-i18next'

const Lineage = () => {
  const { t } = useTranslation()

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <PageLayout>
        <HeaderSection title="Lineage" />
        <Section size={12} className={classes.AlertSection}>
          <Alert
            title={t('lineage.alert.title')}
            message={
              <Typography format="bcs">{t('lineage.alert.message')}</Typography>
            }
            className={classes.Alert}
          />
        </Section>
        <Section size={12}>
          <DatasetList />
        </Section>
      </PageLayout>
    </ErrorBoundary>
  )
}

export default Lineage
