import { Icon, Typography } from '@matillion/component-library'
import { Link } from 'react-router-dom'
import classes from './DetailViewHeader.module.scss'

const BackToLineage = () => {
  return (
    <Link to={'/lineage'} className={classes.Back}>
      <Icon.ChevronDown className={classes.Chevron} stroke="#2d8825" />
      <Typography>Back</Typography>
    </Link>
  )
}

export default BackToLineage
