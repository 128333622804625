import { FunctionComponent } from 'react'
import { Button, Grid, Section, Typography } from '@matillion/component-library'
import MessageBanner from 'components/MessageBanner'
import JobDataGrid from 'components/JobDataGrid'
import classes from './JobActivity.module.scss'
import SearchJobs from './SearchJobs'
import { JobActivityProps } from './types'
import useJobActivity from './useJobActivity'
import { InfiniteScrollAnchor } from 'components/InfiniteScrollAnchor'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { RefreshIcon } from 'components/svg'

const JobActivity: FunctionComponent<JobActivityProps> = ({
  installationId,
  instanceId
}) => {
  const {
    isPaginating,
    isRefreshing,
    jobs,
    jobsMessage,
    name,
    searchByName,
    paginate,
    refresh
  } = useJobActivity({
    installationId,
    instanceId
  })

  if (jobsMessage && !jobsMessage.searchMode) {
    return (
      <MessageBanner
        title={jobsMessage.title}
        description={jobsMessage.description}
        className={classes.JobActivity__MessageContainer}
      />
    )
  }

  return (
    <div className={classes.JobActivity}>
      <Grid>
        <Section size={8} className={classes.JobActivity__TitleSection}>
          <Typography
            as="h2"
            format="bcl"
            id={`${instanceId}-job-region-heading`}
          >
            <Typography as="span" format="bcl" weight="bold">
              Job activity
            </Typography>
            <Typography
              as="span"
              format="bcl"
              className={classes.JobActivity__TitleSuffix}
            >
              from previous 24 hours
            </Typography>
          </Typography>
          <Button
            className={classes.JobActivity__Refresh}
            size="sm"
            alt="secondary"
            onClick={refresh}
          >
            <RefreshIcon />
            <Typography as="span" format="bcs">
              Refresh
            </Typography>
          </Button>
        </Section>
        <Section size={4} className={classes.JobActivity__SearchSection}>
          <SearchJobs value={name} onSearch={searchByName} />
        </Section>
        {isRefreshing ? (
          <CenteredSpinner />
        ) : (
          <Section
            size={12}
            className={classes.JobActivity__JobsSection}
            tabIndex="0"
            role="region"
            aria-labelledby={`${instanceId}-job-region-heading`}
            data-testid="jobs-panel"
          >
            {jobsMessage?.searchMode ? (
              <MessageBanner
                {...jobsMessage}
                className={classes.JobActivity__MessageContainer}
              />
            ) : (
              <>
                <JobDataGrid jobs={jobs} />
                <InfiniteScrollAnchor onIntersect={paginate} />
                {isPaginating && <CenteredSpinner size="compact" />}
              </>
            )}
          </Section>
        )}
      </Grid>
    </div>
  )
}

export default JobActivity
