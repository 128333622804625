import { FunctionComponent } from 'react'
import { formatDate, formatDistanceToNow } from 'date-fns'

const FRIENDLY_FORMAT = 'h:mm a (z) MMMM d, yyyy'

interface TimestampProps {
  timestamp?: Date | string
  format: 'friendly' | 'relative'
}

export const Timestamp: FunctionComponent<TimestampProps> = ({
  timestamp,
  format
}) => {
  if (!timestamp) return <>-</>
  try {
    const date: Date =
      timestamp instanceof Date ? timestamp : new Date(timestamp)
    const friendly = formatDate(date, FRIENDLY_FORMAT)
    return (
      <time dateTime={date.toUTCString()} title={friendly}>
        {format === 'friendly' && friendly}
        {format === 'relative' &&
          formatDistanceToNow(date, {
            includeSeconds: true,
            addSuffix: true
          })}
      </time>
    )
  } catch {
    return <>-</>
  }
}
