import { ButtonHTMLAttributes, FC } from 'react'

import { Tooltip } from '@matillion/component-library'
import classnames from 'classnames'

import classes from './ToolbarButton.module.scss'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

export type Props = ButtonProps & {
  label: string
  shortcut?: string
  align?: 'start' | 'center' | 'end'
  autoSize?: boolean
  hideTooltip?: boolean
}

export const IconButton: FC<Props> = ({
  className,
  children,
  label,
  align = 'center',
  autoSize = false,
  ...props
}) => (
  <Tooltip content={label} align={align} className={classes.Tooltip__Container}>
    <button
      type="button"
      aria-label={label}
      className={classnames(
        classes.Tooltip__Button,
        !autoSize && classes.Tooltip__Button__FixedSize,
        className
      )}
      {...props}
    >
      {children}
    </button>
  </Tooltip>
)
