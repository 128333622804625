import classes from './Search.module.scss'

import SearchInput from 'components/SearchInput'
import { useFilters } from '../../context/FilterContext'

const Search = () => {
  const { updateFilters, filters } = useFilters()

  return (
    <div
      className={classes.SearchContainer}
      data-testid="pipeline-filter-search"
    >
      <div className={classes.SearchBarContainer}>
        <SearchInput
          onChange={(e) => updateFilters({ search: e.target.value })}
          value={filters.search}
          placeholder="Search"
          className={classes.SearchBar}
          debounceWait={200}
        />
      </div>
    </div>
  )
}

export default Search
