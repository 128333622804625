import { FC } from 'react'

export interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: string
  iconColor?: string
  borderColor?: string
  width?: string
  height?: string
}

const TransformationPipelineIcon: FC<Props> = ({
  backgroundColor = '#EAEFE5',
  iconColor = '#2D8825',
  borderColor = '#2D8825',
  ...props
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="17"
        height="17"
        rx="3.5"
        fill={backgroundColor}
      />
      <path
        d="M11.95 5.67505H6.04999V6.87505H8.23999V12.325H9.75999V6.87505H11.95V5.67505Z"
        fill={iconColor}
      />
      <rect
        x="0.5"
        y="0.5"
        width="17"
        height="17"
        rx="3.5"
        stroke={borderColor}
      />
    </svg>
  )
}

export default TransformationPipelineIcon
