import { ActivityFilterFields } from 'api/eos'
import { QueryOptions, useActivityFilters } from 'api/hooks/useActivityFilters'
import { useMemo } from 'react'

export const FIELDS: ActivityFilterFields[] = ['STATUS']

export const useFilterFields = (args?: QueryOptions) => {
  return useActivityFilters(
    useMemo(
      () => ({
        fields: FIELDS
      }),
      []
    ),
    args
  )
}
