import { Typography } from '@matillion/component-library'
import { DataTableCell, DataTableRow } from 'components/DataTable'
import classes from './InformationPanelTable.module.scss'
import { FC, PropsWithChildren } from 'react'

export interface PanelTableRowProps {
  fieldName: string
}

const PanelTableRow: FC<PropsWithChildren<PanelTableRowProps>> = ({
  fieldName,
  children
}) => {
  return (
    <DataTableRow className={classes.InformationPanelTable__Row}>
      <DataTableCell className={classes.InformationPanelTable__FieldName}>
        <Typography format="bcs">{fieldName}</Typography>
      </DataTableCell>
      <DataTableCell className={classes.InformationPanelTable__Value}>
        {typeof children === 'string' ? (
          <Typography format="bcs">{children}</Typography>
        ) : (
          children
        )}
      </DataTableCell>
    </DataTableRow>
  )
}

export default PanelTableRow
