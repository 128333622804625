import { FunctionComponent } from 'react'

export const OrchestrationIcon: FunctionComponent = () => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0H5.29289L0 5.29289V16H3V15H1V6H6V1H11V10.5H12V0ZM5 5H1.70711L5 1.70711V5ZM5 11V13H7V11H5ZM4 10V14H8V12.5H10V15C10 15.8284 9.32843 16.5 8.5 16.5H8V15H4V19H8V17.5H8.5C9.88071 17.5 11 16.3807 11 15V12.5H13V14H17V10H13V11.5H8V10H4ZM14 13V12.5V11.5V11H16V13H14ZM7 16V18H5V16H7Z"
        fill="#366FE5"
      />
    </svg>
  )
}
