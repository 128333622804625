import { parseHost } from './parseHost'

export function prependSubdomain(
  href: string,
  subdomain: string | undefined
): URL {
  const url = new URL(href)
  const parsedHost = parseHost(url.hostname)

  const amendedHost = [
    parsedHost.product,
    parsedHost.region,
    parsedHost.matillion,
    parsedHost.tld
  ].filter((part) => !!part)
  if (subdomain) {
    amendedHost.unshift(subdomain)
  }
  url.hostname = amendedHost.join('.')
  return url
}
