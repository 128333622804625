import { Edge, ReactFlowProvider } from 'reactflow'
import LineageGraph, { FlowNode } from './LineageGraph'
import { FC } from 'react'

export interface LineageGraphWrapperProps {
  initialNodes?: FlowNode[]
  initialEdges?: Edge[]
  initialDatasetId?: string
}

const LineageGraphWrapper: FC<LineageGraphWrapperProps> = ({
  initialNodes,
  initialEdges,
  initialDatasetId
}) => {
  return (
    <ReactFlowProvider>
      <LineageGraph
        initialNodes={initialNodes}
        initialEdges={initialEdges}
        initialDatasetId={initialDatasetId}
      />
    </ReactFlowProvider>
  )
}

export default LineageGraphWrapper
