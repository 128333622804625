import { Checkbox, CheckboxProps } from '@matillion/component-library'
import classNames from 'classnames'
import { ReactNode } from 'react'
import classes from './FilterPopoverContent.module.scss'

export interface Props extends CheckboxProps {
  label?: ReactNode
}

export const FilterPopoverItem = ({ className, label, ...props }: Props) => (
  <li className={classes.FilterPopoverItem}>
    <label className={classes.FilterPopoverItem__Label}>
      <Checkbox
        {...props}
        className={classNames(classes.FilterPopoverItem__Checkbox, className)}
      />
      <span className={classes.FilterPopoverItem__Label__Text}>{label}</span>
    </label>
  </li>
)
