import { useGetStepsInfinite } from 'api/hooks/useGetSteps'
import { CenteredSpinner } from 'components/CenteredSpinner'
import ErrorMessage from 'components/ErrorMessage'
import { InfiniteScrollAnchor } from 'components/InfiniteScrollAnchor'
import { Cell, Row } from 'components/DataTable'
import { FC } from 'react'
import { NestedPipeline, PipelineStatus } from 'types/eos'
import { safeString } from 'utils/safeString'
import classes from './NestedPipelineSteps.module.scss'
import StepRow from './StepRow'

interface Props {
  nestedPipeline: NestedPipeline
  parentDepth: number
  treeLines: boolean[]
  rowClassName?: string
  filterStatus?: PipelineStatus
  parentIsLast: boolean
}

const NestedPipelineSteps: FC<Props> = ({
  nestedPipeline,
  parentDepth,
  rowClassName,
  filterStatus,
  treeLines,
  parentIsLast
}) => {
  const { steps, isFetching, hasMore, isError, fetchNextPage } =
    useGetStepsInfinite(safeString(nestedPipeline.id), filterStatus)

  const moreAnchor = hasMore && !isFetching

  return (
    <>
      {steps?.map((childStep, index) => {
        // Builds a list of the parent tree lines for the child to draw
        const newTreeLines = treeLines ? [...treeLines, !parentIsLast] : []
        return (
          <StepRow
            rowClassName={rowClassName}
            key={childStep.id}
            step={childStep}
            depth={parentDepth + 1}
            pipelineName={safeString(nestedPipeline?.name)}
            nestedFilterStatus={filterStatus}
            isRoot={false}
            isLast={index === steps.length - 1}
            treeLines={newTreeLines}
          />
        )
      })}
      {(moreAnchor || isFetching || isError) && (
        <Row>
          <Cell fullWidth>
            {isFetching && <CenteredSpinner />}
            {moreAnchor && <InfiniteScrollAnchor onIntersect={fetchNextPage} />}
            {isError && (
              <ErrorMessage
                className={classes.NestedPipelineSteps__ErrorMessage}
              >
                Something went wrong when attempting to load the pipeline.
              </ErrorMessage>
            )}
          </Cell>
        </Row>
      )}
    </>
  )
}

export default NestedPipelineSteps
