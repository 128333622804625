import { FunctionComponent, PropsWithChildren } from 'react'
import classes from './EditableTextSection.module.scss'

const EditableTextSection: FunctionComponent<PropsWithChildren> = ({
  children
}) => (
  <div
    data-testid="instance-metadata-description"
    className={classes.EditableTextSection__Container}
  >
    {children}
  </div>
)

export default EditableTextSection
