import { differenceInCalendarDays, isAfter } from 'date-fns'
import { BillingData, useAuth } from '..'
import useUser from './useUser'
import useEnvironment from './useEnvironment'
import { useEffect, useState } from 'react'
import { AxiosRequestConfig } from 'axios'
import getConfig from '../config'
import { getBillingData } from '../api/billing'

const useBillingData = () => {
  const { getToken } = useAuth()
  const { organisation } = useUser()
  const { environment, offline } = useEnvironment()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()
  const [data, setData] = useState<BillingData>()

  const BILLING_API = getConfig(environment).billingApiUrl

  const axiosRequestConfig: AxiosRequestConfig = {
    baseURL: BILLING_API
  }

  useEffect(() => {
    const fetchBillingData = async () => {
      setIsLoading(true)

      try {
        const token = await getToken()

        const { data } = await getBillingData(token, axiosRequestConfig)
        setData(data)

        setError(undefined)
      } catch (e) {
        console.error('Failed to load billing data for user', e)
        setData(undefined)
        setError(e as Error)
      }

      setIsLoading(false)
    }

    fetchBillingData()
  }, [getToken, setData, setIsLoading, organisation, offline])

  const calcRemainingDays = () => {
    const now = new Date()
    const trialPeriodEndAt = data?.trial?.periodEndAt
      ? new Date(data?.trial?.periodEndAt)
      : undefined

    return trialPeriodEndAt
      ? differenceInCalendarDays(trialPeriodEndAt, now)
      : undefined
  }

  const calcRemainingCredits = () => {
    const now = new Date()
    const trialPeriodEndAt = data?.trial?.periodEndAt
      ? new Date(data?.trial?.periodEndAt)
      : undefined

    if (trialPeriodEndAt && isAfter(now, trialPeriodEndAt)) {
      return 0
    }

    if (
      data?.trial?.creditAllowance !== undefined &&
      data?.trial?.creditsConsumed !== undefined
    ) {
      return parseInt(
        (
          data?.trial?.creditAllowance - data?.trial?.creditsConsumed
        ).toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 1
        })
      )
    }

    return undefined
  }

  const hasTrialExpired = () =>
    data?.trial?.periodEndAt
      ? isAfter(new Date(), new Date(data.trial.periodEndAt))
      : undefined

  return {
    isLoading,
    error,
    data,
    hasTrialExpired,
    calcRemainingDays,
    calcRemainingCredits
  }
}

export default useBillingData
