import usePlatformMenuStructure from './usePlatformMenuStructure'

import useEntitlements from '../../../effects/useEntitlements'
import useUser from '../../../effects/useUser'

export function usePlatformMenu() {
  const [{ services: standardServices, conditionalServices, subMenus }] =
    usePlatformMenuStructure()

  const { data: entitlements } = useEntitlements()
  const canSeeDesignerTile = entitlements?.canSeeDesignerTile
  const { roles } = useUser()
  const isSaasEtlUser = roles.has('saas-etl:user')

  const createNewServicesList = (key: string, pos: number) => {
    const conditionalService = conditionalServices.find(
      (obj) => obj.key === key
    )

    if (conditionalService) {
      return [
        ...standardServices.slice(0, pos),
        conditionalService,
        ...standardServices.slice(pos)
      ]
    }

    return []
  }

  const designerAccessible = isSaasEtlUser && canSeeDesignerTile
  const services = designerAccessible
    ? createNewServicesList('project-explorer-frontend', 1)
    : standardServices

  return {
    services,
    subMenus
  }
}
