import { useGetProjects } from './useGetProjects'
import { useMemo } from 'react'

export function useProjectNames(): Record<string, string> {
  const { data } = useGetProjects()
  return useMemo(() => {
    return (data ?? []).reduce(
      (result, project) => ({
        ...result,
        [project.id]: project.name
      }),
      {}
    )
  }, [data])
}
