import { DataTable, DataTableBody } from 'components/DataTable'
import { ActivityItem, DpcEtlPipelineDefinition } from 'types/eos'
import { FC } from 'react'
import PanelTableRow from './PanelTableRow'
import { calcDuration } from 'modules/Activity/RunHistoryDataGrid/hooks/calcDuration'
import { DateTime } from 'components/DateTime'
import { formatTrigger } from 'modules/Activity/RunHistoryDataGrid/hooks/formatTrigger'

export interface PipelinesDetailTableProps {
  data: ActivityItem
}

const PipelineDetailsTable: FC<PipelinesDetailTableProps> = ({ data }) => {
  const { result, trigger, definition } = data
  return (
    <DataTable>
      <DataTableBody>
        <PanelTableRow fieldName="Project">
          {(definition as DpcEtlPipelineDefinition).projectName || '-'}
        </PanelTableRow>
        <PanelTableRow fieldName="Status">{result.status}</PanelTableRow>
        <PanelTableRow fieldName="Started at">
          <DateTime timestamp={result.startedAt} />
        </PanelTableRow>
        <PanelTableRow fieldName="Finished at">
          <DateTime timestamp={result.finishedAt ?? ''} />
        </PanelTableRow>
        <PanelTableRow fieldName="Duration">
          {calcDuration(result.startedAt, result.finishedAt ?? '-')}
        </PanelTableRow>
        <PanelTableRow fieldName="By">{formatTrigger(trigger)}</PanelTableRow>
      </DataTableBody>
    </DataTable>
  )
}

export default PipelineDetailsTable
