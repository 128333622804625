import { Heap } from '../globals'

export const heap: Heap = {
  getHeapInstance: () => {
    if (window.heap) {
      return window.heap
    }
    console.debug('The Heap library has not been loaded.')
  },
  identify: (identity) => {
    try {
      heap.getHeapInstance()?.identify(identity)
    } catch (e) {
      console.debug(
        'heap.identify errored. Check that Heap has loaded and window.heap is available.',
        e
      )
    }
  },
  addUserProperties: (properties) => {
    try {
      heap.getHeapInstance()?.addUserProperties(properties)
    } catch (e) {
      console.debug(
        'heap.addUserProperties errored. Check that Heap has loaded and window.heap is available.',
        e
      )
    }
  },
  track: (event, properties) => {
    try {
      heap.getHeapInstance()?.track(event, properties)
    } catch (e) {
      console.debug(
        'heap.track errored. Check that Heap has loaded and window.heap is available.',
        e
      )
    }
  },
  addEventProperties: (properties) => {
    try {
      heap.getHeapInstance()?.addEventProperties(properties)
    } catch (e) {
      console.debug(
        'heap.addEventProperties errored. Check that Heap has loaded and window.heap is available.',
        e
      )
    }
  },
  clearEventProperties: () => {
    try {
      heap.getHeapInstance()?.clearEventProperties()
    } catch (e) {
      console.debug(
        'heap.clearEventProperties errored. Check that Heap has loaded and window.heap is available.',
        e
      )
    }
  },
  resetIdentity: () => {
    try {
      heap.getHeapInstance()?.resetIdentity()
    } catch (e) {
      console.debug(
        'heap.resetIdentity errored. Check that Heap has loaded and window.heap is available.',
        e
      )
    }
  },
  removeEventProperty: (property) => {
    try {
      heap.getHeapInstance()?.removeEventProperty(property)
    } catch (e) {
      console.debug(
        'heap.removeEventProperty errored. Check that Heap has loaded and window.heap is available.',
        e
      )
    }
  }
}
