import { useSuspenseQuery } from '@tanstack/react-query'
import { getBillingInstallations } from '../billing'
import { useBillingClient } from './useBillingClient'

export const useGetBillingInstallations = () => {
  const client = useBillingClient()
  return useSuspenseQuery({
    queryKey: ['billing-installations'],
    queryFn: async () => getBillingInstallations(client)
  })
}
