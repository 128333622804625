import { PipelineTrigger } from 'types/eos'

export function formatTrigger(trigger: PipelineTrigger) {
  switch (trigger.type) {
    case 'HUB_USER':
      return trigger.name || '(unknown user)'
    case 'DPC_SCHEDULE':
      return trigger.name || '(unknown schedule)'
    case 'API_USER':
      return 'API User'
    case 'UNKNOWN':
      return '—'
  }
}
