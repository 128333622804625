import {
  Grid,
  LoadingSpinner,
  Page,
  Section,
  Toaster
} from '@matillion/component-library'
import { useGetSinglePipelineActivity } from 'api/hooks/useGetSinglePipelineActivity'
import { useNavigate, useParams } from 'react-router-dom'
import { safeString } from 'utils/safeString'
import classes from './PipelineRunDetails.module.scss'
import PipelineRunDetailsHeader from './PipelineRunDetailsHeader/PipelineRunDetailsHeader'
import PipelineTree from './PipelineTree/PipelineTree'
import { useEffect, useRef } from 'react'
import { useAuth, useFlags, useUser } from '@matillion/hub-client'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { Client } from '@stomp/stompjs'
import { StepProgressUpdate } from 'types/eos'
import { StepsResponse } from 'api/eos'
import { pipelineMessageHandler } from 'utils/websocket/pipelineMessageHandler'
import config from 'config'
import { createStompConnection } from 'utils/websocket/websocket'

const PipelineRunDetails = () => {
  const { pipelineId } = useParams()
  const navigate = useNavigate()
  const toaster = Toaster.useToaster()
  const { data, error, isLoading } = useGetSinglePipelineActivity(
    safeString(pipelineId)
  )

  const queryClient = useQueryClient()
  const { organisation } = useUser()
  const { getToken } = useAuth()
  const stompClientRef = useRef<Client | null | 'init'>(null)
  const { rolloutEnableRealTimeUpdates } = useFlags()

  useEffect(() => {
    if (!pipelineId || !rolloutEnableRealTimeUpdates) {
      return
    }

    const handleNewMessage = (message: StepProgressUpdate) => {
      queryClient.setQueryData(
        ['steps', message.pipelineExecutionId, null],
        (stepsData: InfiniteData<StepsResponse, unknown> | undefined) =>
          pipelineMessageHandler(message, stepsData)
      )

      if (message.step.result.status === 'FAILED') {
        queryClient.setQueryData(
          ['steps', message.pipelineExecutionId, 'FAILED'],
          (failedStepsData: InfiniteData<StepsResponse, unknown> | undefined) =>
            pipelineMessageHandler(message, failedStepsData)
        )
      }
    }

    const connectStomp = async () => {
      try {
        stompClientRef.current = 'init'
        const tokenOptions = { audience: config.platformAudience }
        const jwt = await getToken(tokenOptions)
        stompClientRef.current = createStompConnection(
          pipelineId,
          organisation.id,
          jwt,
          handleNewMessage
        )
      } catch (stompError) {
        console.log('Error connecting to stomp', stompError)
      }
    }

    !stompClientRef.current && connectStomp()

    return () => {
      if (stompClientRef.current !== 'init' && stompClientRef.current) {
        stompClientRef.current.deactivate()
      }
    }
  }, [
    pipelineId,
    organisation.id,
    queryClient,
    rolloutEnableRealTimeUpdates,
    getToken
  ])

  useEffect(() => {
    if (!error) {
      return
    }

    navigate('/')

    if (error?.response?.status === 404) {
      toaster.makeToast({
        type: 'error',
        theme: 'dark',
        title: 'Sorry.',
        message: 'We couldn’t find that pipeline.'
      })
      return
    }

    if (error?.response?.status === 403) {
      toaster.makeToast({
        type: 'error',
        theme: 'dark',
        title: 'Sorry.',
        message: 'You don’t have permission to view that pipeline.'
      })
      return
    }

    toaster.makeToast({
      type: 'error',
      theme: 'dark',
      title: 'Sorry.',
      message: 'Something went wrong when attempting to load the pipeline.'
    })
  }, [error, navigate, toaster])

  return (
    <Page>
      <Grid className={classes.Grid}>
        {isLoading || !data ? (
          <LoadingSpinner className={classes.LoadingSpinner} />
        ) : (
          <>
            <Section size={12}>
              <PipelineRunDetailsHeader pipelineActivity={data} />
            </Section>
            <Section>
              <PipelineTree
                pipelineName={safeString(data?.definition.pipelineName)}
                title="Steps with errors"
                status="FAILED"
                endOfResultsMessage="That's all the failed steps."
                data-testid="list-failed-steps"
              />
            </Section>
            <Section size={12}>
              <PipelineTree
                pipelineName={safeString(data?.definition.pipelineName)}
                title="All steps"
                endOfResultsMessage="That's everything for this pipeline."
                data-testid="list-all-steps"
              />
            </Section>
          </>
        )}
      </Grid>
    </Page>
  )
}

export default PipelineRunDetails
