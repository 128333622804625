import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getDataset } from 'api/dgs'
import useDgsClient from './useDgsClient'
import { Dataset } from 'types/dgs'

export const useGetDataset = (datasetId: string) => {
  const client = useDgsClient()
  return useQuery<Dataset, AxiosError>({
    queryKey: ['dataset', datasetId],
    queryFn: async () => getDataset(client, datasetId),
    refetchOnMount: false,
    staleTime: 5 * 60 * 1000
  })
}
