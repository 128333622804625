import { Loader, Section } from '@matillion/component-library'
import { useGetDatasetLineage } from 'api/hooks/useGetDatasetLineage'
import ErrorMessage from 'components/ErrorMessage'
import { FC } from 'react'
import classes from './Lineage.module.scss'
import { transformEdges, transformNodes } from './LineageTransformers'
import LineageGraphWrapper from 'components/ReactFlow/LineageGraphWrapper'
import { useGetColumnLineage } from 'api/hooks/useGetColumnLineage'
import { useFlags } from '@matillion/hub-client'

const FieldLineageEndpointExperiment: FC<LineageProps> = ({
  initialDatasetId
}) => {
  useGetColumnLineage(initialDatasetId)
  return <></>
}

export interface LineageProps {
  initialDatasetId: string
}

const Lineage: FC<LineageProps> = ({ initialDatasetId }) => {
  const { rolloutEnableFieldLineageEndpoint } = useFlags()
  const { data, isFetching, error } = useGetDatasetLineage(initialDatasetId)

  return (
    <>
      {rolloutEnableFieldLineageEndpoint && (
        <FieldLineageEndpointExperiment initialDatasetId={initialDatasetId} />
      )}
      <Section size={12} className={classes.CanvasContainer}>
        {isFetching && <Loader />}
        {!isFetching && !error && data && (
          <LineageGraphWrapper
            initialNodes={transformNodes(data.nodes, data.edges)}
            initialEdges={transformEdges(data.edges)}
            initialDatasetId={initialDatasetId}
          />
        )}
        {error && (
          <ErrorMessage className={classes.ErrorMessage}>
            Something went wrong when loading the dataset lineage.
          </ErrorMessage>
        )}
      </Section>
    </>
  )
}

export default Lineage
