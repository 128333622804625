import React, { FunctionComponent } from 'react'
import useFlags from '../effects/useFlags'
import { ServiceRegistryProvider } from './ServiceRegistryProvider'
import { MockServiceRegistryProvider } from './MockServiceRegistryProvider'

interface Props {
  enableServiceRegistryFlagName: string
  endpoints?: {
    [index: string]: string
  }
}
export const FlaggedServiceRegistryProvider: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ children, enableServiceRegistryFlagName, endpoints }) => {
  const useServiceRegistry = useFlags()[enableServiceRegistryFlagName]
  return useServiceRegistry ? (
    <ServiceRegistryProvider>{children}</ServiceRegistryProvider>
  ) : (
    <MockServiceRegistryProvider endpoints={endpoints}>
      {children}
    </MockServiceRegistryProvider>
  )
}
