import { CacheLocation } from '@auth0/auth0-react'

import { AuthProviderEnvironment } from '../types'

import dev from './dev.json'
import local from './local.json'
import preprod from './preprod.json'
import prod from './prod.json'
import test from './test.json'

const ENVIRONMENTS: Record<AuthProviderEnvironment, EnvironmentConfig> = {
  dev,
  local,
  preprod,
  prod,
  test
}

interface EnvironmentConfig {
  AUTH_DOMAIN: string
  AUTH_CLIENTID: string
  AUTH_AUDIENCE: string
  AUTH_CACHE_LOCATION: string

  APP_SHELL_URL: string
  HUB_URL: string
  HUB_API_URL: string
  ENTITLEMENTS_API_URL: string
  BILLING_API_URL: string
  HUB_ASSETS_URL: string
  REGISTRY_URL: string
  PERMISSIONS_API_URL: string

  PLATFORM_NAVIGATION: {
    SOURCE: string
  }

  SERVICES: {
    HOME_URL: string
  }

  LAUNCH_DARKLY: {
    CLIENT_ID: string
    DEFAULT_FLAGS: Record<string, boolean>
  }

  INTERCOM_APP_ID: string
}

const getConfig = (environment: AuthProviderEnvironment) => {
  const config =
    ENVIRONMENTS[environment] || ENVIRONMENTS[AuthProviderEnvironment.dev]

  if (!ENVIRONMENTS[environment]) {
    console.error(`Unrecognised AuthProvider environment: ${environment}`)
  }

  return {
    version: process.env.HUB_CLIENT_VERSION as string,
    appShellUrl: config.APP_SHELL_URL,
    hubUrl: config.HUB_URL,
    hubApiUrl: config.HUB_API_URL,
    entitlementsApiUrl: config.ENTITLEMENTS_API_URL,
    billingApiUrl: config.BILLING_API_URL,
    hubAssetsUrl: config.HUB_ASSETS_URL,
    registryUrl: config.REGISTRY_URL,
    permissionsApiUrl: config.PERMISSIONS_API_URL,

    auth0: {
      domain: config.AUTH_DOMAIN,
      clientId: config.AUTH_CLIENTID,
      audience: config.AUTH_AUDIENCE,
      cacheLocation: config.AUTH_CACHE_LOCATION as CacheLocation
    },

    launchDarkly: {
      defaultClientId: config.LAUNCH_DARKLY.CLIENT_ID,
      defaultFlags: config.LAUNCH_DARKLY.DEFAULT_FLAGS
    },

    platformNavigation: {
      source: config.PLATFORM_NAVIGATION?.SOURCE
    },

    services: {
      homeUrl: config.SERVICES.HOME_URL
    },

    intercom: {
      app_id: config.INTERCOM_APP_ID
    }
  }
}

export default getConfig
