import { useQueryClient } from '@tanstack/react-query'
import classes from './RefreshButton.module.scss'
import { Icon } from '@matillion/component-library'

const RefreshButton = () => {
  const queryClient = useQueryClient()

  return (
    <button
      type="button"
      aria-label="Refresh"
      className={classes.Refresh}
      onClick={() => {
        void queryClient.resetQueries({
          queryKey: ['activity']
        })
      }}
    >
      <Icon.Refresh />
    </button>
  )
}

export default RefreshButton
