import { FunctionComponent, useEffect, ReactNode } from 'react'
import { Loader } from '@matillion/component-library'

import locationRedirect from '../../utils/locationRedirect'
import useEnvironment from '../../effects/useEnvironment'
import useRole from '../../effects/useRole'

import { FullPageLoader } from '../FullPageLoader/FullPageLoader'

export interface RoleGuardProps {
  /**
   * The role that the current user needs to access this content.
   * It should follow the following taxonomy:
   *
   * ```
   * yourapp:role
   * ```
   *
   * If you're uncertain of what roles your application has
   * available, please contact the Hub team.
   */
  role: string

  /**
   * The components you want to protect behind your specified
   * role. These will only ever be mounted if the user has
   * the applicable role.
   */
  children: ReactNode
}

/**
 * Wrap a route in this component to ensure that only users with
 * the specified role can access it.
 *
 * If you wrap something (probably the contents of a route, right?) in the
 * <RoleGuard /> you can pass a role via props to ensure the user has that role.
 *
 * As an example:
 * ```jsx
 * <Switch>
 *   <Route path="/admin">
 *     <RouteGuard role="hub:admin">
 *       <MyAdminDashboard />
 *     </RouteGuard>
 *   </Route>
 * </Switch>
 * ```
 *
 * If the user accessing the /admin route in the above example has the hub:admin
 * role (amongst others), everything will just render. If the user does not have
 * that role, they will be redirected to a hub error page.
 *
 * @param props See [[RoleGuardProps]].
 * @category Components
 */

const RoleGuardLoader = function () {
  if (window.__MICROVERSE__) {
    return <FullPageLoader />
  }

  return <Loader data-testid="RoleGuard/Loading" />
}

const RoleGuard: FunctionComponent<React.PropsWithChildren<RoleGuardProps>> = ({
  children,
  role
}) => {
  const { hubUrl } = useEnvironment()
  const isGrantedAccess = useRole(role)

  useEffect(() => {
    if (isGrantedAccess) {
      return
    }

    locationRedirect(`${hubUrl}/error/incorrect_role/${role}`)
  }, [isGrantedAccess, hubUrl])

  if (!isGrantedAccess) {
    return <RoleGuardLoader />
  }

  return <>{children}</>
}

export default RoleGuard
