const highlightText = (text: string, searchTerm: string) => {
  const index = text.toLowerCase().indexOf(searchTerm.toLowerCase())
  if (index === -1) return <>{text}</>

  const beforeTerm = text.substring(0, index)
  const term = text.substring(index, index + searchTerm.length)
  const afterTerm = text.substring(index + searchTerm.length)

  return (
    <>
      {beforeTerm}
      <mark>{term}</mark>
      {afterTerm}
    </>
  )
}

export default highlightText
