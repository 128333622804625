import { AuthProviderEnvironment } from '../../types'

const envs: AuthProviderEnvironment[] = [
  AuthProviderEnvironment.local,
  AuthProviderEnvironment.dev,
  AuthProviderEnvironment.preprod,
  AuthProviderEnvironment.prod
]

interface ParseProductResult {
  env: AuthProviderEnvironment
  basename: string
}

export function parseProduct(product: string): ParseProductResult {
  const parts = product.split('-')

  if (envs.includes(parts[0] as AuthProviderEnvironment)) {
    return {
      env: parts[0] as AuthProviderEnvironment,
      basename: parts.slice(1).join('-')
    }
  } else if (
    envs.includes(parts[parts.length - 1] as AuthProviderEnvironment)
  ) {
    return {
      env: parts[parts.length - 1] as AuthProviderEnvironment,
      basename: parts.slice(0, -1).join('-')
    }
  }

  return { env: AuthProviderEnvironment.prod, basename: product }
}
