import { SkeletonText, Tooltip, Typography } from '@matillion/component-library'
import classes from './DetailViewHeader.module.scss'
import { FC } from 'react'
import BackToLineage from './BackToExplore'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { useGetDataset } from 'api/hooks/useGetDataset'

const DetailViewHeader: FC = () => {
  const params = useParams()
  const { data, isLoading } = useGetDataset(params.datasetId as string)
  const path = data ? [data.namespace, ...data.path].join(' / ') : ''
  return (
    <div className={classNames(classes.DetailViewHeader)}>
      <div className="u-truncate-text" style={{ minHeight: '100%' }}>
        <BackToLineage />
        {isLoading ? (
          <>
            <SkeletonText
              type="h2"
              className={classes.DetailViewHeader__MainSkeleton}
            />
            <SkeletonText
              type="h3"
              className={classes.DetailViewHeader__SecondarySkeleton}
            />
          </>
        ) : (
          <>
            <Tooltip
              content={data?.name}
              position="top"
              className={classes.DetailViewHeader__Tooltip}
            >
              <div>
                <Typography
                  as="h1"
                  format="dts"
                  className={classNames(
                    classes.DetailViewHeader__MainHeading,
                    'u-truncate-text'
                  )}
                  data-testid="dataset-title"
                >
                  {data?.name}
                </Typography>
              </div>
            </Tooltip>
            <Tooltip content={path}>
              <div>
                <Typography
                  format="bcm"
                  className={classNames(
                    classes.DetailViewHeader__Location,
                    'u-truncate-text'
                  )}
                  data-testid="dataset-namespace"
                >
                  {path}
                </Typography>
              </div>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  )
}

export default DetailViewHeader
