import { PlatformMenuServiceItem } from '@matillion/component-library'

import useMatillionApplicationURL from '../../effects/useMatillionApplicationURL'
import { useServiceUrl } from '../../effects/useServiceUrl'
import { parseHost } from '../../utils/parseHost'

interface HubPlatformMenuServiceItemProps {
  name: string
  description: string
  serviceKey: string
}

const HubPlatformMenuServiceItem = ({
  name,
  description,
  serviceKey
}: HubPlatformMenuServiceItemProps) => {
  const url = useServiceUrl(serviceKey, true)
  const { isActiveApplication } = useMatillionApplicationURL(url)

  const isActive = () => {
    const currentApplication = window.location.hostname
    const parsedActiveApplication = parseHost(currentApplication).product

    const activeApplicationEnvRemoved = parsedActiveApplication?.includes('-')
      ? parsedActiveApplication.substring(
          0,
          parsedActiveApplication.indexOf('-')
        )
      : parsedActiveApplication

    // The nav is currently set to go to `projects` url under the designer tab.
    // The 'Designer' tab is automatically `active` when using the `isActiveApplication` function
    // as it compares the active hostname to that of the application provided.
    // This makes the `designer` url also make the 'Designer' tab in the nav `active`

    const applicationIsActive =
      activeApplicationEnvRemoved === 'designer' && name === 'Designer'
        ? true
        : isActiveApplication

    return applicationIsActive
  }

  return (
    <PlatformMenuServiceItem
      as="a"
      active={isActive()}
      href={url.toString()}
      name={name}
      description={description}
    />
  )
}

export default HubPlatformMenuServiceItem
