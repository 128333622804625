import { useMutation } from '@tanstack/react-query'
import useEosClient from './useEosClient'
import { cancelPipeline } from '../eos'

export interface CancelPipelineParams {
  pipelineId: string
  force: boolean
}

export const useCancelPipeline = (pipelineId: string) => {
  const client = useEosClient()
  return useMutation({
    mutationKey: ['cancellation', pipelineId],
    mutationFn: async (request: CancelPipelineParams) =>
      cancelPipeline(client, request.pipelineId, request.force)
  })
}
