import { FlowNode } from 'components/ReactFlow/LineageGraph'
import dagre from 'dagre'
import { Edge, MarkerType, Node } from 'reactflow'
import { ColumnLineage, LineageEdge, LineageNode } from 'types/dgs'

export const determineNodeDimensions = (node: Node) => {
  if (node.height) {
    return { width: isPipeline(node) ? 30 : 300, height: node.height }
  }

  if (isPipeline(node)) {
    return { width: 30, height: 36 }
  }

  return { width: 300, height: 74 }
}

const makeNode = (node: LineageNode | FlowNode): Node => ({
  ...node,
  position: { x: 0, y: 0 },
  selectable:
    node.type !== undefined && ['DATASET', 'PIPELINE'].includes(node.type)
})

const isPipeline = (node: Node): boolean => {
  return (
    node.type !== undefined &&
    ['PIPELINE', 'INACCESSIBLE_PIPELINE'].includes(node.type)
  )
}

export const transformNodes = (
  nodes: LineageNode[] | FlowNode[],
  edges: LineageEdge[]
) => {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  const transformedNodes = nodes.map(makeNode)

  dagreGraph.setGraph({ rankdir: 'LR', align: 'UL', ranksep: 200 })

  transformedNodes.forEach((transformedNode) => {
    dagreGraph.setNode(
      transformedNode.id,
      determineNodeDimensions(transformedNode)
    )
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  transformedNodes.forEach((transformedNode) => {
    const nodeWithPosition = dagreGraph.node(transformedNode.id)

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    const { width, height } = determineNodeDimensions(transformedNode)

    transformedNode.position = {
      x: nodeWithPosition.x - width / 2,
      y: nodeWithPosition.y - height / 2
    }

    // If this is a new node we must adjust the data structure
    if (!transformedNode.data.content) {
      const newData = {
        content: transformedNode.data,
        meta: {}
      }

      transformedNode.data = newData
    }
  })

  return transformedNodes
}

export const transformEdges = (edges: LineageEdge[]) => {
  return edges.map((edge) => {
    return {
      ...edge,
      id: `${edge.source}-${edge.target}`,
      data: {
        meta: {
          selectedField: undefined
        }
      }
    }
  })
}

export const transformColumnLineage = (edges: ColumnLineage[]): Edge[] => {
  return edges.map(({ target, source }) => {
    const id = [
      source.datasetId,
      source.fieldName,
      target.datasetId,
      target.fieldName
    ].join('-')

    return {
      target: target.datasetId,
      source: source.datasetId,
      sourceHandle: source.fieldName,
      targetHandle: target.fieldName,
      id,
      // column level edges should appear above nodes
      // we only show this when a column is selected
      zIndex: 1001,
      markerEnd: {
        type: MarkerType.Arrow
      },
      data: {
        meta: {
          selectedField: undefined
        }
      }
    }
  })
}
