import { ReactComponent as ActiveFilterIcon } from './ActiveFilterIcon.svg'
import classes from './FilterCellHead.module.scss'
import { ReactComponent as FilterIcon } from './FilterIcon.svg'

export interface Props {
  isActive?: boolean
  isVisible?: boolean
}

export const IconPicker = ({ isActive, isVisible }: Props) => {
  if (isActive) {
    return (
      <ActiveFilterIcon className={classes.FilterTableHead__Button__Icon} />
    )
  }

  if (isVisible) {
    return <FilterIcon className={classes.FilterTableHead__Button__Icon} />
  }

  return <div className={classes.FilterTableHead__Button__FauxIcon}></div>
}
