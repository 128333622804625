import { getCookieValue } from '../utils/getCookieValue'

const utms = [
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_content',
  'utm_term'
] as const

type UtmParam = typeof utms[number]

export const modifyUtmParams = (
  params: Record<UtmParam, string | undefined>
) => {
  const result = utms.reduce<Record<string, string>>((output, utm) => {
    const prefixedUtm = `mkto_${utm}`
    const utmValue = params[utm]
    if (typeof utmValue === 'string') {
      output[prefixedUtm] = utmValue
    }
    return output
  }, {})

  if (Object.keys(result).length === 0) {
    utms.forEach((utm) => {
      const cookieName = `mkto_${utm}`
      const cookieValue = getCookieValue(cookieName)
      if (cookieValue) {
        result[cookieName] = cookieValue
      }
    })
  }

  return result
}
