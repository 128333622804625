import { FunctionComponent, HTMLAttributes, PropsWithChildren } from 'react'
import MetadataBlock from './MetadataBlock'
import Logo from 'components/Logo'
import { Typography } from '@matillion/component-library'
import classes from './MetadataBlock.module.scss'

interface MetadataProps
  extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  label: string
  svgFilename?: string | null
}

const Metadata: FunctionComponent<MetadataProps> = ({
  label,
  svgFilename,
  children,
  ...props
}) => {
  return (
    <MetadataBlock label={label} {...props}>
      {svgFilename && <Logo SVGFileName={svgFilename} />}
      <Typography
        format="bcs"
        className={classes.MetadataBlock__Label}
        data-testid={`${label
          .replace(/\s+/g, '-')
          .toLowerCase()}-metadata-value`}
      >
        {children || '-'}
      </Typography>
    </MetadataBlock>
  )
}

export default Metadata
