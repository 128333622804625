import { Children, FC, MouseEvent, PropsWithChildren } from 'react'

import classnames from 'classnames'

import classes from './HorizontalStack.module.scss'

export type Props = PropsWithChildren<{
  withSpacer?: boolean
  'aria-label'?: string
}>

export const HorizontalStack: FC<Props> = ({
  children,
  withSpacer,
  'aria-label': ariaLabel
}) => (
  <ul
    onContextMenu={(e: MouseEvent) => e.stopPropagation()}
    aria-label={ariaLabel}
    className={classnames(
      classes.HorizontalStack,
      withSpacer && classes['HorizontalStack--Spacer']
    )}
  >
    {Children.map(children, (child) => (
      <li className={classes.HorizontalStack__Item}>{child}</li>
    ))}
  </ul>
)
