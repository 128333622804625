import useUser from './useUser'

/**
 * Returns a boolean representing whether or not the current
 * user has the provided role.
 *
 * @param role The user role to look for. This will be structured
 *             in a format similar to "hub:admin", where the first
 *             part is your application scope and the second is
 *             the user's role.
 * @returns Whether or not the current user has been granted `role`.
 * @category Hooks
 */
const useRole = (role: string): boolean => {
  const { roles } = useUser()

  return roles.has(role)
}

export default useRole
