import { useEffect, useState } from 'react'
import { useUser } from '@matillion/hub-client'
import config from 'config'

const billingURLPaths = {
  setup: '/setup',
  usage: '/usage',
  instances: '/instances'
}

const useMatillionServiceUrl = () => {
  const [startServiceUrl, setStartServiceUrl] = useState('')
  const [billingUrl, setBillingUrl] = useState('')
  const [dataLoaderUrl, setDataLoaderUrl] = useState('')
  const { organisation } = useUser()

  useEffect(() => {
    if (!organisation.subdomain) return
    const getEnv = config.environment !== 'prod' ? `-${config.environment}` : ''
    const getDataLoaderEnv =
      config.environment !== 'prod' ? `${config.environment}-` : ''

    setStartServiceUrl(
      `https://${organisation.subdomain}.start${getEnv}.matillion.com`
    )
    setBillingUrl(
      `https://${organisation.subdomain}.billing${getEnv}.matillion.com`
    )
    setDataLoaderUrl(
      `https://${organisation.subdomain}.${getDataLoaderEnv}dataloader.matillion.com`
    )
  }, [organisation.subdomain])

  return {
    billingUrl,
    dataLoaderUrl,
    startServiceUrl
  }
}

export { useMatillionServiceUrl, billingURLPaths }
