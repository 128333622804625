import { getInstallations } from 'api/odb'
import { useSuspenseQuery } from '@tanstack/react-query'
import useClient from './useClient'

export const useInstallations = () => {
  const client = useClient()
  return useSuspenseQuery({
    queryKey: ['installations'],
    queryFn: async () => getInstallations(client)
  })
}
