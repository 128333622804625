import { useState, useEffect } from 'react'
import { AxiosRequestConfig } from 'axios'
import { getEntitlements } from '../api/entitlements'
import getConfig from '../config'
import useUser from './useUser'
import useEnvironment from './useEnvironment'
import useAuth from './useAuth'
import { Entitlements } from '../types'

const useEntitlements = () => {
  const { getToken } = useAuth()
  const { organisation } = useUser()
  const { environment, offline } = useEnvironment()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()
  const [data, setData] = useState<Entitlements>()

  const ENTITLEMENTS_API_URL = getConfig(environment).entitlementsApiUrl

  const apiBaseUrl = ENTITLEMENTS_API_URL.replace(
    '{{region}}',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    organisation.region!
  )

  const axiosRequestConfig: AxiosRequestConfig = {
    baseURL: apiBaseUrl
  }

  useEffect(() => {
    const fetchEntitlement = async () => {
      setLoading(true)

      try {
        const token = await getToken()

        if (offline) {
          const fromLocalStorage = localStorage.getItem('entitlements')
          if (fromLocalStorage) {
            setData(JSON.parse(fromLocalStorage))
          } else {
            setData({})
          }
        } else {
          const { data } = await getEntitlements(
            token,
            organisation.id,
            axiosRequestConfig
          )
          setData(data)
        }

        setError(undefined)
      } catch (e) {
        console.error('Failed to load entitlements for user', e)
        setData(undefined)
        setError(e as Error)
      }

      setLoading(false)
    }

    fetchEntitlement()
  }, [getToken, setData, setLoading, organisation, offline])

  return { data, isLoading, error }
}

export default useEntitlements
