import { InputProps, SearchBar } from '@matillion/component-library'
import { useMemo, FunctionComponent, useState, useEffect } from 'react'
import classes from './SearchInput.module.scss'
import debounce from 'debounce'

const SearchInput: FunctionComponent<
  InputProps & {
    debounceWait?: number
    onChange: (e: { target: { value: string } }) => void
    value: string
  }
> = ({ onChange, debounceWait = 300, placeholder, value, prefix, ...rest }) => {
  const [currentValue, setCurrentValue] = useState<string>(value)
  useEffect(() => {
    setCurrentValue(value)
  }, [value])
  const debouncedChangeHandler = useMemo(
    () => debounce(onChange, debounceWait),
    [debounceWait, onChange]
  )

  return (
    <SearchBar
      className={classes.SearchInput}
      placeholder={placeholder}
      onChange={(e) => {
        setCurrentValue(e.target.value)
        debouncedChangeHandler(e)
      }}
      value={currentValue}
      crossOrigin={''}
      {...rest}
    />
  )
}

export default SearchInput
