import { useQuery } from '@tanstack/react-query'
import useEosClient from './useEosClient'
import { getSinglePipelineActivity } from 'api/eos'
import { ActivityItem } from 'types/eos'
import { AxiosError } from 'axios'
import { useProjectNames } from './useProjectNames'
import { augmentItem } from '../utils/augmentItem'

export const useGetSinglePipelineActivity = (pipelineId: string) => {
  const projectNames = useProjectNames()
  const client = useEosClient()
  const result = useQuery<ActivityItem, AxiosError>({
    queryKey: ['singlePipelineActivity', pipelineId],
    queryFn: async () => getSinglePipelineActivity(client, pipelineId)
  })
  augmentItem(result.data, projectNames)
  return result
}
