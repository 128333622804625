import { FunctionComponent } from 'react'

import useEnvironment from '../../../effects/useEnvironment'

const MyDetailsLink: FunctionComponent<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { hubUrl } = useEnvironment()

  return (
    <a
      target="_blank"
      rel="noreferrer"
      {...props}
      href={`${hubUrl}/profile/personal-details`}
    >
      {children}
    </a>
  )
}

export default MyDetailsLink
