import { FunctionComponent, Suspense } from 'react'
import classnames from 'classnames'
import { Typography, EditableText } from '@matillion/component-library'
import { Instance as InstanceProps, InstallationId } from 'types/installations'
import classes from './Instance.module.scss'
import { useUser } from '@matillion/hub-client'
import {
  Accordion,
  AccordionHeader,
  AccordionButton,
  AccordionTitle,
  AccordionContent
} from 'components/Accordion'
import Status from './Status'
import ActionBtnSection from './ActionBtnSection'
import EditableTextSection from './EditableTextSection'
import { JobActivity, JobActivityError } from './JobActivity'
import Metadata from 'components/Metadata'
import Chevron from 'svg/Chevron'
import {
  PLATFORMS,
  DATA_WAREHOUSE,
  keyDataWarehouseTypes,
  keyPlatformTypes
} from 'svg/logos/logo.list'
import { ErrorBoundary } from 'react-error-boundary'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { Timestamp } from 'components/Timestamp'
import useUpdateMetadata from 'api/hooks/useUpdateMetadata'

const Instance: FunctionComponent<{
  instances: InstanceProps[]
  installationId: InstallationId
  installationName: string
  description: string
}> = ({ instances, installationId, installationName, description }) => {
  const { organisation } = useUser()
  const organisationId = organisation.id
  const updateMetadata = useUpdateMetadata()
  const handleDescriptionChange = (newDescription: string) =>
    updateMetadata.mutate({
      description: newDescription,
      installationId,
      organisationId
    })

  return (
    <>
      {instances.map((instance: InstanceProps) => (
        <Accordion
          key={instance.instanceId}
          id={instance.instanceId}
          data-testid="instance-card"
          data-tracker="instance-accordion-card"
        >
          <AccordionHeader>
            <div className={classes.Instance__Header}>
              <AccordionTitle
                className={classes.Instance__TitleContainer}
                data-tracker="instance-card-item-title"
                data-testid="instance-card-btn"
                data-installationid={installationId}
                data-instanceid={instance.instanceId}
              >
                <Typography
                  as="h2"
                  format="bcl"
                  weight="bold"
                  data-testid="instance-metadata-name"
                >
                  {installationName}
                </Typography>
              </AccordionTitle>
              <EditableTextSection>
                <EditableText
                  text={description === '' ? 'Add a description' : description}
                  maxLength={255}
                  onChange={handleDescriptionChange}
                  className={classnames({
                    [classes.Instance__DefaultDescription]: !description
                  })}
                />
              </EditableTextSection>
              <Status state={instance.state} />
              <ActionBtnSection>
                <AccordionButton
                  aria-label={`${instance.instanceId} dropdown button`}
                  data-tracker="instance-card-item-btn"
                  className={classes.Instance__ActionButton}
                >
                  <Chevron />
                </AccordionButton>
              </ActionBtnSection>
            </div>
          </AccordionHeader>
          <AccordionContent
            data-tracker="instance-accordion-content-panel"
            data-testid="instance-metadata-container"
          >
            <div
              className={classes.Instance__MetadataContainer}
              data-tracker="instance-metadata-block"
              data-testid="instance-metadata-container"
            >
              <Metadata
                label="Platform"
                svgFilename={
                  instance.platform &&
                  PLATFORMS[instance.platform as keyPlatformTypes]?.svgFile
                }
                data-testid="instance-card-metadata-platform"
              >
                {instance.platform &&
                  PLATFORMS[instance.platform as keyPlatformTypes]?.label}
              </Metadata>
              <Metadata
                label="Data Warehouse"
                svgFilename={
                  instance.warehouse &&
                  DATA_WAREHOUSE[instance.warehouse as keyDataWarehouseTypes]
                    ?.svgFile
                }
                data-testid="instance-card-metadata-warehouse"
              >
                {instance.warehouse &&
                  DATA_WAREHOUSE[instance.warehouse as keyDataWarehouseTypes]
                    ?.label}
              </Metadata>
              <Metadata
                label="Region"
                data-testid="instance-card-metadata-region"
              >
                {instance.region}
              </Metadata>
              <Metadata label="Size" data-testid="instance-card-metadata-size">
                {instance.size}
              </Metadata>
              <Metadata
                label="Matillion version"
                data-tracker="instance-version"
                data-testid="instance-card-metadata-version"
              >
                {instance.version}
              </Metadata>
              <Metadata
                label="Last called in"
                data-testid="instance-card-metadata-lastCalledIn"
              >
                <Timestamp timestamp={instance.lastUpdated} format="relative" />
              </Metadata>
            </div>
            <ErrorBoundary FallbackComponent={JobActivityError}>
              <Suspense fallback={<CenteredSpinner />}>
                <JobActivity
                  installationId={installationId}
                  instanceId={instance.instanceId}
                />
              </Suspense>
            </ErrorBoundary>
          </AccordionContent>
        </Accordion>
      ))}
    </>
  )
}

export default Instance
