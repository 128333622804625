import { Edge } from 'reactflow'

export type Direction = 'forward' | 'backward'
export const findEdgesRecursive = (
  nodeId: string,
  edges: Edge[],
  direction: Direction
): Edge[] => {
  const connectedEdges = edges.filter((edge) =>
    direction === 'forward' ? edge.source === nodeId : edge.target === nodeId
  )

  const connectedNodes = connectedEdges.map((edge) =>
    direction === 'forward' ? edge.target : edge.source
  )

  const visitedNodes = new Set<string>()
  visitedNodes.add(nodeId)

  const connectedEdgesRecursive = connectedNodes
    .filter((id) => !visitedNodes.has(id))
    .map((id) => {
      visitedNodes.add(id)
      return findEdgesRecursive(
        id,
        edges.filter((edge) => !connectedEdges.includes(edge)),
        direction
      )
    })
    .flat()

  return [...connectedEdges, ...connectedEdgesRecursive]
}
