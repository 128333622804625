import { FC } from 'react'
import { Tooltip, Typography } from '@matillion/component-library'
import classNames from 'classnames'
import classes from './InformationPanel.module.scss'
import { Link } from 'react-router-dom'

export interface InformationPanelHeaderProps {
  Icon: JSX.Element
  title?: string
  subtitle?: string
  url?: string
  className?: string
}

const InformationPanelHeader: FC<InformationPanelHeaderProps> = ({
  Icon,
  title,
  subtitle,
  url,
  className
}) => {
  return (
    <div className={classNames(classes.InformationPanel__Header, className)}>
      {Icon}
      <Tooltip
        content={title}
        position="top"
        className={classes.InformationPanel__Header__Tooltip}
      >
        <div
          className={classNames(
            classes.InformationPanel__Titles,
            'u-truncate-text'
          )}
        >
          {url ? (
            <Link to={url}>
              <Typography
                format="ts"
                className={classNames(
                  classes.InformationPanel__Titles__Title,
                  classes.InformationPanel__Titles__TitleLink,
                  'u-truncate-text'
                )}
              >
                {title}
              </Typography>
              <Typography
                format="bcs"
                className={classes.InformationPanel__Titles__Subtitle}
              >
                {subtitle}
              </Typography>
            </Link>
          ) : (
            <>
              <Typography
                format="ts"
                className={classNames(
                  classes.InformationPanel__Titles__Title,
                  'u-truncate-text'
                )}
              >
                {title}
              </Typography>
              <Typography
                format="bcs"
                className={classes.InformationPanel__Titles__Subtitle}
              >
                {subtitle}
              </Typography>
            </>
          )}
        </div>
      </Tooltip>
    </div>
  )
}

export default InformationPanelHeader
