import { Edge } from 'reactflow'

/**
 * Finds all connected edges from a given source node and handle, traversing in the specified direction.
 * The function searches through the provided array of edges, identifying those that are connected
 * to the current source and handle. It then recursively searches from each connected edge's target (in forward direction)
 * or source (in backward direction), accumulating all relevant edges in the results array.
 *
 * @param {Edge[]} edges - The array of all edges to search through. Each edge should have source, target, sourceHandle, and targetHandle properties.
 * @param {string} currentSource - The identifier of the current source node from which to start searching.
 * @param {string} currentHandle - The identifier of the current handle associated with the source node.
 * @param {'forward' | 'back'} direction - The direction to traverse the graph. 'forward' searches from source to target, 'back' searches from target to source.
 * @param {Set<string>} [visited=new Set()] - A set of visited edges (identified by a unique string) to avoid revisiting and to prevent infinite loops in cyclic graphs.
 * @param {Edge[]} [results=[]] - The accumulator array for storing all found connected edges. This parameter is mainly used internally by the recursive calls.
 *
 * @returns {Edge[]} An array of edges that are connected to the given source node and handle, following the specified direction.
 */

export function findAllConnectedEdges(
  edges: Edge[],
  currentSource: string,
  currentHandle: string,
  direction: 'forward' | 'back',
  visited: Set<string> = new Set(),
  results: Edge[] = []
) {
  edges.forEach((edge) => {
    const identifier = `${edge.source}-${edge.sourceHandle as string}-${
      edge.target
    }-${edge.targetHandle as string}`
    let nextSource
    let nextHandle

    if (
      direction === 'forward' &&
      edge.source === currentSource &&
      edge.sourceHandle === currentHandle &&
      !visited.has(identifier)
    ) {
      nextSource = edge.target
      nextHandle = edge.targetHandle
    } else if (
      direction === 'back' &&
      edge.target === currentSource &&
      edge.targetHandle === currentHandle &&
      !visited.has(identifier)
    ) {
      nextSource = edge.source
      nextHandle = edge.sourceHandle
    }

    if (nextSource && nextHandle) {
      visited.add(identifier)
      results.push(edge)
      findAllConnectedEdges(
        edges,
        nextSource,
        nextHandle,
        direction,
        visited,
        results
      )
    }
  })

  return results
}
