import { useInfiniteQuery } from '@tanstack/react-query'
import { DatasetsResponse, getDatasets } from 'api/dgs'
import useDgsClient from './useDgsClient'
import { DatasetItem } from 'types/dgs'

const getNextPage = (lastPage: DatasetsResponse) => lastPage

export const useGetDatasetsInfinite = (search?: string) => {
  const client = useDgsClient()

  const { data, isFetching, fetchNextPage, isFetchingNextPage, isError } =
    useInfiniteQuery({
      queryKey: ['datasets', search],
      queryFn: async ({ pageParam }) => {
        const prevPage = pageParam

        const offset =
          typeof prevPage?.offset === 'number'
            ? prevPage.offset + prevPage.limit
            : 0
        const limit = prevPage?.limit ?? undefined

        return getDatasets(client, { offset, limit, search })
      },
      initialPageParam: undefined,
      getNextPageParam: getNextPage,
      refetchOnMount: false
    })

  const allDatasets: DatasetItem[] | undefined = data?.pages.flatMap(
    (page) => page?.results
  )

  const hasMore = data?.pages[data.pages.length - 1].hasNext

  const fetchNext = () => {
    if (hasMore && !isFetching) {
      void fetchNextPage()
    }
  }

  return {
    datasets: allDatasets,
    isFetching,
    isRefreshing: isFetching && !isFetchingNextPage,
    isPaginating: isFetchingNextPage,
    hasMore,
    isError,
    fetchNextPage: fetchNext
  }
}
