import './base.scss'

import { StrictMode, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import config from './config'
import App from './App'
import {
  AuthProvider,
  FlagProvider,
  RegionGuard,
  ServiceRegistryProvider
} from '@matillion/hub-client'
import { BrowserRouter } from 'react-router-dom'
import './utilities.scss'

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_ENVIRONMENT === 'mocks'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser')
  worker.start({ quiet: true })
}

if (config.datadog) {
  const { rumService, service, ...options } = config.datadog

  datadogLogs.init({
    ...options,
    service,
    forwardErrorsToLogs: true,
    sampleRate: 100
  })

  datadogRum.init({
    ...options,
    version: config.version,
    service: rumService,
    sampleRate: 100,
    allowedTracingOrigins: [
      // https://regexr.com/7eq8s
      /^https:\/\/.+\.(?:platform|core)(?:-.+)?\.matillion\.com$/
    ]
  })
}

if (config.gtmId) {
  TagManager.initialize({ gtmId: config.gtmId })
}

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

function AppWithCallbackAfterRender() {
  useEffect(() => {
    container?.setAttribute('data-app-environment', config.environment)
    container?.setAttribute('data-app-version', config.version)
  })

  return (
    <StrictMode>
      <AuthProvider environment={config.authEnvironment}>
        <ServiceRegistryProvider>
          <RegionGuard strictAccountRegion>
            <FlagProvider
              launchDarklyClientId={config.launchDarkly.clientId}
              flags={config.launchDarkly.flags}
            >
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </FlagProvider>
          </RegionGuard>
        </ServiceRegistryProvider>
      </AuthProvider>
    </StrictMode>
  )
}

root.render(<AppWithCallbackAfterRender />)
