import { ActivityItem } from '../../types/eos'

export function augmentItem(
  item: ActivityItem | undefined,
  projectNames: Record<string, string>
) {
  if (item?.definition.type === 'DPC_ETL') {
    item.definition.projectName = projectNames[item.definition.projectId] ?? ''
  }
}
