import ReactFlow, {
  Background,
  BackgroundVariant,
  NodeDragHandler,
  NodeMouseHandler,
  useOnSelectionChange,
  useStoreApi,
  useNodesState,
  useEdgesState,
  Edge,
  Node,
  useViewport
} from 'reactflow'
import 'reactflow/dist/style.css'
import { CustomEdge, EdgeData } from './CustomEdge/CustomEdge'
import CustomDatasetNode from './CustomNodes/DatasetNode/DatasetNode'
import CustomPipelineNode from './CustomNodes/PipelineNode/PipelineNode'
import CustomInaccessibleDatasetNode from './CustomNodes/InaccessibleDatasetNode/InaccessibleDatasetNode'
import CustomInaccessiblePipelineNode from './CustomNodes/InaccessiblePipelineNode/InaccessiblePipelineNode'
import classes from './LineageGraph.module.scss'
import { repositionIntersectingNodes } from './helpers/NodeRepositioningHelpers'
import { FC, useCallback, useEffect, MutableRefObject } from 'react'
import InformationPanel from './InformationPanel/InformationPanel'
import { transformNodes } from 'modules/DatasetDetailView/Lineage/LineageTransformers'
import { ZoomToolbar } from './ZoomToolbar/ZoomToolbar'
import { findAllConnectedEdges } from './helpers/findAllConnectedEdges'
import { Dataset, LineageNodeData } from 'types/dgs'
import { updateMeta } from './helpers/updateMeta'
import { useNavigate } from 'react-router-dom'
import { Toaster } from '@matillion/component-library'
import { useFlags } from '@matillion/hub-client'
import { findEdgesRecursive } from './helpers/findEdgesRecursive'
import { useColumnLineage } from 'modules/DatasetDetailView/Lineage/useColumnLineage'

export interface ExpandedNode {
  id: string
  isExpanded: boolean
  height: number
}

export interface NodeData<T = LineageNodeData> {
  content: T
  meta: {
    isExpanded?: boolean
    selectedField?: SelectedField
    filteredColumnEdges?: Edge[]
    expandedNodeRef?: MutableRefObject<ExpandedNode | undefined>
    connectedEdges?: Edge[]
    setSelectedField?: (field: SelectedField | undefined) => void
    toggleExpanded?: (id: string) => void
    /* To be called when a node finishes expanding */
    onExpand?: (nodeId: string, height: number) => void
  }
}

export type FlowNode<T = LineageNodeData> = Node<NodeData<T>>

export type FlowEdge = Edge<EdgeData>

export interface SelectedField {
  nodeId: string
  fieldId: string
}

export interface ReactFlowContainerProps {
  initialNodes?: FlowNode[]
  initialEdges?: Edge[]
  initialDatasetId?: string
}

const nodeTypes = {
  DATASET: CustomDatasetNode,
  PIPELINE: CustomPipelineNode,
  INACCESSIBLE_DATASET: CustomInaccessibleDatasetNode,
  INACCESSIBLE_PIPELINE: CustomInaccessiblePipelineNode
}

const edgeTypes = {
  default: CustomEdge
}

const LineageGraph: FC<ReactFlowContainerProps> = ({
  initialNodes,
  initialEdges,
  initialDatasetId
}) => {
  const [edges, setEdges, onEdgesChange] = useEdgesState([
    ...(initialEdges ?? [])
  ])
  const [nodes, setNodes, onNodesChange] = useNodesState([
    ...(initialNodes ?? [])
  ])
  const { columnLineage, isError, updateIds } = useColumnLineage()

  const selected = nodes.filter((node) => node.selected)[0]
  const selectedField = nodes[0]?.data.meta.selectedField
  const { rolloutEnableGraphUpdates } = useFlags()

  const { getState } = useStoreApi()
  const { unselectNodesAndEdges } = getState()

  const navigate = useNavigate()

  const toaster = Toaster.useToaster()
  const { zoom } = useViewport()

  useEffect(() => {
    if (isError) {
      toaster.makeToast({
        type: 'error',
        theme: 'dark',
        title: 'Sorry.',
        message:
          'Something went wrong when attempting to load the column lineage.'
      })
    }
  }, [isError, toaster])

  const setSelectedField = useCallback(
    (field: SelectedField | undefined) => {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) =>
          updateMeta({ selectedField: field }, node)
        )
        return newNodes
      })
    },
    [setNodes]
  )

  const toggleExpanded = useCallback(
    (id: string) => {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) => {
          if (node.id === id) {
            return updateMeta({ isExpanded: !node.data.meta.isExpanded }, node)
          }
          return node
        })

        return newNodes
      })

      if (selectedField?.nodeId === id) {
        setSelectedField(undefined)
      }
    },
    [selectedField, setNodes, setSelectedField]
  )

  const onExpand = useCallback(
    (nodeId: string, height: number) => {
      setNodes((prevNodes) => {
        const foundNode = prevNodes.find((n) => n.id === nodeId) as FlowNode
        foundNode.height = height / zoom

        return repositionIntersectingNodes(foundNode, prevNodes)
      })
      const foundEdgesForward = findEdgesRecursive(
        nodeId,
        initialEdges ?? [],
        'forward'
      )
      const foundEdgesBackward = findEdgesRecursive(
        nodeId,
        initialEdges ?? [],
        'backward'
      )
      const foundEdges = [...foundEdgesForward, ...foundEdgesBackward]
      setNodes((prevNodes) => {
        return prevNodes.map((node) => {
          if (node.id === nodeId) {
            return updateMeta({ connectedEdges: foundEdges }, node)
          }
          return node
        })
      })
      updateIds(nodeId, foundEdges, initialNodes as FlowNode[])
    },
    [zoom, initialEdges, initialNodes, updateIds, setNodes]
  )

  useEffect(() => {
    setNodes((currentNodes) => {
      return currentNodes.map((node) => {
        return updateMeta({ toggleExpanded, setSelectedField, onExpand }, node)
      })
    })
  }, [setNodes, toggleExpanded, setSelectedField, onExpand])

  useEffect(() => {
    setNodes((currentNodes) =>
      currentNodes.map((node) => ({
        ...node,
        selected: node.id === initialDatasetId
      }))
    )
  }, [setNodes, initialDatasetId])

  useEffect(() => {
    if (!rolloutEnableGraphUpdates) return
    if (selectedField) return
    if (!selected) return

    const foundEdgesForward = findEdgesRecursive(
      selected.id,
      initialEdges ?? [],
      'forward'
    )
    const foundEdgesBackward = findEdgesRecursive(
      selected.id,
      initialEdges ?? [],
      'backward'
    )
    const foundEdges = [...foundEdgesForward, ...foundEdgesBackward]
    const updatedEdges = initialEdges?.map((edge) => {
      const isEdgeInActiveChain = foundEdges.some(
        (foundEdge) => foundEdge.id === edge.id
      )
      if (isEdgeInActiveChain) {
        return updateMeta({ isInActiveChain: true }, edge)
      }
      return updateMeta({ isInBackground: true }, { ...edge, zIndex: -1 })
    })

    setEdges(updatedEdges ?? [])
  }, [
    selected,
    selectedField,
    initialEdges,
    rolloutEnableGraphUpdates,
    setEdges
  ])

  useEffect(() => {
    if (selectedField) {
      const activeForward = findAllConnectedEdges(
        columnLineage,
        selectedField.nodeId,
        selectedField.fieldId,
        'forward'
      )
      const activeBackward = findAllConnectedEdges(
        columnLineage,
        selectedField.nodeId,
        selectedField.fieldId,
        'back'
      )

      const activeWithMeta = [...activeForward, ...activeBackward].map((edge) =>
        updateMeta({ isInActiveChain: true }, edge)
      )

      setEdges(() => {
        const backgroundEdges =
          initialEdges?.map((edge) => {
            return updateMeta({ isInBackground: true }, edge)
          }) ?? []

        return [...backgroundEdges, ...activeWithMeta]
      })

      setNodes((currentNodes) => {
        return currentNodes.map((node) => {
          return updateMeta(
            { selectedField, filteredColumnEdges: activeWithMeta },
            node
          )
        })
      })
    } else {
      setEdges(() => {
        return (
          initialEdges?.map((edge) => {
            return updateMeta({ isInBackground: false }, edge)
          }) ?? []
        )
      })
      setNodes((currentNodes) => {
        return currentNodes.map((node) => {
          return updateMeta({ filteredColumnEdges: undefined }, node)
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField, initialEdges, setEdges, setNodes])

  useOnSelectionChange({
    onChange: ({ nodes: selectedNodes }) => {
      if (selectedNodes.length === 1 && selectedNodes[0].type === 'DATASET') {
        const selectedNode = selectedNodes[0] as FlowNode<Dataset>
        navigate(`/lineage/dataset/${selectedNode.id}`, { replace: true })
      }
    }
  })

  const reorganiseNodes = () => {
    setNodes((currentNodes) => {
      return transformNodes(currentNodes, edges)
    })
  }

  const collapseAll = () => {
    setNodes((prevNodes) => {
      return prevNodes.map((node) => {
        return updateMeta({ isExpanded: false, selectedField: undefined }, node)
      })
    })
  }

  const onNodeDragStop: NodeDragHandler = (_, node) => {
    setNodes((currentNodes) => {
      const newNodes = currentNodes.map((n) => {
        if (n.id === node.id) {
          n.zIndex = 0
        }
        return n
      })
      return repositionIntersectingNodes(node, newNodes)
    })
  }

  const onNodeDragStart: NodeDragHandler = (_, node) => {
    setNodes((currentNodes) => {
      return currentNodes.map((n) => {
        if (n.id === node.id) {
          n.zIndex = 1000
        }
        return n
      })
    })
  }

  const onNodeClick: NodeMouseHandler = (_, node) => {
    if (
      node.type !== undefined &&
      ['INACCESSIBLE_DATASET', 'INACCESSIBLE_PIPELINE'].includes(node.type)
    ) {
      toaster.makeToast({
        type: 'warning',
        theme: 'dark',
        title: 'You do not have permission to view.',
        message:
          'If you believe this is an error please contact your administrator.'
      })
    }
  }

  const onPaneClick = () => {
    setSelectedField(undefined)
    setEdges((prevEdges) => {
      return prevEdges.map((edge) => {
        return updateMeta(
          { isInBackground: false, isInActiveChain: false },
          edge
        )
      })
    })
  }

  return (
    <div
      className={classes.LineageGraph__Container}
      data-testid="lineage-graph"
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        deleteKeyCode={null}
        fitView
        fitViewOptions={{ padding: 0.6 }}
        minZoom={0.01}
        onNodeDragStop={onNodeDragStop}
        onNodeDragStart={onNodeDragStart}
        onNodeClick={onNodeClick}
        proOptions={{ hideAttribution: true }}
        multiSelectionKeyCode={null}
        selectionOnDrag={false}
        selectionKeyCode={null}
        selectNodesOnDrag={false}
        onPaneClick={onPaneClick}
        className={classes.LineageGraph}
      >
        <Background
          variant={BackgroundVariant.Dots}
          size={4}
          color="#ddd"
          className={classes.LineageGraph__Background}
        />
        {selected && (
          <InformationPanel node={selected} onClose={unselectNodesAndEdges} />
        )}
      </ReactFlow>
      <ZoomToolbar
        collapseAll={collapseAll}
        reorganiseNodes={reorganiseNodes}
      />
    </div>
  )
}

export default LineageGraph
