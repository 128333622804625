import InstancesListError from './InstancesList/InstancesListError'
import { FC, Suspense } from 'react'
import InstancesList from './InstancesList'
import { ErrorBoundary } from 'react-error-boundary'

import { CenteredSpinner } from 'components/CenteredSpinner'

export const InstancesWrapper: FC = () => {
  return (
    <ErrorBoundary FallbackComponent={InstancesListError}>
      <Suspense fallback={<CenteredSpinner />}>
        <InstancesList />
      </Suspense>
    </ErrorBoundary>
  )
}
