import { Cell, Row, RowProps } from 'components/DataTable'

import classNames from 'classnames'
import classes from './PipelineTreeHeadRow.module.scss'

export interface Props extends Omit<RowProps, 'children'> {
  cellClassName?: string
}

export const PipelineTreeHeadRow = ({ cellClassName, ...props }: Props) => (
  <Row {...props}>
    <Cell
      semantics="head"
      className={classNames(
        classes.PipelineTreeHead__PipelineColumn,
        cellClassName
      )}
    >
      Pipeline
    </Cell>
    <Cell className={cellClassName} semantics="head">
      Component
    </Cell>
    <Cell className={cellClassName} semantics="head">
      Started
    </Cell>
    <Cell className={cellClassName} semantics="head">
      Duration
    </Cell>
    <Cell className={cellClassName} semantics="head">
      Row count
    </Cell>
    <Cell className={cellClassName} semantics="head">
      Message
    </Cell>
  </Row>
)
