import { BillingInstallation } from 'api/billing'
import { differenceInHours } from 'date-fns'

export function isBillingInstallationRetired(
  installation: BillingInstallation,
  now: Date
) {
  return (
    installation.status === 'INACTIVE' &&
    (!installation.lastCalledIn ||
      differenceInHours(now, installation.lastCalledIn) >= 72)
  )
}
