import { ServiceRegistry } from '../index'
import { ServiceRegistryContext } from '../context'
import React, { FunctionComponent } from 'react'

interface MockServiceRegistryProps {
  endpoints?: {
    [index: string]: string
  }
}
export const MockServiceRegistryProvider: FunctionComponent<
  React.PropsWithChildren<MockServiceRegistryProps>
> = ({ children, endpoints }) => {
  const serviceRegistry: ServiceRegistry = {
    metadata: {
      env: '',
      tier: '',
      region: ''
    },
    endpoints: {}
  }
  for (const endpointsKey in endpoints) {
    serviceRegistry.endpoints[endpointsKey] = {
      endpoint: endpoints[endpointsKey],
      global: false,
      region: '',
      type: ''
    }
  }
  return (
    <ServiceRegistryContext.Provider value={serviceRegistry}>
      {children}
    </ServiceRegistryContext.Provider>
  )
}
