import { useCallback, useMemo, useState } from 'react'

export const useOpener = () => {
  const [isOpen, setIsOpen] = useState(false)
  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])
  const toggleOpen = useCallback(() => setIsOpen((v) => !v), [])

  return useMemo(
    () => ({
      isOpen,
      open,
      close,
      toggleOpen
    }),
    [isOpen, close, toggleOpen, open]
  )
}
