import { FlowEdge, FlowNode, NodeData } from '../LineageGraph'
import { EdgeData } from '../CustomEdge/CustomEdge'

export function updateMeta<T extends FlowNode | FlowEdge>(
  values: Partial<NodeData['meta']> | Partial<EdgeData['meta']>,
  entity: T
): T {
  return {
    ...entity,
    data: {
      ...entity.data,
      meta: {
        ...entity.data?.meta,
        ...values
      }
    }
  }
}
