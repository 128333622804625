import React, { FunctionComponent, useContext } from 'react'
import { Loader } from '@matillion/component-library'

import { AuthStateContext } from '../context'

const AuthLoader: FunctionComponent<React.PropsWithChildren> = ({
  children
}) => {
  const { isLoading } = useContext(AuthStateContext)

  return isLoading ? <Loader data-testid="Auth/Loading" /> : <>{children}</>
}

export default AuthLoader
