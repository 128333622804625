import { Grid, Section, Typography } from '@matillion/component-library'
import classes from './InstancesListError.module.scss'
import ContactSupportLink from 'components/ContactSupportLink'

const InstancesListError = () => {
  return (
    <Grid>
      <Section
        role="alert"
        size={12}
        subgrid
        className={classes.InstancesListError_Center}
        data-testid="instances-error-view"
      >
        <Section>
          <Typography
            as="h1"
            format="ts"
            data-testid="instances-error-view-header"
          >
            Your instances could not be loaded right now.
          </Typography>
        </Section>
        <Section>
          <Typography data-testid="instances-error-view-footer">
            Please try and refresh the page. If this problem persists then
            please
            <ContactSupportLink />
            so that we are able to look into this for you.
          </Typography>
        </Section>
      </Section>
    </Grid>
  )
}

export default InstancesListError
