import {
  HeaderMenuItemGroup,
  HeaderMenuItem,
  UserMenu,
  Typography
} from '@matillion/component-library'
import type { PropsWithChildren } from 'react'

import useAuth from '../../effects/useAuth'
import useUser from '../../effects/useUser'

import './HubUserMenu.scss'

import MyDetailsLink from './components/MyDetailsLink'
import SwitchOrganisationLink from './components/SwitchOrganisationLink'

interface MenuItemProps {
  title: string
  as: React.ComponentType<PropsWithChildren<unknown>>
}

type MenuGroupProps = MenuItemProps[]

interface HubUserMenuProps {
  menuGroups?: MenuGroupProps[]
}

const defaultMenuGroups: MenuGroupProps[] = [
  [
    { title: 'My details', as: MyDetailsLink },
    { title: 'Switch account', as: SwitchOrganisationLink }
  ]
]

const HubUserMenu = ({ menuGroups = defaultMenuGroups }: HubUserMenuProps) => {
  const { profile, organisation } = useUser()
  const { logout } = useAuth()

  return (
    <UserMenu profile={profile} data-testid="hub-user-menu">
      {menuGroups.map((group, idx) => (
        <HeaderMenuItemGroup key={idx} small>
          {group.map((item, idx) => (
            <HeaderMenuItem key={idx} as={item.as}>
              {item.title}
            </HeaderMenuItem>
          ))}
        </HeaderMenuItemGroup>
      ))}
      <HeaderMenuItemGroup small>
        <HeaderMenuItem
          as="button"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Logout
        </HeaderMenuItem>
        <div className="HeaderMenuAccountId">
          <Typography format="mc">
            Account ID: {organisation.publicId}
          </Typography>
        </div>
      </HeaderMenuItemGroup>
    </UserMenu>
  )
}

export default HubUserMenu
