import { useInstallations } from 'api/hooks/useInstallations'
import { useGetBillingInstallations } from 'api/hooks/useGetBillingInstallations'
import { useMemo } from 'react'
import { BillingInstallation } from 'api/billing'
import { billingStatusToInstanceState } from './billingStatusToInstanceState'
import { isBillingInstallationRetired } from './isBillingInstallationRetired'

const useInstallationsAndInstances = () => {
  const { data: odbInstallations = [] } = useInstallations()
  const { data: billingInstallations = [] } = useGetBillingInstallations()
  return useMemo(() => {
    const dataFromBilling: Record<string, BillingInstallation> =
      billingInstallations
        .filter(
          (billingInstallation) =>
            !isBillingInstallationRetired(billingInstallation, new Date())
        )
        .reduce((acc, billingInstallation) => {
          return {
            ...acc,
            [billingInstallation.id]: billingInstallation
          }
        }, {})
    const installations = odbInstallations
      .filter(
        (odbInstallation) => !!dataFromBilling[odbInstallation.installationId]
      )
      .map((odbInstallation) => {
        const billingInstallation =
          dataFromBilling[odbInstallation.installationId]
        return {
          ...odbInstallation,
          installationName: billingInstallation.name,
          instances: odbInstallation.instances.map((instance) => {
            return {
              ...instance,
              state: billingStatusToInstanceState(billingInstallation.status),
              lastUpdated: billingInstallation.lastCalledIn
            }
          })
        }
      })
    const instances = installations.flatMap(
      (installation) => installation.instances
    )
    return {
      installations,
      instances
    }
  }, [odbInstallations, billingInstallations])
}

export default useInstallationsAndInstances
