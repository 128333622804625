import classes from './JobActivity.module.scss'
import MessageBanner from 'components/MessageBanner'

const JobActivityError = () => {
  return (
    <MessageBanner
      title="The jobs for this instance could not be loaded right now"
      description="Please try and refresh the page. If the problem persists, we suggest checking with your platform provider for more information."
      className={classes.JobActivity__MessageErrorContainer}
    />
  )
}

export default JobActivityError
