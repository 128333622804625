import { useAuth } from '@matillion/hub-client'
import axios from 'axios'
import { useMemo } from 'react'
import config from 'config'

export const useBillingClient = () => {
  const { getToken } = useAuth()

  return useMemo(() => {
    const client = axios.create({
      baseURL: config.billingApiBaseUrl,
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: 50000
    })

    client.interceptors.request.use(async (interceptedConfig) => {
      interceptedConfig.headers.Authorization = `Bearer ${await getToken()}`
      return interceptedConfig
    })
    return client
  }, [getToken])
}
