import classNames from 'classnames'
import { HTMLProps, Ref, forwardRef } from 'react'

import classes from './DataTable.module.scss'

export interface Props extends HTMLProps<HTMLTableElement> {
  ref?: Ref<HTMLTableElement>
}

export const DataTable = forwardRef<HTMLTableElement, Props>(
  ({ className, ...props }, ref) => {
    return (
      <table
        {...props}
        ref={ref}
        className={classNames(classes.DataTable, className)}
      />
    )
  }
)

DataTable.displayName = 'DataTable'
