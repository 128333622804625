import { Grid, Page, Section } from '@matillion/component-library'
import ErrorPage from 'components/ErrorPage'
import { ErrorBoundary } from 'react-error-boundary'
import DetailViewHeader from './DetailViewHeader/DetailViewHeader'
import classes from './DatasetDetailView.module.scss'
import { FC, PropsWithChildren, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { safeString } from 'utils/safeString'
import Lineage from './Lineage/Lineage'

const DatasetDetailView: FC<PropsWithChildren> = () => {
  const { datasetId } = useParams()
  const [initialDatasetId, setInitialDatasetId] = useState<string>()

  if (initialDatasetId === undefined) {
    setInitialDatasetId(datasetId)
  }

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Page>
        <Grid fullScreen>
          <Section size={12} className={classes.HeaderContainer}>
            <DetailViewHeader />
          </Section>
          <Section size={12} className={classes.TabsContainer}>
            <Lineage initialDatasetId={safeString(initialDatasetId)} />
          </Section>
          <Outlet />
        </Grid>
      </Page>
    </ErrorBoundary>
  )
}

export default DatasetDetailView
