export interface Pendo {
  initialize: (attributes: {
    visitor?: {
      id: string
    }
    account?: {
      id: string
    }
  }) => void
  updateOptions: (attributes: {
    visitor?: PendoUserAttributes
    account?: PendoAccountAttributes
  }) => void
  track: (eventName: string, props?: Record<string, unknown>) => void
  teardown: () => void
}

interface PendoUserAttributes {
  // These are reserved Pendo attribute names
  email?: string
  full_name?: string
  role?: string

  [key: string]: string | boolean | number | null | undefined
}

interface PendoAccountAttributes {
  // These are reserved Pendo attribute names
  name?: string
  is_paying?: boolean
  monthly_value?: number
  planLevel?: string
  planPrice?: number
  creationDate?: string

  [key: string]: string | boolean | number | null | undefined
}

declare global {
  interface Window {
    pendo?: Pendo
  }
}

export const pendo = {
  getPendoInstance: () => window.pendo,
  initialize: (
    userId: string | undefined = undefined,
    accountId: string | undefined = undefined
  ) => {
    const pendoInstance = pendo.getPendoInstance()
    if (!pendoInstance) {
      console.debug(
        "Pendo's initialize operation did not run. Check that Pendo has loaded and window.pendo is available."
      )
      return
    }
    try {
      pendoInstance.initialize({
        ...(userId && {
          visitor: {
            id: userId
          }
        }),
        ...(accountId && {
          account: {
            id: accountId
          }
        })
      })
    } catch (e) {
      console.error('Could not initialize Pendo!', e)
    }
  },
  updateOptions: (userAttributes = {}, accountAttributes = {}) => {
    const pendoInstance = pendo.getPendoInstance()
    if (!pendoInstance) {
      console.debug(
        "Pendo's updateOptions operation did not run. Check that Pendo has loaded and window.pendo is available."
      )
      return
    }
    try {
      pendoInstance.updateOptions({
        visitor: userAttributes,
        account: accountAttributes
      })
    } catch (e) {
      console.error('Could not update Pendo options!', e)
    }
  },
  track: (event: string, metadata?: Record<string, unknown>) => {
    const pendoInstance = pendo.getPendoInstance()
    if (!pendoInstance) {
      console.debug(
        "Pendo's track operation did not run. Check that Pendo has loaded and window.pendo is available."
      )
      return
    }
    try {
      pendoInstance.track(event, metadata)
    } catch (e) {
      console.error('Could not track the event!', e)
    }
  },
  teardown: () => {
    const pendoInstance = pendo.getPendoInstance()
    if (!pendoInstance) {
      console.debug(
        "Pendo's teardown operation did not run. Check that Pendo has loaded and window.pendo is available."
      )
      return
    }
    try {
      pendoInstance.teardown()
    } catch (e) {
      console.error('Could not teardown Pendo!', e)
    }
  }
}

export const usePendo = () => {
  return pendo
}
