import { useAuth, useUser } from '@matillion/hub-client'
import axios, { AxiosRequestConfig } from 'axios'
import { useMemo } from 'react'
import config from 'config'

const useClient = (audience?: string, axiosConfig?: AxiosRequestConfig) => {
  const { getToken } = useAuth()
  const { organisation } = useUser()

  return useMemo(() => {
    const client = axios.create(
      axiosConfig ?? {
        baseURL:
          config.apiBaseUrl + `/v1/dashboard/organisations/${organisation.id}`,
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 50000
      }
    )

    client.interceptors.request.use(async (interceptedConfig) => {
      const tokenOptions = {
        audience: audience ?? config.apiBaseAudience
      }
      interceptedConfig.headers.Authorization = `Bearer ${await getToken(
        tokenOptions
      )}`
      return interceptedConfig
    })
    return client
  }, [axiosConfig, organisation.id, audience, getToken])
}

export default useClient
