import { InstancesWrapper } from './InstancesWrapper'
import PageLayout from 'components/PageLayout/PageLayout'
import HeaderSection from 'components/HeaderSection/HeaderSection'
import NavigationTabs from 'components/NavigationTabs/NavigationTabs'
import { Section } from '@matillion/component-library'

const Instances = () => {
  return (
    <PageLayout>
      <HeaderSection title="Your activity" />
      <NavigationTabs />
      <Section size={12}>
        <InstancesWrapper />
      </Section>
    </PageLayout>
  )
}

export default Instances
