import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { PermissionName, PermissionResource, PermissionValue } from '../types'

const permissionsApi = axios.create({
  headers: { 'Content-Type': 'application/json' }
})

export interface CheckPermissionBody {
  resource: PermissionResource
  permission: PermissionName
}

export interface CheckPermissionResponse {
  permission: PermissionValue
}

export const checkPermission = async (
  token: string,
  resource: PermissionResource,
  permissionName: PermissionName,
  requestConfig?: AxiosRequestConfig
) =>
  permissionsApi.post<
    CheckPermissionBody,
    AxiosResponse<CheckPermissionResponse>
  >(
    '/api/v1/permission/check',
    { resource, permission: permissionName },
    {
      ...requestConfig,
      headers: { authorization: `Bearer ${token}` }
    }
  )

export default permissionsApi
