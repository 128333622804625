import {
  HeaderMenuItemGroup,
  PlatformMenu,
  PlatformSubMenu,
  PlatformSubMenuItem
} from '@matillion/component-library'
import { useAllServiceUrlsByKeys } from '../../effects/useServiceUrl'

import HubPlatformMenuServiceItem from './HubPlatformMenuServiceItem'
import { usePlatformMenu } from './effects/usePlatformMenu'

interface GetUrlProps {
  fallbackUrl: string
  serviceKey?: string
}

const HubPlatformMenu = () => {
  const urlsByKeys = useAllServiceUrlsByKeys()

  const getUrl = ({ serviceKey, fallbackUrl }: GetUrlProps) => {
    if (serviceKey) {
      const retrievedUrl = urlsByKeys.get(serviceKey)
      if (!retrievedUrl) {
        throw new Error(`No url found for serviceKey ${serviceKey}`)
      }

      return retrievedUrl
    }

    return fallbackUrl
  }

  const { services, subMenus } = usePlatformMenu()

  return (
    <PlatformMenu data-testid="hub-platform-menu" newDesignEnabled>
      <HeaderMenuItemGroup>
        {services.map((service) => (
          <HubPlatformMenuServiceItem
            key={service.title}
            serviceKey={service.key}
            name={service.title}
            description={service.description}
          />
        ))}
      </HeaderMenuItemGroup>
      <HeaderMenuItemGroup>
        {subMenus.map((subMenu) => (
          <PlatformSubMenu
            key={subMenu.title}
            title={subMenu.title}
            description={subMenu.description}
            active={Boolean(
              subMenu.options.find(
                (option) =>
                  getUrl({
                    serviceKey: option.key,
                    fallbackUrl: option.url
                  }) === window.location.origin
              )
            )}
          >
            {subMenu.options.map((option) => {
              const url = getUrl({
                serviceKey: option.key,
                fallbackUrl: option.url
              })
              return (
                <PlatformSubMenuItem
                  key={option.title}
                  href={url}
                  description={option.description}
                  title={option.title}
                  active={url === window.location.origin}
                />
              )
            })}
          </PlatformSubMenu>
        ))}
      </HeaderMenuItemGroup>
    </PlatformMenu>
  )
}

export default HubPlatformMenu
