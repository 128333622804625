import { BillingInstallationStatus } from 'api/billing'
import { InstanceState } from 'types/installations'

export function billingStatusToInstanceState(
  billingStatus?: BillingInstallationStatus
): InstanceState {
  if (billingStatus === 'ACTIVE') {
    return 'RUNNING'
  }
  return 'STOPPED'
}
