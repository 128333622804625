import { AxiosInstance } from 'axios'

export type BillingInstallationStatus = 'ACTIVE' | 'INACTIVE' | 'UNKNOWN'

export interface BillingInstallation {
  id: string
  name: string
  status: BillingInstallationStatus
  lastCalledIn?: string
}

export const getBillingInstallations = async (
  client: AxiosInstance
): Promise<BillingInstallation[]> => {
  const { data } = await client.get<BillingInstallation[]>(
    '/billing/v1/installations'
  )
  return data
}
