import { Icon, Typography } from '@matillion/component-library'
import { Link } from 'react-router-dom'
import classes from './PipelineRunDetailsHeader.module.scss'
import { useRememberedFilters } from '../../../context/FilterContext'

const BackToHistory = () => {
  const filters = useRememberedFilters()
  return (
    <Link
      to={filters ? `/?${filters.toString()}` : '/'}
      className={classes.Back}
    >
      <Icon.ChevronDown className={classes.Chevron} stroke="#2d8825" />
      <Typography>Back to pipeline run history</Typography>
    </Link>
  )
}

export default BackToHistory
