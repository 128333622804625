import { useEffect, useState } from 'react'
import useAuth from './useAuth'
import { checkPermission } from '../api/permissions'
import { PermissionName, PermissionResource, PermissionValue } from '../types'

/**
 * Returns whether or not the current user has the specified permission for
 * the specified resource.
 *
 * @param resource A type and ID pair representing the resource that the user is
 *                 trying to access.
 * @param permissionName The name of the permission to check. For example, `run_pipelines`.
 * @returns A query bag containing a `data` key with the [[PermssionValue]], and
 *          the `isLoading` and `error` states of the check permission request.
 * @category Hooks
 */
const usePermission = (
  resource: PermissionResource,
  permissionName: PermissionName
) => {
  const { getToken } = useAuth()

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()
  const [data, setData] = useState<PermissionValue>()

  useEffect(() => {
    const fetchPermission = async () => {
      setLoading(true)

      try {
        const token = await getToken()
        const { data } = await checkPermission(token, resource, permissionName)

        setData(data.permission)
        setError(undefined)
      } catch (e) {
        console.error(`Failed to check "${permissionName}" permission`, e)
        setData(undefined)
        setError(e as Error)
      }

      setLoading(false)
    }

    fetchPermission()
  }, [setLoading, setData, getToken])

  return { data, isLoading, error }
}

export default usePermission
