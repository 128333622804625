import {
  Entitlements,
  useEntitlements,
  useFlags,
  useRole
} from '@matillion/hub-client'
import { useMemo } from 'react'

export interface LineageAccess {
  canAccessLineage: boolean
  isLoading: boolean
}

interface LineageAccessRequirements {
  flags: ReturnType<typeof useFlags>
  entitlements: Entitlements | undefined
  hasEtlUserRole: boolean
}

const canAccessLineage = ({
  flags,
  entitlements,
  hasEtlUserRole
}: LineageAccessRequirements): boolean => {
  if (!flags.rolloutEnableLineage) {
    return false
  }

  if (!hasEtlUserRole) {
    return false
  }

  if (!entitlements?.canAccessLineage) {
    return false
  }

  return true
}

export const useCanAccessLineage = () => {
  const flags = useFlags()
  const { data: entitlements } = useEntitlements()
  const hasEtlUserRole = useRole('saas-etl:user')

  return useMemo(() => {
    return {
      canAccessLineage: canAccessLineage({
        flags,
        entitlements,
        hasEtlUserRole
      })
    }
  }, [flags, entitlements, hasEtlUserRole])
}
