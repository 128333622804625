import { Typography } from '@matillion/component-library'
import PipelineDetails from './PipelineDetails/PipelineDetails'
import BackToHistory from './BackToHistory'
import classes from './PipelineRunDetailsHeader.module.scss'
import { ActivityItem } from 'types/eos'
import { FC } from 'react'
import { safeString } from 'utils/safeString'

interface Props {
  pipelineActivity: ActivityItem
}

const PipelineRunDetailsHeader: FC<Props> = ({ pipelineActivity }) => {
  return (
    <div className={classes.HeaderContainer}>
      <div>
        <BackToHistory />
        <Typography
          as="h1"
          format="dts"
          className={classes.PipelineRunDetailsTitle}
        >
          Pipeline run details
        </Typography>
        <Typography
          as="h2"
          format="tl"
          className={classes.PipelineRunDetailsSubtitle}
        >
          {safeString(pipelineActivity?.definition.pipelineName)}
        </Typography>
      </div>

      <PipelineDetails pipelineActivity={pipelineActivity} />
    </div>
  )
}

export default PipelineRunDetailsHeader
