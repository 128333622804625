import { NestingAwareStep, Step } from '../../types/eos'

export function collateSteps(allSteps: Step[]): NestingAwareStep[] {
  const stepsById = new Map<string, NestingAwareStep>()
  allSteps.forEach((step) =>
    stepsById.set(step.id, { ...step, nestedSteps: [] })
  )
  allSteps.forEach((step) => {
    if (step.parentStepId) {
      const parentStep = stepsById.get(step.parentStepId)
      if (parentStep?.iterator) {
        parentStep.nestedSteps.push(stepsById.get(step.id) as NestingAwareStep)
      } else {
        delete step.parentStepId
      }
    }
  })
  const newAllSteps = allSteps
    .filter((step) => !step.parentStepId)
    .map((step) => stepsById.get(step.id) as NestingAwareStep)

  return newAllSteps
}
