import { Badge, BadgeProps } from '@matillion/component-library'
import { useStatusTranslation } from 'hooks/useStatusTranslation'
import { FC } from 'react'
import { JobStatus } from 'types/jobs'
import classes from './StatusBadge.module.scss'

interface Props {
  status: JobStatus
}

const ColourMap: Record<JobStatus, BadgeProps['colour']> = {
  SUCCESS: 'green',
  FAILED: 'red',
  RUNNING: 'blue',
  UNKNOWN: 'grey',
  CANCELLING: 'grey',
  CANCELLED: 'grey',
  SKIPPED: 'grey'
}

const StatusBadge: FC<Props> = ({ status }) => {
  return (
    <Badge
      format="mc"
      weight="bold"
      colour={ColourMap[status]}
      className={classes.Badge}
    >
      {useStatusTranslation()(status)}
    </Badge>
  )
}

export default StatusBadge
