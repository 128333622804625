import { AxiosInstance } from 'axios'
import {
  ColumnLineage,
  Dataset,
  DatasetItem,
  LineageEdge,
  LineageNode
} from 'types/dgs'

export interface DatasetsResponse {
  results: DatasetItem[]
  offset: number
  limit: number
  total: number
  hasNext: boolean
}

export interface GetDatasetsParams {
  paginationToken?: string
  offset?: number
  limit?: number
  search?: string
}

export interface DatasetLineageResponse {
  nodes: LineageNode[]
  edges: LineageEdge[]
}

export interface ColumnLineageResponse {
  edges: ColumnLineage[]
}

export const getDatasets = async (
  client: AxiosInstance,
  params?: GetDatasetsParams
): Promise<DatasetsResponse> => {
  const { data } = await client.get<DatasetsResponse>('/v1/datasets', {
    params
  })
  return data
}

export const getDataset = async (
  client: AxiosInstance,
  datasetId: string
): Promise<Dataset> => {
  const { data } = await client.get<Dataset>(`/v1/datasets/${datasetId}`)
  return data
}

export const getDatasetLineage = async (
  client: AxiosInstance,
  datasetId: string
): Promise<DatasetLineageResponse> => {
  const { data } = await client.get<DatasetLineageResponse>(
    '/v1/lineage/dataset-level',
    {
      params: {
        datasetId
      }
    }
  )
  return data
}

export const getColumnLineage = async (
  client: AxiosInstance,
  datasetId: string
): Promise<ColumnLineageResponse> => {
  const { data } = await client.get<ColumnLineageResponse>(
    '/v1/lineage/field-level',
    {
      params: {
        datasetId
      }
    }
  )
  return data
}
