import { FunctionComponent } from 'react'
import classnames from 'classnames'
import { LoadingSpinner } from '@matillion/component-library'
import classes from './CenteredSpinner.module.scss'

interface CenteredSpinnerProps {
  size?: 'normal' | 'compact'
}

export const CenteredSpinner: FunctionComponent<CenteredSpinnerProps> = ({
  size = 'normal'
}) => {
  return (
    <div
      className={classnames(
        classes.CenteredSpinner,
        classes['CenteredSpinner--' + size]
      )}
    >
      <LoadingSpinner data-testid="loading-spinner" />
    </div>
  )
}
