import { useAuth0 } from '@auth0/auth0-react'
import { useMemo } from 'react'

import { Auth0User } from '../../types'

/**
 * Returns the current Auth0 user.
 */
const useAuth0User = () => {
  const { isLoading, user, error } = useAuth0()

  return useMemo(
    () => [user as Auth0User | undefined, isLoading, error] as const,
    [user, isLoading, error]
  )
}

export default useAuth0User
