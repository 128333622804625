import { FunctionComponent, SVGProps } from 'react'

export const RefreshIcon: FunctionComponent<SVGProps<SVGElement>> = () => {
  return (
    <svg
      aria-hidden="true"
      width="12"
      height="15"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00001 7H2.71001L3.85001 5.85L3.15001 5.15L0.790009 7.5L3.15001 9.85L3.85001 9.15L2.71001 8H6.08001C6.20082 8 6.32046 7.9762 6.43208 7.92997C6.5437 7.88374 6.64512 7.81597 6.73055 7.73054C6.81598 7.64511 6.88374 7.54369 6.92998 7.43207C6.97621 7.32045 7.00001 7.20082 7.00001 7.08V5H6.00001V7Z"
        fill="currentColor"
      />
      <path
        d="M4.21 0.790078L5.42 2.00008H1.92C1.676 2.00008 1.442 2.09701 1.26946 2.26954C1.09693 2.44207 1 2.67608 1 2.92008V5.00008H2V3.00008H5.42L4.21 4.21008L4.92 4.92008L7.33 2.50008L4.92 0.0800781L4.21 0.790078Z"
        fill="currentColor"
      />
    </svg>
  )
}
